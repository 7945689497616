<template>
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Recharges</span> My O'card
    </h3>
    <Widget class="mx-auto elevation-0">
      <v-dialog v-model="dialogRecharge" max-width="600px">
        <v-card>
          <v-card-title class="headline">Recharger ma carte {{carte.serialNumber}}</v-card-title>
          <v-row justify="center">
            <v-col cols="12" sm="6">
              <v-text-field
                  v-model="montant"
                  label="Montant"
                  required
                  append-outer-icon="mdi-currency-eur"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="error" text @click="dialogRecharge = false"
            >Annuler</v-btn
            >
            <v-btn color="#03498E" text @click="getSeq"
            >Confirmer</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAnswer" max-width="550px" persistent>
        <v-card style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
          <v-card-title class="headline">Recharger ma carte</v-card-title>
          <!-- payment form -->
          <div id="myPaymentForm">
            <div class="kr-embedded">
              <div class="kr-pan"></div>
              <div class="kr-expiry"></div>
              <div class="kr-security-code"></div>
              <div class="kr-form-error"></div>
              <button class="kr-payment-button"></button>
            </div>
          </div>
          <div id="customerror"></div>
          <div>{{ message }}</div>
          <v-card-actions>
            <div class="flex-grow-1"></div>
            <v-btn color="error" text @click="dialogAnswer = false">Annuler</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div class="table-responsive" style="text-align: center">
        <v-progress-circular v-if="loadBtn"
                             indeterminate
                             color="primary"
        ></v-progress-circular>
        <b-col lg="12" sm="6" xs="12" v-if="!loadBtn">
          <div class="pb-xlg h-100">
            <Widget
                class="mx-auto elevation-0"
                bodyClass="p-0 mt"
                customHeader
                title=""
            >
              <div>
                <v-card
                    :class="`d-flex justify-space-around mb-6 align-items-center`"
                    flat
                    tile
                >
                  <v-card class="pa-2 elevation-0" tile>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                  <v-select
                      ref="cartesRef"
                      :items="cartes"
                      :item-text="'serialNumber'"
                      label="Carte"
                      return-object
                      @change="onChange"
                  ></v-select>
                  </span>
                  </v-card>
                  <v-divider class="mx-4" vertical></v-divider>
                  <v-card class="pa-2 elevation-0" tile>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                      v-if="carte.solde ===''"
                  >
                     Solde : -
                  </span>
                    <span
                        class="subtitle-1 font-weight-medium"
                        style="color:#546E7A"
                        v-if="carte.solde !==''"
                    >
                     Solde : {{ carte.solde }} €
                  </span>
                  </v-card>
                </v-card>
                <v-card
                    :class="`d-flex justify-space-around mb-6 align-items-center`"
                    flat
                    tile
                    v-if="carte.status !== 'ACTIVE'"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Attention cette carte est {{carte.status}}
                  </span>
                </v-card>
              </div>

              <div
                  class="clearfix"
                  style="display: flex;align-items: center;justify-content: center;"
              >
                <div class="btn-toolbar">
                  <v-btn class="ma-2" type="submit" outlined color="#F6852D" :disabled="carte.serialNumber === ''"
                         @click="dialogRecharge = true;"
                  >Recharger ma carte</v-btn
                  >
                </div>
              </div>
            </Widget>
          </div>
        </b-col>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";
import "moment/locale/fr";
import { mask } from "vue-the-mask";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";
import KRGlue from '@lyracom/embedded-form-glue'

global.EventSource = NativeEventSource || EventSourcePolyfill;
let evtSource = false;

export default {
  name: "RechargeCartes",
  components: { Widget },
  directives: {
    mask
  },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      carte: {serialNumber: '', solde: '', status: 'ACTIVE'},
      cartes: [],
      dialogRecharge: false,
      dialogAnswer: false,
      montant: '',
      publicKey: '18478120:publickey_rJow52LFDUeTcWLoCAKDju1UTML7TYiYvLfSGmWcKGqUX',
    //  publicKey: '18478120:testpublickey_gL13HMkBM0IJgUtYMSmWW2wbXgces82CB5RZVh0vAxnLP',
      endpoint: 'https://api.systempay.fr/',
      formToken: '',
      message: '',
      sb: { status: false, text: "", color: "", y: "top" },
      loadBtn: false,
    };
  },
  methods: {
    initialize() {
      this.cartes = [];
      this._getCarte();
    },
    _getCarte() {
      var that = this;
      evtSource = new EventSourcePolyfill(host + "/cartes/client/" + this.entity.id, {
        headers: {
          Authorization: "Bearer" + this.token
        }
      });

      evtSource.addEventListener("message", function(e) {
        let carte = JSON.parse(e.data);
        that.loadData = false;
        that.loadDataIn = false;
        that.cartes.push(carte);
      });

      evtSource.addEventListener(
          "error",
          function(event) {
            switch (event.target.readyState) {
              case EventSource.CONNECTING:
                evtSource.close();
                break;
              case EventSource.CLOSED:
                break;
            }
          },
          false
      );

      evtSource.addEventListener(
          "close",
          // eslint-disable-next-line no-unused-vars
          function(e) {
            evtSource.close();
          },
          false
      );
    },
    getSeq() {
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
          .get(host + "/tickets/receipt/get-seq", auth)
          .then(result => {
            this.goRecharge(result.data);
            this.loadDownload = false;
          })
          .catch(err => {
            console.log(err);
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
          });
    },
    goRecharge(order){
      const endpoint = 'https://api.systempay.fr/'
       const publicKey = '18478120:publickey_rJow52LFDUeTcWLoCAKDju1UTML7TYiYvLfSGmWcKGqUX'
       let identifiant = '18478120:prodpassword_GzHEwLfFLx94M47AFH4Cwr6e5VoVUfSUk3a5VszplpavA';
     // const publicKey = '18478120:testpublickey_gL13HMkBM0IJgUtYMSmWW2wbXgces82CB5RZVh0vAxnLP'
     // let identifiant = '18478120:testpassword_i8RyK7bbRW8IV3U1GkAOGtEexbQw3MhQWQZCOtkxi82hs';
      let identifiant64 = btoa(identifiant);
      let item = {
        amount: this.montant*100,
        currency: "EUR",
        orderId: order.toString(),
        customer: {
          email: this.carte.client.entreprise.email,
          reference: this.carte.client.id.toString()
        },
        formAction: identifiant64
      };

      axios
          .post(host + "/tickets/systempay", item)
          .then(resp => {
            this.dialogRecharge = false;
            this.dialogAnswer = true;
            this.formToken = resp.data
            return KRGlue.loadLibrary(
                endpoint,
                publicKey
            ) /* Load the remote library */
          })
          .then(({ KR }) =>
              KR.setFormConfig({
                /* set the minimal configuration */
                formToken: this.formToken,
                'kr-language': 'fr-FR' /* to update initialization parameter */
              })
          )
          .then(({ KR }) => KR.onSubmit(this.validatePayment)) // Custom payment callback
          .then(({ KR }) =>
              KR.attachForm('#myPaymentForm')
          ) /* add a payment form  to myPaymentForm div*/
          .then(({ KR, result }) =>
              KR.showForm(result.formId)
          ) /* show the payment form */
          .catch(error =>
              this.message = error + ' (see console for more details)'
          )
    },
    /* Validate the payment data */
    validatePayment(paymentData) {
      if(paymentData.clientAnswer.orderStatus === 'PAID') {
        this.writeTrx(paymentData);
      } else {
        this.dialogAnswer = false;
        this.sb.status = true;
        this.sb.text = "Un erreur est survenue ! ";
        this.sb.color = "error";
      }

      return false;
      // Return false to prevent the redirection
    },
    writeTrx(paymentData) {
      this.dialogAnswer = false;
      this.loadBtn = true;
      let amount = this.montant;
      let montant = parseFloat(amount.replace(",", "."));
      let ticket = {
        carte: { id: this.carte.id },
        transactions: [{ montant: montant }],
        station: { id: "5e4e279af3395461439a5072" },
        typeTicket: "RECHARGE"
      };

      axios({
        method: "POST",
        url: host + "/tickets/charge/5e4e279af3395461439a5072",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: ticket
      })
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            if (result.status === 200) {
              this._writeReceipt(ticket, paymentData);
            } else {
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue ! ";
              this.sb.color = "error";
              this.initialize();
              this.montant="";
              this.loadBtn = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.initialize();
            this.montant="";
            this.loadBtn = false;
          });
    },
    _writeReceipt(ticket, paymentData) {
      let label = ticket.typeTicket + " " + paymentData.clientAnswer.orderDetails.orderId;
      let receipt = {
        amount: ticket.transactions[0].montant,
        client: this.carte.client,
        label: label,
        orderNumber: paymentData.clientAnswer.orderDetails.orderId
      };
      axios({
        method: "POST",
        url: host + "/tickets/receipt",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: receipt
      }) // eslint-disable-next-line no-unused-vars
          .then(result => {
            if (result.status === 200) {
              this.sb.status = true;
              this.sb.text = "Paiement effectué ! ";
              this.sb.color = "success";
              this.carte = {serialNumber: '', solde: '', status: 'ACTIVE'};
              this.initialize();
              this.montant="";
              this.loadBtn = false;
            } else {
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue ! ";
              this.sb.color = "error";
              this.carte = {serialNumber: '', solde: '', status: 'ACTIVE'};
              this.initialize();
              this.montant="";
              this.loadBtn = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            console.log(error);
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.initialize();
            this.montant="";
            this.loadBtn = false;
          });
    },
    onChange(obj){
      this.carte = obj;
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    likesAllProduit() {
      return this.editedItem.produits.length === this.produits.length;
    },
    icon() {
      if (this.likesAllProduit) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
            .locale("fr")
            .format("L");

        return date;
      }
    }
  },
  mounted() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.initialize();
  },
  beforeDestroy() {}
};
</script>

<style src="./RechargePM.scss" lang="scss" scoped />
