<template>
  <v-img
      :src="require('../../assets/bg.jpg')"
      style="width: 100%; height: 100%"
  >
    <div class="login-page" style="background-color: rgba(3,73,142, 0.8)">
      <b-container>
        <h5 class="logo" style="color:#03498E">
          <v-row justify="center">
            <v-col cols="12" sm="4"> </v-col>
            <v-img
                :src="require('../../assets/ocard1.png')"
                aspect-ratio="1.7"
                contain
                width="200"
                height="200"
            ></v-img>
            <v-col cols="12" sm="4"> </v-col>
          </v-row>
        </h5>
        <Widget
            class="mx-auto elevation-0"
            title="<h3 class='mt-0'>Authentification</h3>"
            customHeader
        >
          <p class="text-muted mb-0 mt fs-sm"></p>
          <form class="mt" @submit.prevent="login">
            <v-alert
                border="right"
                colored-border
                type="error"
                elevation="2"
                v-if="errorMessage"
            >
              Une erreur est survenue ! Veuillez réessayer.
            </v-alert>
            <div class="form-group">
              <v-text-field
                  v-model="username"
                  type="text"
                  name="username"
                  label="Identifiant"
              >
              </v-text-field>
            </div>
            <div class="form-group">
              <v-text-field
                  v-model="password"
                  :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPwd ? 'text' : 'password'"
                  name="password"
                  label="Mot de passe"
                  @click:append="showPwd = !showPwd"
              ></v-text-field>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
              <div class="btn-toolbar">
                <v-btn class="ma-2" type="submit" outlined color="#F6852D"
                       :loading="loadBtn" :disabled="loadBtn"
                >Se connecter</v-btn
                >
              </div>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
              <div class="btn-toolbar">
                <v-btn class="ma-1"
                       color="#039be5"
                       outlined
                       @click="goToActiveSpace"
                >Activer mon Espace</v-btn
                >
              </div>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
              <div class="btn-toolbar">
                <v-btn class="ma-1"
                       color="#f4511e"
                       outlined
                       @click="goToNewSpace"
                >Je n'ai pas encore de carte</v-btn
                >
              </div>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
              <div class="btn-toolbar">
                <v-btn class="ma-1"
                       color="primary"
                       plain
                       @click="readFile"
                >Comment activer l'espace carte?</v-btn
                >
              </div>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
<!--              <div class="btn-toolbar">
                <v-btn
                    class="ma-1"
                    color="gray"
                    outlined
                    @click="goToForgotPassword"
                >
                  Mot de passe oublié ?
                </v-btn>
              </div>-->
            </div>
            <div class="row no-gutters mt-3"></div>
          </form>
        </Widget>
      </b-container>
      <footer class="footer" style="color:#fff" href="/signIn">
        {{ new Date().getFullYear() }} &copy;
        <v-btn text href="/" style="color:#fff">ocard.olaenergy.re</v-btn>
      </footer>
    </div>
  </v-img>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import store from "@/store";
import router from "@/router";
import { host } from "@/utils/constants";
import { mapGetters } from "vuex";
import qs from "querystring";
import axios from "axios";

export default {
  components: { Widget },
  data() {
    return {
      username: "",
      password: "",
      errorMessage: false,
      showPwd: false,
      loadBtn: false
    };
  },
  computed: {
    ...mapGetters("auth", ["hasAuthentication"])
  },
  created() {
    if (this.hasAuthentication) {
      router.push("/app/home");
    }
  },
  methods: {
    async login() {
      if (this.username.length !== 0 && this.password.length !== 0) {
        this.loadBtn = true;

        var data = {
          grant_type: "password",
          username: this.username,
          password: this.password,
          client_id: "browser",
          client_secret: "1234",
          redirect_url: host + "/uaa/oauth/token"
        };
        axios({
          method: "POST",
          url: host + "/uaa/oauth/token",
          baseUrl: host,
          mode: 'no-cors',
          headers: {
            "Access-Control-Allow-Origin": "*",
            "content-type": "application/x-www-form-urlencoded",
          },
          credentials: 'same-origin',
          data: qs.stringify(data)
        })
            .then(async result => {
              const auth = {
                headers: {
                  Authorization: "Bearer " + result.data.access_token
                }
              };
              axios
                  .get(
                      host + "/accounts/me",
                      auth
                  )
                  .then(async res => {
                    let role = res.data.authorities[0].authority;

                    if (
                        role === "ROLE_ADMIN" ||
                        role === "ROLE_COMMERCIAL" ||
                        role === "ROLE_CONTROLEUR"
                    ) {
                      await store.dispatch("auth/signIn", {
                        role: role,
                        token: result.data.access_token,
                        expires_in: result.data.expires_in,
                        entity: null,
                        username: this.username
                      });
                      if (this.hasAuthentication) {
                        this.loadBtn = true;
                        router.push("/app/home");
                      }
                    } else {
                      switch (role) {
                        case "ROLE_GERANT":
                        case "ROLE_STATION":
                          axios
                              .get(
                                  host +
                                  "/stations/account/" +
                                  res.data.userAuthentication.details.principal.id,
                                  auth
                              )
                              .then(async r => {
                                await store.dispatch("auth/signIn", {
                                  role: role,
                                  token: result.data.access_token,
                                  expires_in: result.data.expires_in,
                                  entity: r.data,
                                  username: this.username
                                });
                                if (this.hasAuthentication) {
                                  this.loadBtn = false;
                                  router.push("/app/home");
                                }
                              })
                              // eslint-disable-next-line no-unused-vars
                              .catch(err => {
                                this.loadBtn = false;
                              });
                          break;
                        case "ROLE_CLIENT":
                          axios
                              .get(host + "/clients/account/" +
                                  res.data.userAuthentication.details.principal.id,
                                  auth
                              )
                              .then(async r => {
                                await store.dispatch("auth/signIn", {
                                  role: role,
                                  token: result.data.access_token,
                                  expires_in: result.data.expires_in,
                                  entity: r.data,
                                  username: this.username
                                });
                                if (this.hasAuthentication) {
                                  this.loadBtn = false;
                                  router.push("/app/home");
                                }
                              })
                              // eslint-disable-next-line no-unused-vars
                              .catch(err => {
                                this.loadBtn = false;
                              });
                          break;
                      }
                    }
                  })
                  // eslint-disable-next-line no-unused-vars
                  .catch(err => {
                    this.loadBtn = false;
                    this.errorMessage = true;
                  });
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.loadBtn = false;
              this.errorMessage = true;
            });
      }
    },
    goToForgotPassword() {
      router.push("/forgot-password");
    },
    goToActiveSpace() {
      router.push("/active-space");
    },
    goToNewSpace() {
      router.push("/new-spacePM");
    },
    readFile() {
      window.open('Notice_MyOcard_V1.2.pdf', '_blank')
    }
  }
};
</script>

<style src="./Login.scss" lang="scss" scoped />
