<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Cartes</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="text-right">
        <v-btn
            color="success"
            class="ma-2 white--text"
            @click="sortCartes()"
            :loading="loadCSV"
            :disabled="loadCSV"
        >Télécharger CSV <v-icon right dark>mdi-cloud-download</v-icon>
        </v-btn>
      </div>
      <div class="table-responsive">
        <v-skeleton-loader
            v-if="
            role === 'ROLE_ADMIN' ||
              role === 'ROLE_CONTROLEUR' ||
              role === 'ROLE_COMMERCIAL'
          "
            :loading="loadData"
            type="table"
        >
          <v-data-table
              :headers="headers"
              :items="cartes"
              :search="search"
              sort-by="nom"
              class="elevation-0"
              :loading="loadDataIn"
          >
            <template v-slot:item.status="{ item }">
              <v-btn
                  class="ma-2"
                  tile
                  outlined
                  :color="getColor(item.status)"
                  @click="updateStatut(item)"
              >
                <span v-if="item.status === 'ACTIVE'"
                >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else-if="item.status === 'PREACTIVE'"
                >Pré Active
                  <v-icon color="orange">mdi-exclamation</v-icon></span
                >
                <span v-else-if="item.status === 'DEMDEBLK'"
                >Demande <v-icon color="orange">mdi-exclamation</v-icon></span
                >
                <span v-else-if="item.status === 'ERRPIN'"
                >Erreur Pin<v-icon color="orange"
                >mdi-exclamation</v-icon
                ></span
                >
                <span v-else
                >Inactive <v-icon color="red">mdi-close</v-icon></span
                >
              </v-btn>
            </template>
            <template v-slot:item.solde="{ item }">
              <span>{{ formatFloat(item.solde) }} €</span>
            </template>
            <template v-slot:item.hors_parc="{ item }">
              <span v-if="item.hors_parc === true">Hors Parc</span>
              <span v-else-if="item.vehicule !== null">{{
                  item.vehicule.immatriculation
                }}</span>
            </template>
            <template v-slot:item.createdDate="{ item }">
              {{ item.createdDate | formatUnix }}
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-row justify="center" class="mt-6">
                  <v-col cols="12" md="5">
                    <v-text-field
                        v-model="search"
                        label="Recherche par N° Carte, Client ou Immatriculation"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                        :items="TypeCartes"
                        v-model="cartesFilterValue"
                        label="Type de Cartes"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-dialog
                    v-model="dialogEditOptions"
                    max-width="700px"
                    persistent
                >
                  <v-card>
                    <v-card-title class="headline"
                    >Modifier les options</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" sm="12">
                            <v-select
                                v-model="editedItem.produits"
                                :items="displayProduits"
                                item-text="libelle"
                                label="Produits"
                                return-object
                                multiple
                                outlined
                            >
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                  <v-list-item-action>
                                    <v-icon
                                        :color="
                                        selectedProduits.length > 0
                                          ? 'indigo darken-4'
                                          : ''
                                      "
                                    >{{ icon }}</v-icon
                                    >
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title
                                    >Tout sélectionner</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="3">
                            <h4>Options</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="5">
                            <v-checkbox
                                v-model="editedItem.vehicule.saisi_km"
                                label="KM"
                                :disabled="editedItem.hors_parc || editedItem.typePayement === 'PORTE_MONNAIE'"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-checkbox
                                v-model="editedItem.vehicule.code_chauffeur"
                                label="Code Chauffeur"
                                :disabled="editedItem.hors_parc || editedItem.typePayement === 'PORTE_MONNAIE'"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" align="center">
                            <h4>Plage horaire d'utilisation</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="4">
                            <v-checkbox
                                v-model="restrictionjour1.active"
                                label="Lundi - Vendredi"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour1.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours1Start"
                                transition="scale-transition"
                                v-model="mEditHours1Start"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour1.active"
                                    clearable
                                    label="Heure Début"
                                    v-model="restrictionjour1.heure_debut"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour1.heure_debut"
                                  :max="restrictionjour1.heure_fin"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours1Start = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours1Start = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour1.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours1End"
                                transition="scale-transition"
                                v-model="mEditHours1End"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour1.active"
                                    clearable
                                    label="Heure Fin"
                                    v-model="restrictionjour1.heure_fin"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour1.heure_fin"
                                  :min="restrictionjour1.heure_debut"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours1End = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours1End = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="4">
                            <v-checkbox
                                v-model="restrictionjour2.active"
                                label="Samedi"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour2.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours2Start"
                                transition="scale-transition"
                                v-model="mEditHours2Start"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour2.active"
                                    clearable
                                    label="Heure Début"
                                    v-model="restrictionjour2.heure_debut"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour2.heure_debut"
                                  :max="restrictionjour2.heure_fin"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours2Start = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours2Start = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour2.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours2End"
                                transition="scale-transition"
                                v-model="mEditHours2End"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour2.active"
                                    clearable
                                    label="Heure Fin"
                                    v-model="restrictionjour2.heure_fin"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour2.heure_fin"
                                  :min="restrictionjour2.heure_debut"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours2End = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours2End = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="4">
                            <v-checkbox
                                v-model="restrictionjour3.active"
                                label="Dimanche"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour3.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours3Start"
                                transition="scale-transition"
                                v-model="mEditHours3Start"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour3.active"
                                    clearable
                                    label="Heure Début"
                                    v-model="restrictionjour3.heure_debut"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour3.heure_debut"
                                  :max="restrictionjour3.heure_fin"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours3Start = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours3Start = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour3.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours3End"
                                transition="scale-transition"
                                v-model="mEditHours3End"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour3.active"
                                    clearable
                                    label="Heure Fin"
                                    v-model="restrictionjour3.heure_fin"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour3.heure_fin"
                                  :min="restrictionjour3.heure_debut"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours3End = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours3End = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                          color="error"
                          text
                          @click="dialogEditOptions = false"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Annuler</v-btn
                      >
                      <v-btn
                          color="#03498E"
                          text
                          @click="editingOptions"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Modifier</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogEditPlafond"
                    max-width="500px"
                    persistent
                >
                  <v-card>
                    <v-card-title class="headline"
                    >Modifier les options</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="editedItem.limit.montant"
                                label="Plafond"
                                required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="12">
                            <v-select
                                v-model="editedItem.limit.periodicite"
                                :items="periodes"
                                label="Période"
                                outlined
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="7">
                            <h4>Stations Non Autorisées</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="10">
                            <v-select
                                v-model="editedItem.blacklist"
                                :items="stations"
                                item-text="nom"
                                label="Station(s)"
                                hint="Sélectionner un ou plusieurs stations à interdire"
                                return-object
                                multiple
                                outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                          color="error"
                          text
                          @click="dialogEditPlafond = false"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Annuler</v-btn
                      >
                      <v-btn
                          color="#03498E"
                          text
                          @click="editingPlafond"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Modifier</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogInfo" max-width="700px" persistent>
                  <v-card>
                    <v-card-title class="headline">Information</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.serialNumber"
                                label="Numéro de carte"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.libelle"
                                label="Libellé de carte"
                                readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="8">
                            <v-text-field
                                v-model="editedItem.typePayement"
                                label="Type de Paiement"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-switch
                                v-model="editedItem.hors_parc"
                                :label="`Hors Parc`"
                                readonly
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedItem.vehicule.immatriculation"
                                label="Immatriculation"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-switch
                                v-model="switchKm"
                                :label="`Kilomètrage`"
                                readonly
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="3" v-show="switchKm">
                            <v-text-field
                                v-model="editedItem.vehicule.km"
                                label="Kilométrage"
                                readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-if="editedItem.client !== null"
                                v-model="editedItem.client.entreprise.enseigne"
                                label="Enseigne Entreprise"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-if="editedItem.client !== null"
                                v-model="editedItem.client.entreprise.activite"
                                label="Activité Entreprise"
                                readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="12">
                            <v-select
                                v-model="editedItem.produits"
                                :items="displayProduits"
                                item-text="libelle"
                                label="Produits"
                                return-object
                                multiple
                                readonly
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" align="center">
                            <h4>Plage horaire d'utilisation</h4>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-list disabled>
                            <v-list-item-group color="primary">
                              <v-list-item
                                  v-for="(item, i) in editedItem.restriction"
                                  :key="i"
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-clock</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{item.restrictionJour}} -
                                    {{ item.heure_debut | formatHour }} à
                                    {{ item.heure_fin | formatHour }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="3">
                            <h4>Limites</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.limit.montant"
                                label="Plafond"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.limit.periodicite"
                                label="Périodicité"
                                readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-expansion-panels flat>
                          <v-expansion-panel>
                            <v-expansion-panel-header>
                              <template v-slot:default="{ open }">
                                <v-row no-gutters>
                                  <v-col cols="7">
                                    <h4>Stations Non Autorisées</h4>
                                  </v-col>
                                </v-row>
                              </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                              <v-row
                                  justify="center"
                                  v-if="editedItem.blacklist === null"
                              >
                                <span class="font-italic"
                                >Aucune station bloquée</span
                                >
                              </v-row>
                              <v-list v-else>
                                <v-list-item-group color="primary">
                                  <v-list-item
                                      v-for="(item, i) in editedItem.blacklist"
                                      :key="i"
                                  >
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                          item.nom
                                        }}</v-list-item-title>
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-expansion-panel-content>
                          </v-expansion-panel>
                        </v-expansion-panels>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="#03498E" text @click="dialogInfo = false"
                      >Fermer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogUpdateStatus" max-width="290px">
                  <v-card>
                    <v-card-title
                        class="headline"
                        v-show="updatedItem.status === 'ACTIVE'"
                    >Bloquer cette carte ?</v-card-title
                    >
                    <v-card-title
                        class="headline"
                        v-show="
                        updatedItem.status === 'DEMDEBLK' ||
                          updatedItem.status === 'INACTIVE' ||
                          updatedItem.status === 'ERRPIN'
                      "
                    >Débloquer cette carte ?</v-card-title
                    >
                    <v-card-title
                        class="headline"
                        v-show="updatedItem.status === 'PREACTIVE'"
                    >Activer cette carte ?</v-card-title
                    >
                    <v-card-text v-show="updatedItem.status === 'ACTIVE'"
                    >Êtes-vous sûr de vouloir bloquer cette carte
                      ?</v-card-text
                    >
                    <v-card-text
                        v-show="
                        updatedItem.status === 'DEMDEBLK' ||
                          updatedItem.status === 'ERRPIN' ||
                          updatedItem.status === 'INACTIVE'
                      "
                    >Êtes-vous sûr de vouloir débloquer cette carte
                      ?</v-card-text
                    >
                    <v-card-text v-show="updatedItem.status === 'PREACTIVE'"
                    >Êtes-vous sûr de vouloir activer cette carte
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                          color="error"
                          text
                          @click="dialogUpdateStatus = false"
                      >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="updatingStatus"
                      >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="350px">
                  <v-card>
                    <v-card-title class="headline"
                    >Supprimer cette demande ?</v-card-title
                    >
                    <v-card-text
                    >Êtes-vous sûr de vouloir supprimer cette demande
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogDelete = false"
                      >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="deletingItem"
                      >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip
                  top
                  v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon size="20" v-on="on" @click="editOptions(item)"
                  >mdi-filter-outline</v-icon
                  >
                </template>
                <span>Modifier les options</span>
              </v-tooltip>
              <v-tooltip
                  top
                  v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon size="20" v-on="on" @click="editPlafond(item)"
                  >mdi-cash-100</v-icon
                  >
                </template>
                <span>Modifier le plafond / Stations non autorisées</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" v-on="on" @click="showDialogInfo(item)"
                  >mdi-information-outline</v-icon
                  >
                </template>
                <span>Plus d'informations</span>
              </v-tooltip>
              <v-tooltip top v-if="role === 'ROLE_ADMIN'">
                <template v-slot:activator="{ on }">
                  <v-icon
                      size="20"
                      @click="deleteItem(item)"
                      color="red darken-2"
                  >
                    mdi-delete-outline</v-icon
                  >
                </template>
                <span>Suppression</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
        <v-skeleton-loader
            v-if="role === 'ROLE_CLIENT'"
            :loading="loadData"
            type="table"
        >
          <v-data-table
              :headers="headersClient"
              :items="cartes"
              :search="search"
              sort-by="nom"
              class="elevation-0"
              :loading="loadDataIn"
          >
            <template v-slot:item.status="{ item }">
              <v-btn
                  class="ma-2"
                  tile
                  outlined
                  :color="getColor(item.status)"
                  @click="updateStatut(item)"
              >
                <span v-if="item.status === 'ACTIVE'"
                >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else-if="item.status === 'PREACTIVE'"
                >Pré Active
                  <v-icon color="orange">mdi-exclamation</v-icon></span
                >
                <span v-else-if="item.status === 'DEMDEBLK'"
                >Demande <v-icon color="orange">mdi-exclamation</v-icon></span
                >
                <span v-else-if="item.status === 'ERRPIN'"
                >Erreur Pin<v-icon color="orange"
                >mdi-exclamation</v-icon
                ></span
                >
                <span v-else
                >Inactive <v-icon color="red">mdi-close</v-icon></span
                >
              </v-btn>
            </template>
            <template v-slot:item.pinCode="{ item }">
              <v-edit-dialog
                  :return-value.sync="item.pinCode"
                  large
                  lazy
                  persistent
                  cancel-text="Annuler"
                  save-text="Valider"
                  @save="savePinCode(item)"
              >
                {{ item.pinCode }}
                <v-text-field
                    slot="input"
                    label="Edite"
                    v-model="item.pinCode"
                    v-mask="maskPincode"
                    single-line
                    autofocus
                    :rules="[
                    v =>
                      v.length === 4 || 'Le pin code doit faire 4 caratères !'
                  ]"
                ></v-text-field>
              </v-edit-dialog>
            </template>
            <template v-slot:item.hors_parc="{ item }">
              <span v-if="item.hors_parc === true">Hors Parc</span>
              <span v-else-if="item.vehicule !== null">{{
                  item.vehicule.immatriculation
                }}</span>
            </template>
            <template v-slot:item.createdDate="{ item }">
              {{ item.createdDate | formatUnix }}
            </template>
            <template v-slot:item.solde="{ item }">
              <span>{{ formatFloat(item.solde) }} €</span>
            </template>
            <template v-slot:item.limit.montant="{ item }">
              <span>{{ formatFloat(item.limit.montant) }} €</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-row justify="center" class="mt-6">
                  <v-col cols="12" md="5">
                    <v-text-field
                        v-model="search"
                        label="Recherche par N° Carte ou Immatriculation"
                        single-line
                        hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-select
                        :items="TypeCartes"
                        v-model="cartesFilterValue"
                        label="Type de Cartes"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-dialog
                    v-model="dialogEditOptions"
                    max-width="700px"
                    persistent
                >
                  <v-card>
                    <v-card-title class="headline"
                    >Modifier les options</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" sm="12">
                            <v-select
                                v-model="editedItem.produits"
                                :items="displayProduits"
                                item-text="libelle"
                                label="Produits"
                                return-object
                                multiple
                                outlined
                            >
                              <template v-slot:prepend-item>
                                <v-list-item ripple @click="toggle">
                                  <v-list-item-action>
                                    <v-icon
                                        :color="
                                        selectedProduits.length > 0
                                          ? 'indigo darken-4'
                                          : ''
                                      "
                                    >{{ icon }}</v-icon
                                    >
                                  </v-list-item-action>
                                  <v-list-item-content>
                                    <v-list-item-title
                                    >Tout sélectionner</v-list-item-title
                                    >
                                  </v-list-item-content>
                                </v-list-item>
                                <v-divider class="mt-2"></v-divider>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="3">
                            <h4>Options</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center" v-if="!editedItem.hors_parc">
                          <v-col cols="12" sm="5">
                            <v-checkbox
                                v-model="editedItem.vehicule.saisi_km"
                                label="KM"
                                :disabled="editedItem.hors_parc || editedItem.typePayement === 'PORTE_MONNAIE'"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-checkbox
                                v-model="editedItem.vehicule.code_chauffeur"
                                label="Code Chauffeur"
                                :disabled="editedItem.hors_parc || editedItem.typePayement === 'PORTE_MONNAIE'"
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.limit.montant"
                                label="Plafond"
                                required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-select
                                v-model="editedItem.limit.periodicite"
                                :items="periodes"
                                label="Période"
                                outlined
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" align="center">
                            <h4>Plage horaire d'utilisation</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="4">
                            <v-checkbox
                                v-model="restrictionjour1.active"
                                label="Lundi - Vendredi"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour1.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours1Start"
                                transition="scale-transition"
                                v-model="mEditHours1Start"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour1.active"
                                    clearable
                                    label="Heure Début"
                                    v-model="restrictionjour1.heure_debut"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour1.heure_debut"
                                  :max="restrictionjour1.heure_fin"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours1Start = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours1Start = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour1.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours1End"
                                transition="scale-transition"
                                v-model="mEditHours1End"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour1.active"
                                    clearable
                                    label="Heure Fin"
                                    v-model="restrictionjour1.heure_fin"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour1.heure_fin"
                                  :min="restrictionjour1.heure_debut"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours1End = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours1End = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="4">
                            <v-checkbox
                                v-model="restrictionjour2.active"
                                label="Samedi"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour2.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours2Start"
                                transition="scale-transition"
                                v-model="mEditHours2Start"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour2.active"
                                    clearable
                                    label="Heure Début"
                                    v-model="restrictionjour2.heure_debut"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour2.heure_debut"
                                  :max="restrictionjour2.heure_fin"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours2Start = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours2Start = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour2.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours2End"
                                transition="scale-transition"
                                v-model="mEditHours2End"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour2.active"
                                    clearable
                                    label="Heure Fin"
                                    v-model="restrictionjour2.heure_fin"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour2.heure_fin"
                                  :min="restrictionjour2.heure_debut"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours2End = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours2End = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="4">
                            <v-checkbox
                                v-model="restrictionjour3.active"
                                label="Dimanche"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour3.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours3Start"
                                transition="scale-transition"
                                v-model="mEditHours3Start"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour3.active"
                                    clearable
                                    label="Heure Début"
                                    v-model="restrictionjour3.heure_debut"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour3.heure_debut"
                                  :max="restrictionjour3.heure_fin"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours3Start = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours3Start = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-menu
                                :close-on-content-click="false"
                                :disabled="!restrictionjour3.active"
                                min-width="290px"
                                offset-y
                                ref="mEditHours3End"
                                transition="scale-transition"
                                v-model="mEditHours3End"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                    :disabled="!restrictionjour3.active"
                                    clearable
                                    label="Heure Fin"
                                    v-model="restrictionjour3.heure_fin"
                                    readonly
                                    v-on="on"
                                ></v-text-field>
                              </template>
                              <v-time-picker
                                  format="24hr"
                                  v-model="restrictionjour3.heure_fin"
                                  :min="restrictionjour3.heure_debut"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                    @click="mEditHours3End = false"
                                    color="primary"
                                    text
                                >Annuler</v-btn
                                >
                                <v-btn
                                    @click="mEditHours3End = false"
                                    color="primary"
                                    text
                                >Valider</v-btn
                                >
                              </v-time-picker>
                            </v-menu>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="7">
                            <h4>Stations Non Autorisées</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="12">
                            <v-select
                                v-model="editedItem.blacklist"
                                :items="stations"
                                item-text="nom"
                                label="Station(s)"
                                return-object
                                multiple
                                outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                          color="error"
                          text
                          @click="dialogEditOptions = false"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Annuler</v-btn
                      >
                      <v-btn
                          color="#03498E"
                          text
                          @click="editingOptions"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Modifier</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogInfo" max-width="700px" persistent>
                  <v-card>
                    <v-card-title class="headline">Information</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.serialNumber"
                                label="Numéro de carte"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.libelle"
                                label="Libellé de carte"
                                readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="8">
                            <v-text-field
                                v-model="editedItem.typePayement"
                                label="Type de Paiement"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4" v-if="!editedItem.hors_parc">
                            <v-switch
                                v-model="editedItem.hors_parc"
                                :label="`Hors Parc`"
                                readonly
                            ></v-switch>
                          </v-col>
                        </v-row>
                        <v-row justify="center" v-if="!editedItem.hors_parc">
                          <v-col cols="12" md="6">
                            <v-text-field
                                v-model="editedItem.vehicule.immatriculation"
                                label="Immatriculation"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="3">
                            <v-switch
                                v-model="switchKm"
                                :label="`Kilomètrage`"
                                readonly
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="3" v-show="switchKm">
                            <v-text-field
                                v-model="editedItem.vehicule.km"
                                label="Kilométrage"
                                readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-if="editedItem.client !== null"
                                v-model="editedItem.client.entreprise.enseigne"
                                label="Enseigne Entreprise"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-if="editedItem.client !== null"
                                v-model="editedItem.client.entreprise.activite"
                                label="Activité Entreprise"
                                readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="12">
                            <v-select
                                v-model="editedItem.produits"
                                :items="displayProduits"
                                item-text="libelle"
                                label="Produits"
                                return-object
                                multiple
                                readonly
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" align="center">
                            <h4>Plage horaire d'utilisation</h4>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-list disabled>
                            <v-list-item-group color="primary">
                              <v-list-item
                                  v-for="(item, i) in editedItem.restriction"
                                  :key="i"
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-clock</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{item.restrictionJour}} -
                                    {{ item.heure_debut | formatHour }} à
                                    {{ item.heure_fin | formatHour }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-row>
                        <v-row justify="center" v-if="!PM">
                          <v-col cols="3">
                            <h4>Limites</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center" v-if="!PM">
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.limit.montant"
                                label="Plafond"
                                readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                v-model="editedItem.limit.periodicite"
                                label="Périodicité"
                                readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="7">
                            <h4>Stations Non Autorisées</h4>
                          </v-col>
                        </v-row>
                        <v-row
                            justify="center"
                            v-if="editedItem.blacklist === null"
                        >
                          <span class="subtitle-2"
                          >Aucune station interdite</span
                          >
                        </v-row>
                        <v-row
                            justify="center"
                            v-if="editedItem.blacklist !== null"
                        >
                          <v-col cols="12" sm="10">
                            <v-combobox
                                v-model="editedItem.blacklist"
                                item-text="nom"
                                label="Station(s)"
                                multiple
                                readonly
                            ></v-combobox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="#03498E" text @click="dialogInfo = false"
                      >Fermer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog
                    v-model="dialogEditPlafond"
                    max-width="700px"
                    persistent
                >
                  <v-card>
                    <v-card-title class="headline"
                    >Modifier les options</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <v-row justify="center" v-if="!PM">
                          <v-col cols="12" sm="12">
                            <v-text-field
                                v-model="editedItem.limit.montant"
                                label="Plafond"
                                required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center" v-if="!PM">
                          <v-col cols="12" sm="12">
                            <v-select
                                v-model="editedItem.limit.periodicite"
                                :items="periodes"
                                label="Période"
                                outlined
                            >
                            </v-select>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="7">
                            <h4>Stations Non Autorisées</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="12">
                            <v-select
                                v-model="editedItem.blacklist"
                                :items="stations"
                                item-text="nom"
                                label="Station(s)"
                                hint="Sélectionner un ou plusieurs stations à interdire"
                                return-object
                                multiple
                                outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                          color="error"
                          text
                          @click="dialogEditPlafond = false"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Annuler</v-btn
                      >
                      <v-btn
                          color="#03498E"
                          text
                          @click="editingPlafond"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Modifier</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogUpdateStatus" max-width="290px">
                  <v-card>
                    <v-card-title
                        class="headline"
                        v-show="updatedItem.status === 'ACTIVE'"
                    >Bloquer cette carte ?</v-card-title
                    >
                    <v-card-title
                        class="headline"
                        v-show="
                        updatedItem.status === 'DEMDEBLK' ||
                          updatedItem.status === 'INACTIVE' ||
                          updatedItem.status === 'ERRPIN'
                      "
                    >Débloquer cette carte ?</v-card-title
                    >
                    <v-card-title
                        class="headline"
                        v-show="updatedItem.status === 'PREACTIVE'"
                    >Activer cette carte ?</v-card-title
                    >
                    <v-card-text v-show="updatedItem.status === 'ACTIVE'"
                    >Êtes-vous sûr de vouloir bloquer cette carte
                      ?</v-card-text
                    >
                    <v-card-text
                        v-show="
                        updatedItem.status === 'DEMDEBLK' ||
                          updatedItem.status === 'ERRPIN' ||
                          updatedItem.status === 'INACTIVE'
                      "
                    >Êtes-vous sûr de vouloir débloquer cette carte
                      ?</v-card-text
                    >
                    <v-card-text v-show="updatedItem.status === 'PREACTIVE'"
                    >Êtes-vous sûr de vouloir activer cette carte
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                          color="error"
                          text
                          @click="dialogUpdateStatus = false"
                      >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="updatingStatus"
                      >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="350px">
                  <v-card>
                    <v-card-title class="headline"
                    >Supprimer cette carte ?</v-card-title
                    >
                    <v-card-text
                    >Êtes-vous sûr de vouloir supprimer cette carte
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogDelete = false"
                      >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="deletingItem"
                      >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" v-on="on" @click="editOptions(item)"
                  >mdi-filter-outline</v-icon
                  >
                </template>
                <span>Modifier les options</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" v-on="on" @click="showDialogInfo(item)"
                  >mdi-information-outline</v-icon
                  >
                </template>
                <span>Plus d'informations</span>
              </v-tooltip>
              <!--              <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon size="20" v-on="on" @click="editPlafond(item)"
                                  >mdi-cash-100</v-icon
                                >
                              </template>
                              <span>Modifier le plafond / Stations non autorisées</span>
                            </v-tooltip>
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon
                                  size="20"
                                  @click="deleteItem(item)"
                                  color="red darken-2"
                                >
                                  mdi-delete-outline</v-icon
                                >
                              </template>
                              <span>Suppression</span>
                            </v-tooltip>-->
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";
import Papa from "papaparse";
import "moment/locale/fr";
import * as _ from "lodash";
import { mask } from "vue-the-mask";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";

global.EventSource = NativeEventSource || EventSourcePolyfill;
let evtSource = false;

export default {
  name: "Cartes",
  components: { Widget },
  directives: {
    mask
  },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      loadBtn: false,
      loadData: true,
      loadDataIn: true,
      loadCSV: false,
      dialog: false,
      dialogEditOptions: false,
      dialogEditPlafond: false,
      dialogUpdateStatus: false,
      dialogInfo: false,
      dialogDelete: false,
      dialogPin: false,
      sb: { status: false, text: "", color: "", y: "top" },
      TypeCartes: [
        { text: "TOUT", value: null },
        { text: "PRO", value: "PRO" },
        { text: "PORTE MONNAIE", value: "PORTE_MONNAIE" },
        { text: "PRE PAYEE", value: "PRE_PAYEE" }
      ],
      cartesFilterValue: null,
      search: "",
      headers: [
        {
          text: "N° Carte",
          align: "left",
          value: "serialNumber"
        },
        { text: "N° Client", value: "client.entreprise.contratNumero" },
        {
          text: "Enseigne Client",
          value: "client.entreprise.enseigne"
        },
        { text: "Date Création", value: "createdDate", filterable: false },
        { text: "Solde", value: "solde" },
        { text: "Type", value: "typePayement", filter: this.cartesFilter },
        { text: "Immat / HP", value: "hors_parc" },
        { text: "Statut", value: "status", filterable: false },
        { text: "Actions", value: "action", sortable: false, filterable: false }
      ],
      headersClient: [
        {
          text: "N° Carte",
          align: "left",
          value: "serialNumber"
        },
        { text: "Date Création", value: "createdDate", filterable: false },
        { text: "Immat / HP", value: "hors_parc" },
        { text: "Libelle", value: "libelle" },
        { text: "Type", value: "typePayement", filter: this.cartesFilter },
        { text: "Solde", value: "solde" },
        { text: "Plafond", value: "limit.montant" },
        { text: "Pincode", value: "pinCode" },
        { text: "Statut", value: "status", filterable: false },
        { text: "Actions", value: "action", sortable: false, filterable: false }
      ],
      cartes: [],
      initCartes: [],
      maskPincode: "####",
      mEditHours1Start: false,
      mEditHours1End: false,
      mEditHours2Start: false,
      mEditHours2End: false,
      mEditHours3Start: false,
      mEditHours3End: false,
      restrictionjour1: {
        active: false,
        heure_debut: null,
        heure_fin: null,
        restrictionJour: "LUNDI_VENDREDI"
      },
      restrictionjour2: {
        active: false,
        heure_debut: null,
        heure_fin: null,
        restrictionJour: "SAMEDI"
      },
      restrictionjour3: {
        active: false,
        heure_debut: null,
        heure_fin: null,
        restrictionJour: "DIMANCHE"
      },
      editedItem: {
        createdDate: "",
        status: null,
        serialNumber: "",
        libelle: "",
        dematerialiser: true,
        hors_parc: true,
        typePayement: "",
        client: {
          id: "",
          nom: "",
          prenom: "",
          entreprise: {
            enseigne: "",
            email: null,
            activite: "",
            siret: "",
            numeroRcs: 0,
            contratNumero: "",
            contratDate: "",
            contratExpire: true
          }
        },
        produits: [
          {
            id: "",
            code: "",
            libelle: "",
            status: "ACTIVE"
          }
        ],
        restriction: {
          heure_debut: "",
          heure_fin: "",
          restrictionJour: ""
        },
        limit: {
          frequenceJour: 0,
          montant: 0,
          montantJour: 0,
          periodicite: "",
          volume: 0
        },
        uuid: "",
        vehicule: {
          immatriculation: "",
          km: ""
        },
        blacklist: []
      },
      updatedItem: {},
      switchKm: false,
      produits: [],
      displayProduits: [],
      participationProduits: [],
      selectedProduits: [],
      periodes: ["JOUR", "HEB", "MOIS", "TRIM", "AN"],
      selectedPeriodes: [],
      status: null,
      stations: [],
      cartesCSV: [],
      PM: false,
    };
  },
  methods: {
    initialize() {
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
          .get(host + "/produits/", auth)
          .then(result => {
            this.produits = _.sortBy(result.data, "code");
          })
          .catch(err => {
            this.produits = [];
          });

      axios
          .get(host + "/stations/", auth)
          .then(result => {
            this.stations = result.data;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.stations = [];
          });
    },
    showDialogInfo(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.produits = _.sortBy(item.produits, "code");

      if (this.editedItem.vehicule === null) {
        this.editedItem.vehicule = { immatriculation: "", km: "" };
      }
      if (this.editedItem.vehicule.km !== null) {
        this.switchKm = true;
      }

      this.dialogInfo = true;
    },
    editOptions(item) {
      this.displayProduits = [];
      this.editedItem = Object.assign({}, item);
      this.editedItem.produit = _.sortBy(item.produits, "code");
      if (this.editedItem.vehicule === null) {
        this.editedItem.vehicule = { immatriculation: "", km: "" };
      }
      if (
          this.editedItem.typePayement === "PRO" ||
          this.editedItem.typePayement === "PRE_PAYEE"
      ) {
        let arr = [...this.produits];

        arr.splice(
            arr.findIndex(item => item.code === "50"),
            1
        );
        arr.splice(
            arr.findIndex(item => item.code === "51"),
            1
        );

        this.displayProduits = arr;
      } else {
        let arr = [...this.produits];
        arr.forEach(element => {
          if (element.code === "50") {
            this.displayProduits.push(element);
          } else if (element.code === "51") {
            this.displayProduits.push(element);
          }
        });
      }
      this.editedItem.restriction.forEach(element => {
        if (element.restrictionJour === this.restrictionjour1.restrictionJour) {
          let hD = [element.heure_debut.slice(0, 2), ":", element.heure_debut.slice(2)].join('');
          let hF = [element.heure_fin.slice(0, 2), ":", element.heure_fin.slice(2)].join('');
          this.restrictionjour1.active = true;
          this.restrictionjour1.heure_debut = hD;
          this.restrictionjour1.heure_fin = hF;
        } else if (
            element.restrictionJour === this.restrictionjour2.restrictionJour
        ) {
          let hD = [element.heure_debut.slice(0, 2), ":", element.heure_debut.slice(2)].join('');
          let hF = [element.heure_fin.slice(0, 2), ":", element.heure_fin.slice(2)].join('');
          this.restrictionjour2.active = true;
          this.restrictionjour2.heure_debut = hD;
          this.restrictionjour2.heure_fin = hF;
        } else if (
            element.restrictionJour === this.restrictionjour3.restrictionJour
        ) {
          let hD = [element.heure_debut.slice(0, 2), ":", element.heure_debut.slice(2)].join('');
          let hF = [element.heure_fin.slice(0, 2), ":", element.heure_fin.slice(2)].join('');
          this.restrictionjour3.active = true;
          this.restrictionjour3.heure_debut = hD;
          this.restrictionjour3.heure_fin = hF;
        }
      });

      this.dialogEditOptions = true;
    },
    editingOptions() {
      this.loadBtn = true;

      let restrictions = [];
      if (this.restrictionjour1.active) {
        let hd = this.restrictionjour1.heure_debut.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
        let hf = this.restrictionjour1.heure_fin.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
        restrictions.push({
          restrictionJour: this.restrictionjour1.restrictionJour,
          heure_debut: hd,
          heure_fin: hf
        });
      }
      if (this.restrictionjour2.active) {
        let hd = this.restrictionjour2.heure_debut.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
        let hf = this.restrictionjour2.heure_fin.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
        restrictions.push({
          restrictionJour: this.restrictionjour2.restrictionJour,
          heure_debut: hd,
          heure_fin: hf
        });
      }
      if (this.restrictionjour3.active) {
        let hd = this.restrictionjour3.heure_debut.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
        let hf = this.restrictionjour3.heure_fin.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
        restrictions.push({
          restrictionJour: this.restrictionjour3.restrictionJour,
          heure_debut: hd,
          heure_fin: hf
        });
      }



      this.editedItem.restriction = restrictions;

      let item = Object.assign({}, this.editedItem);

      if (item.hors_parc === true || item.typePayement === "PORTE_MONNAIE") {
        item.vehicule = null;
      }

      axios({
        method: "PUT",
        url:
            host + "/cartes/" + item.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: item
      })
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.sb.status = true;
            this.sb.text = "Carte mise a jour ! ";
            this.sb.color = "success";
            this.reloadCarte();
            this.loadBtn = false;
            this.dialogEditOptions = false;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue !";
            this.sb.color = "error";
            this.reloadCarte();
            this.loadBtn = false;
            this.dialogEditOptions = false;
          });
    },
    editPlafond(item) {
      this.editedIndex = this.cartes.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.vehicule === null) {
        this.editedItem.vehicule = { immatriculation: "", km: "" };
      }
      this.dialogEditPlafond = true;
    },
    editingPlafond() {
      this.loadBtn = true;
      let item = Object.assign({}, this.editedItem);

      if (item.hors_parc === true || item.typePayement === "PORTE_MONNAIE") {
        item.vehicule = null;
      }

      axios({
        method: "PUT",
        url:
            host + "/cartes/" + item.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: item
      })
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.sb.status = true;
            this.sb.text = "Carte mise a jour ! ";
            this.sb.color = "success";
            this.reloadCarte();
            this.loadBtn = false;
            this.dialogEditPlafond = false;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.reloadCarte();
            this.loadBtn = false;
            this.dialogEditPlafond = false;
          });
    },
    updateStatut(item) {
      this.updatedItem = Object.assign({}, item);
      if (this.updatedItem.status !== "PREACTIVE") {
        this.dialogUpdateStatus = true;
      } else if (this.updatedItem.status === "PREACTIVE" && this.PM){
        this.dialogUpdateStatus = true;
      }
    },
    updatingStatus() {
      let statut = "ACTIVE";
      if (this.updatedItem.status === "ACTIVE") {
        statut = "INACTIVE";
      }
      axios({
        method: "PUT",
        url:

            host +
            "/cartes/status/" +
            statut +
            "/" +
            this.updatedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.sb.status = true;
            this.sb.text = "Carte mise à jour ! ";
            this.sb.color = "success";
            this.reloadCarte();
            this.dialogUpdateStatus = false;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            console.log(error);
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
          });
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:

            host +
            "/cartes/" +
            this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.sb.status = true;
            this.sb.text = "Carte supprimée ! ";
            this.sb.color = "success";
            this.reloadCarte();
          })
          .catch(error => {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.reloadCarte();
          });

      this.dialogDelete = false;
    },
    sortCartes() {
      this.loadCSV = true;
      this.cartesCSV = [];
      let item = {};
      this.cartes.forEach(element => {
        item = {
          numero: element.serialNumber,
          libelle: element.libelle,
          type: element.typePayement,
          solde: element.solde.toFixed(2).replace(".", ","),
          client: element.client.entreprise.contratNumero,
          enseigne: element.client.entreprise.enseigne,
          hors_parc: "0",
          code_chauffeur: "0",
          saisi_km: "0",
          pincode: element.pinCode,
          immatriculation: "",
          statut: element.status,
          date_creation: moment(element.createdDate).locale("fr").format("DD-MM-YYYY"),
          produitsCode: "",
          produitsLibelle: "",
          restrictionsJour: "",
          restrictions_HeureDebut: "",
          restrictions_HeureFin: "",
          limite_periode: "",
          limite_montant: "",
          blacklist_numeroStation: "",
          blacklist_nomStation: ""
        };

        let produitsCode = "";
        let produitsLibelle = "";
        let restrictionsJour = "";
        let restrictions_HeureDebut = "";
        let restrictions_HeureFin = "";
        let blacklist_numeroStation= "";
        let blacklist_nomStation= "";

        if (element.hors_parc) {
          item.hors_parc = "1";
        }
        if (element.produits !== null) {
          element.produits.forEach(produit => {
            produitsCode = produitsCode.concat(produit.code, " /");
            produitsLibelle = produitsLibelle.concat(produit.libelle, " /");
          });
          item.produitsCode = produitsCode.slice(0, -1);
          item.produitsLibelle = produitsLibelle.slice(0, -1);
        }
        if(element.blacklist !== null){
          element.blacklist.forEach(station => {
            blacklist_numeroStation = blacklist_numeroStation.concat(station.numStation, " /")
            blacklist_nomStation = blacklist_nomStation.concat(station.nom, " /")
          });
          item.blacklist_numeroStation = blacklist_numeroStation.slice(0, -1);
          item.blacklist_nomStation = blacklist_nomStation.slice(0, -1);
        }
        if (element.restriction !== null) {
          element.restriction.forEach(restrict => {
            restrictionsJour = restrictionsJour.concat(
                restrict.restrictionJour,
                " /"
            );
            restrictions_HeureDebut = restrictions_HeureDebut.concat(
                restrict.heure_debut,
                " /"
            );
            restrictions_HeureFin = restrictions_HeureFin.concat(
                restrict.heure_fin,
                " /"
            );
          });
          item.restrictionsJour = restrictionsJour.slice(0, -1);
          item.restrictions_HeureDebut = restrictions_HeureDebut.slice(0, -1);
          item.restrictions_HeureFin = restrictions_HeureFin.slice(0, -1);
        }
        if (element.vehicule !== null) {
          if (element.vehicule.code_chauffeur) {
            item.code_chauffeur = "1";
          }
          if (element.vehicule.saisi_km) {
            item.saisi_km = "1";
          }
          item.immatriculation = element.vehicule.immatriculation;
        }
        if (element.limit !== null) {
          item.limite_periode = element.limit.periodicite;
          item.limite_montant = this.formatFloat(element.limit.montant).replace(".", " ");
        }
        this.cartesCSV.push(item);
      });
      let csv = Papa.unparse(
          {
            fields: [
              "numero",
              "libelle",
              "type",
              "solde",
              "client",
              "enseigne",
              "hors_parc",
              "code_chauffeur",
              "saisi_km",
              "pincode",
              "immatriculation",
              "statut",
              "date_creation",
              "produitsCode",
              "produitsLibelle",
              "restrictionsJour",
              "restrictions_HeureDebut",
              "restrictions_HeureFin",
              "limite_periode",
              "limite_montant",
              "blacklist_numeroStation",
              "blacklist_nomStation"
            ],
            data: this.cartesCSV
          },
          {
            delimiter: ";",
            header: true
          }
      );

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "cartes.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link);
      this.loadCSV = false;
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else if (active === "PREACTIVE" || active === "DEMDEBLK") return "orange";
      else return "red";
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllProduit) {
          this.editedItem.produits = [];
        } else {
          let arr = this.produits.slice();
          this.editedItem.produits = _.sortBy(arr, "code");
        }
      });
    },
    cartesFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.cartesFilterValue) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.cartesFilterValue;
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    savePinCode(item) {
      if (item.pinCode.length === 4) {
        axios({
          method: "PUT",
          url:

              host +
              "/cartes/" +
              item.id,
          baseUrl: host,
          headers: {
            Authorization: "Bearer " + this.token,
            "X-Requested-Width": "XMLHttpRequest"
          },
          data: item
        })
            // eslint-disable-next-line no-unused-vars
            .then(result => {
              this.sb.status = true;
              this.sb.text = "Carte mise à jour ! ";
              this.sb.color = "success";
              this.reloadCarte();
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue ! ";
              this.sb.color = "error";
              this.reloadCarte();
            });
      }
    },
    cancel() {},
    open() {},
    close() {
      this.reloadCarte();
    },
    reloadCarte() {
      this.cartes = [];
      this.loadDataIn = true;
      this.loadData = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      let url = "";
      if (
          this.role === "ROLE_ADMIN" ||
          this.role === "ROLE_CONTROLEUR" ||
          this.role === "ROLE_COMMERCIAL"
      ) {
        url = host + "/cartes/";
        this._loadData(url);
      } else if (this.role === "ROLE_CLIENT") {
        axios
            .get(
                host +
                "/clients/" +
                this.entity.id,
                auth
            )
            .then(result => {
              this.entity = result.data;
              if (result.data.account.username.startsWith('PM') || result.data.account.username.startsWith('MO')) {
                this.PM = true;
                url = host + "/cartes/client/" + this.entity.id;
                this._loadData(url);
              } else {
                url = host + "/cartes/client/" + this.entity.id;
                this._loadData(url);
              }
            });
      }
    },
    _loadData(url) {
      this.cartes = [];
      var that = this;
      evtSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: "Bearer" + this.token
        }
      });

      evtSource.addEventListener("message", function(e) {
        let carte = JSON.parse(e.data);
        that.loadData = false;
        that.loadDataIn = false;
        if(carte.delete != true) {
          that.cartes.push(carte);
        }
      });

      evtSource.addEventListener(
          "error",
          function(event) {
            switch (event.target.readyState) {
              case EventSource.CONNECTING:
                console.log('close');
                that.loadData = false;
                that.loadDataIn = false;
                evtSource.close();
                break;

              case EventSource.CLOSED:
                console.log('close');
                that.loadData = false;
                that.loadDataIn = false;
                break;
            }
          },
          false
      );

      evtSource.addEventListener(
          "close",
          // eslint-disable-next-line no-unused-vars
          function(e) {
            evtSource.close();
            that.loadData = false;
            that.loadDataIn = false;
            console.log('close');
          },
          false
      );
    },
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    likesAllProduit() {
      return this.editedItem.produits.length === this.produits.length;
    },
    icon() {
      if (this.likesAllProduit) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
            .locale("fr")
            .format("L");

        return date;
      }
    },
    formatHour: function(value) {
      if (value) {
        let hour = [value.slice(0, 2), ":", value.slice(2)].join('');
        return hour;
      }
    }
  },
  mounted() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.initialize();
    const auth = {
      headers: {
        Authorization: "Bearer " + this.token,
        "X-Requested-Width": "XMLHttpRequest"
      }
    };
    let url = "";
    if (
        this.role === "ROLE_ADMIN" ||
        this.role === "ROLE_CONTROLEUR" ||
        this.role === "ROLE_COMMERCIAL"
    ) {
      url = host + "/cartes/";
      this._loadData(url);
    } else if (this.role === "ROLE_CLIENT") {
      axios
          .get(
              host +
              "/clients/" +
              this.entity.id,
              auth
          )
          .then(result => {
            this.entity = result.data;
            if (result.data.account.username.startsWith('PM') || result.data.account.username.startsWith('MO')) {
              this.PM = true;
              url = host + "/cartes/client/" + this.entity.id;
              this._loadData(url);
            } else {
              url = host + "/cartes/client/" + this.entity.id;
              this._loadData(url);
            }
          });
    }
  },
  beforeDestroy() {
    evtSource.close();
  }
};
</script>

<style src="./Cartes.scss" lang="scss" scoped />
