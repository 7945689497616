<template>
  <v-img
      :src="require('../../assets/bg.jpg')"
      style="width: 100%; height: 100%"
  >
    <div class="login-page" style="background-color: rgba(3,73,142, 0.8)">
      <b-container>
        <h5 class="logo" style="color:#03498E">
          <v-row justify="center">
            <v-col cols="12" sm="4"> </v-col>
            <v-img
                :src="require('../../assets/ocard1.png')"
                aspect-ratio="1.7"
                contain
                width="200"
                height="200"
            ></v-img>
            <v-col cols="12" sm="4"> </v-col>
          </v-row>
        </h5>
        <Widget
            class="mx-auto elevation-0"
            title="<h3 class='mt-0'>Créer mon espace</h3>"
            customHeader
        >
          <p class="text-muted mb-0 mt fs-sm"></p>
          <v-form ref="form" class="mt" @submit.prevent="newSpace" v-model="valid" lazy-validation>
            <v-alert
                border="right"
                colored-border
                type="error"
                elevation="2"
                v-if="errorMessage"
            >
              Une erreur est survenue ! Veuillez réessayer.
            </v-alert>
            <v-alert
                border="right"
                colored-border
                type="error"
                elevation="2"
                v-if="errorMessage2"
            >
              Il semble que ce compte existe déjà.
            </v-alert>
            <v-alert
                border="top"
                colored-border
                type="info"
                elevation="2"
                v-if="successMessage"
            >
            Votre compte a été bien créé
            </v-alert>
            <div class="form-group">
              <v-text-field
                  v-model="enseigne"
                  type="text"
                  name="enseigne"
                  label="Enseigne ou Nom*"
                  :rules="[v => !!v || 'Champ Requis']"
                  required
              >
              </v-text-field>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="nom"
                      type="text"
                      name="nom"
                      label="Nom *"
                      :rules="[v => !!v || 'Champ Requis']"
                      required
                  >
                  </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="prenom"
                      type="text"
                      name="prenom"
                      label="Prenom *"
                      :rules="[v => !!v || 'Champ Requis']"
                      required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="adresse1"
                      type="text"
                      name="adresse"
                      label="Adresse *"
                      :rules="[v => !!v || 'Champ Requis']"
                      required
                  >
                  </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="adresse2"
                      type="text"
                      name="adresse2"
                      label="Adresse Complémentaire"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="codePostal"
                      type="text"
                      name="codepostal"
                      label="Code Postal *"
                      :rules="[v => !!v || 'Champ Requis']"
                      required
                  >
                  </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="ville"
                      type="text"
                      name="ville"
                      label="Ville *"
                      :rules="[v => !!v || 'Champ Requis']"
                      required
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                  v-model="pays"
                  type="text"
                  name="pays"
                  label="Pays *"
                  :rules="[v => !!v || 'Champ Requis']"
                  required
              >
              </v-text-field>
              <v-row>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="telephone1"
                      type="text"
                      name="telephone1"
                      label="Téléphone *"
                      :rules="[v => !!v || 'Champ Requis']"
                      required
                  >
                  </v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                >
                  <v-text-field
                      v-model="telephone2"
                      type="text"
                      name="telephone2"
                      label="Mobile"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-text-field
                  v-model="email"
                  type="text"
                  name="email"
                  label="Email *"
                  :rules="[v => !!v || 'Champ Requis']"
                  required
              >
              </v-text-field>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
              <div class="btn-toolbar">
                <v-btn class="ma-2" type="submit" outlined color="#F6852D"
                       :loading="loadBtn" :disabled="!valid || loadBtn"
                >Valider</v-btn
                >
              </div>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
              <div class="btn-toolbar">
                <v-btn
                    class="ma-1"
                    color="gray"
                    outlined
                    @click="goToBack"
                >
                  Annuler
                </v-btn>
              </div>
            </div>
            <div class="row no-gutters mt-3"></div>
          </v-form>
        </Widget>
      </b-container>
      <footer class="footer" style="color:#fff" href="/signIn">
        {{ new Date().getFullYear() }} &copy;
        <v-btn text href="/" style="color:#fff">ocard.olaenergy.re</v-btn>
      </footer>
    </div>
  </v-img>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import store from "@/store";
import router from "@/router";
import { host } from "@/utils/constants";
import { mapGetters } from "vuex";
import qs from "querystring";
import axios from "axios";

export default {
  components: { Widget },
  data() {
    return {
      valid: true,
      enseigne: "",
      nom: "",
      prenom: "",
      adresse1: "",
      adresse2: "",
      codePostal: "",
      pays: "",
      ville: "",
      email: "",
      telephone1: "",
      telephone2: "",
      errorMessage: false,
      errorMessage2: false,
      showPwd: false,
      loadBtn: false,
      successMessage: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["hasAuthentication"])
  },
  created() {
    if (this.hasAuthentication) {
      router.push("/app/home");
    }
  },
  methods: {
    async newSpace() {
      this.errorMessage = false;
      this.errorMessage2 = false;
      console.log('form valide ? ', this.$refs.form.validate());
      if(this.$refs.form.validate()) {
        this.loadBtn = true;

        var d = {
          adresse: {
            adresse1: this.adresse1,
            adresse2: this.adresse2,
            codePostal: this.codePostal,
            pays: this.pays,
            ville: this.ville
          },
          contact: {
            email: this.email,
            telephone1: this.telephone1,
            telephone2: this.telephone2,
          },
          enseigne: this.enseigne,
          nom: this.nom,
          pinCode: '',
          prenom: this.prenom,
          serialNumber: ''
        };

        axios({
          method: "POST",
          url: host + "/clients/pm-plus",
          baseUrl: host,
          headers: {
            "Access-Control-Allow-Origin": "*",
            "content-type": "application/json",
          },
          data: d
        })
            .then(async result => {
              if (result.status === 200) {
                this.loadBtn = false;
                this.successMessage = true;
                setTimeout(() => {
                this.$router.push({ name: 'signIn' });
              }, 3000);
              } else {
                this.loadBtn = false;
                this.errorMessage = true;
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.loadBtn = false;
              if(error.message === 'Request failed with status code 400') {
                this.errorMessage2 = true;
              } else {
                this.errorMessage = true;
              }
            });
      }
    },
    goToBack() {
      router.push('/signIn');
    }
  }
};
</script>

<style src="./NewSpacePM.scss" lang="scss" scoped />
