<template>
  <div>
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white; margin-top: 4%">Accueil</h3>
    <h4
      v-if="role === 'ROLE_MANAGER'"
      style="color:white; margin-top: 4%; margin-left: 5%"
    >
      Bienvenue sur votre espace GERANT
    </h4>
    <h4
      v-if="role === 'ROLE_CLIENT' && !PM"
      style="color:white; margin-top: 4%; margin-left: 5%"
    >
      Bienvenue sur votre espace CLIENT
    </h4>
    <h4
        v-if="role === 'ROLE_CLIENT' && PM"
        style="color:white; margin-top: 4%; margin-left: 5%"
    >
      Bienvenue sur votre espace My O'card
    </h4>
    <h4
      v-if="role === 'ROLE_STATION' || role === 'ROLE_GERANT'"
      style="color:white; margin-top: 4%; margin-left: 5%"
    >
      Bienvenue sur votre espace STATION
    </h4>
    <h4
      v-if="role === 'ROLE_ADMIN'"
      style="color:white; margin-top: 4%; margin-left: 5%"
    >
      Bienvenue sur votre espace ADMIN
    </h4>
    <h4
      v-if="role === 'ROLE_CONTROLEUR'"
      style="color:white; margin-top: 4%; margin-left: 5%"
    >
      Bienvenue sur votre espace OLA CONTROLEUR
    </h4>
    <h4
      v-if="role === 'ROLE_COMMERCIAL'"
      style="color:white; margin-top: 4%; margin-left: 5%"
    >
      Bienvenue sur votre espace OLA COMMERCIAL
    </h4>
    <b-row v-if="role === 'ROLE_MANAGER'">
      <b-col lg="12" sm="6" xs="12">
        <div class="pb-xlg h-100">
          <Widget
            class="mx-auto elevation-0"
            bodyClass="p-0 mt"
            customHeader
            title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Profil
          </h4>"
          >
            <div>
              <v-card
                :class="`d-flex justify-space-around mb-6 align-items-center`"
                flat
                tile
              >
                <v-card class="pa-2 elevation-0" tile>
                  <v-img
                    :src="require('../../assets/avatar.png')"
                    max-width="100"
                    max-height="100"
                  />
                </v-card>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.contact.nom }} {{ entity.contact.prenom }}</span
                  >
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.contact.telephone1 }}
                  </span>
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.contact.email }}</span
                  >
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Début Activité :
                    {{ entity.debutAcivite | formatUnix }}</span
                  >
                </v-card>
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>

      <b-col lg="12" sm="6" xs="12">
        <div class="pb-xlg h-100">
          <Widget
            class="mx-auto elevation-0"
            bodyClass="p-0 mt"
            customHeader
            title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Cet espace vous permet :
          </h4>"
          >
            <div>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-user-tie</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    De consulter les informations de votre compte gérant</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-gas-pump</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    De consulter les informations de votre station</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-tint</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    De consulter la liste des produits disponibles</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-list</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Consulter les transactions réalisées dans limites de
                    dates</span
                  >
                  <br />
                  <span
                    class="subtitle-1 font-weight-regular"
                    style="color:#546E7A"
                  >
                    De les exporter au format CSV</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-file-upload</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Faire une nouvelle demande de carte</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-file-download</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Télécharger vos notes de crédits</span
                  >
                </v-card>
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="role === 'ROLE_STATION' || role === 'ROLE_GERANT'">
      <b-col lg="12" sm="6" xs="12">
        <div class="pb-xlg h-100">
          <Widget
            class="mx-auto elevation-0"
            bodyClass="p-0 mt"
            customHeader
            title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Profil
          </h4>"
          >
            <div>
              <v-card
                :class="`d-flex justify-space-around mb-6 align-items-center`"
                flat
                tile
              >
                <v-card class="pa-2 elevation-0" tile>
                  <v-img
                    :src="require('../../assets/station.png')"
                    max-width="100"
                    max-height="100"
                  />
                </v-card>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.contact.nom }} {{ entity.contact.prenom }}</span
                  >
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.contact.telephone1 }}
                  </span>
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.contact.email }}</span
                  >
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Pompes : {{ entity.nbrPompe }}</span
                  >
                </v-card>
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="role === 'ROLE_CLIENT'">
      <b-col lg="12" sm="6" xs="12" v-if="!PM">
        <div class="pb-xlg h-100">
          <Widget
            class="mx-auto elevation-0"
            bodyClass="p-0 mt"
            customHeader
            title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Profil
          </h4>"
          >
            <div>
              <v-card
                :class="`d-flex justify-space-around mb-6 align-items-center`"
                flat
                tile
              >
                <v-card class="pa-2 elevation-0" tile>
                  <v-img
                    :src="require('../../assets/customer.png')"
                    max-width="100"
                    max-height="100"
                  />
                </v-card>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.entreprise.enseigne }}
                  </span>
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.nom }}
                  </span>
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    {{ entity.entreprise.contratNumero }}</span
                  >
                </v-card>
                <!-- <v-divider
                        class="mx-4"
                        vertical
                ></v-divider>
                <v-card
                        class="pa-2 elevation-0"
                        tile
                >
                  <span class="subtitle-1 font-weight-medium" style="color:#546E7A"> Pompes : {{entity.nbrPompe }}</span>
                </v-card>-->
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>

<!--      <b-col lg="12" sm="6" xs="12" v-if="PM">
        <div class="pb-xlg h-100">
          <Widget
              class="mx-auto elevation-0"
              bodyClass="p-0 mt"
              customHeader
              title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Profil
          </h4>"
          >
            <div>
              <v-card
                  :class="`d-flex justify-space-around mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card class="pa-2 elevation-0" tile>
                  <v-img
                      :src="require('../../assets/customer.png')"
                      max-width="100"
                      max-height="100"
                  />
                </v-card>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    {{ entityPM.libelle }}
                  </span>
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    {{ entityPM.serialNumber }}
                  </span>
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    {{ entityPM.typePayement }}
                  </span>
                </v-card>
                &lt;!&ndash; <v-divider
                        class="mx-4"
                        vertical
                ></v-divider>
                <v-card
                        class="pa-2 elevation-0"
                        tile
                >
                  <span class="subtitle-1 font-weight-medium" style="color:#546E7A"> Pompes : {{entity.nbrPompe }}</span>
                </v-card>&ndash;&gt;
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>-->

      <b-col lg="12" sm="6" xs="12" v-if="PM">
        <div class="pb-xlg h-100">
          <Widget
              class="mx-auto elevation-0"
              bodyClass="p-0 mt"
              customHeader
              title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Mes Informations
          </h4>"
          >
            <div>
              <v-card
                  :class="`d-flex justify-space-around mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card class="pa-2 elevation-0" tile>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    {{ entity.nom }} {{ entity.prenom }}
                  </span>
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                      v-if="entity.emailFacturation"
                  >
                  email :  {{ entity.emailFacturation }}
                  </span>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                      v-if="!entity.emailFacturation"
                  >
                  email :  -
                  </span>
                </v-card>
                <v-divider class="mx-4" vertical></v-divider>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                   adresse : {{ entity.adresse.adresse1 }} - {{ entity.adresse.codePostal }}
                  </span>
                </v-card>
                <!-- <v-divider
                        class="mx-4"
                        vertical
                ></v-divider>
                <v-card
                        class="pa-2 elevation-0"
                        tile
                >
                  <span class="subtitle-1 font-weight-medium" style="color:#546E7A"> Pompes : {{entity.nbrPompe }}</span>
                </v-card>-->
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>

      <b-col lg="12" sm="6" xs="12" v-if="!PM">
        <div class="pb-xlg h-100">
          <Widget
            class="mx-auto elevation-0"
            bodyClass="p-0 mt"
            customHeader
            title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Cet espace vous permet :
          </h4>"
          >
            <div>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-users</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    De consulter les informations de votre compte client :</span
                  >
                  <br />
                  <span
                    class="subtitle-1 font-weight-regular"
                    style="color:#546E7A"
                  >
                    Adresse, N° de contrat, date de création, contact et code
                    pin général de l’entreprise.</span
                  >
                  <br />
                  <span
                    class="subtitle-1 font-weight-regular"
                    style="color:#546E7A"
                  >
                    Le code pin de l’entreprise est un code unique pour
                    l’ensemble des cartes.</span
                  >
                  <br />
                  <span
                    class="subtitle-1 font-weight-regular"
                    style="color:#546E7A"
                  >
                    Vous avez la possibilité de modifier individuellement le
                    code des cartes.</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-id-card</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Gérer votre parc de cartes :</span
                  >
                  <br />
                  <span
                    class="subtitle-1 font-weight-regular"
                    style="color:#546E7A"
                  >
                    Changer le statut de la carte (active ou bloquée)</span
                  >
                  <br />
                  <span
                    class="subtitle-1 font-weight-regular"
                    style="color:#546E7A"
                  >
                    Changer le code pin</span
                  >
                  <br />
                  <span
                    class="subtitle-1 font-weight-regular"
                    style="color:#546E7A"
                  >
                    Changer les droits d'utilisation</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-list</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Consulter les transactions de l'ensemble de vos cartes</span
                  >
                  <br />
                  <span
                    class="subtitle-1 font-weight-regular"
                    style="color:#546E7A"
                  >
                    De les exporter au format CSV</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-file-upload</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Faire une demande de nouvelle carte</span
                  >
                </v-card>
              </v-card>
              <v-card
                :class="`d-flex justify-space-between mb-6 align-items-center`"
                flat
                tile
              >
                <v-card
                  class="pa-2 elevation-0 justify"
                  tile
                  style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-file-download</v-icon>
                </v-card>
                <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
                >
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                    Télécharger vos factures</span
                  >
                </v-card>
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>

      <b-col lg="12" sm="6" xs="12" v-if="PM">
        <div class="pb-xlg h-100">
          <Widget
              class="mx-auto elevation-0"
              bodyClass="p-0 mt"
              customHeader
              title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Cet espace vous permet :
          </h4>"
          >
            <div>
<!--              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 30%;margin-left: 5%"
                >
                  <v-icon>fas fa-users</v-icon>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 70%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    De consulter les informations de votre compte client :</span
                  >
                  <br />
                  <span
                      class="subtitle-1 font-weight-regular"
                      style="color:#546E7A"
                  >
                    Adresse, N° de contrat, date de création, contact et code
                    pin général de l’entreprise.</span
                  >
                  <br />
                  <span
                      class="subtitle-1 font-weight-regular"
                      style="color:#546E7A"
                  >
                    Le code pin de l’entreprise est un code unique pour
                    l’ensemble des cartes.</span
                  >
                  <br />
                  <span
                      class="subtitle-1 font-weight-regular"
                      style="color:#546E7A"
                  >
                    Vous avez la possibilité de modifier individuellement le
                    code des cartes.</span
                  >
                </v-card>
              </v-card>-->
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-id-card</v-icon>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Gérer votre carte :</span
                  >
                  <br />
                  <span
                      class="subtitle-1 font-weight-regular"
                      style="color:#546E7A"
                  >
                    Changer le statut de la carte (active ou bloquée)</span
                  >
                  <br />
                  <span
                      class="subtitle-1 font-weight-regular"
                      style="color:#546E7A"
                  >
                    Changer le code pin</span
                  >
                  <br />
                  <span
                      class="subtitle-1 font-weight-regular"
                      style="color:#546E7A"
                  >
                    Changer les droits d'utilisation</span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-list</v-icon>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Consulter les transactions de votre carte</span
                  >
                  <br />
                  <span
                      class="subtitle-1 font-weight-regular"
                      style="color:#546E7A"
                  >
                    De les exporter au format CSV</span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-euro</v-icon>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Recharger votre carte</span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
                  <v-icon>fas fa-file</v-icon>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Consulter l'historique des recharges</span
                  >
                </v-card>
              </v-card>
<!--              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 30%;margin-left: 5%"
                >
                  <v-icon>fas fa-file-upload</v-icon>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 70%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Faire une demande de nouvelle carte</span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 30%;margin-left: 5%"
                >
                  <v-icon>fas fa-file-download</v-icon>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 70%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Télécharger vos factures</span
                  >
                </v-card>
              </v-card>-->
            </div>
          </Widget>
        </div>
      </b-col>

      <b-col lg="12" sm="6" xs="12" v-if="PM">
        <div class="pb-xlg h-100">
          <Widget
              class="mx-auto elevation-0"
              bodyClass="p-0 mt"
              customHeader
              title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Comment recharger la carte :
          </h4>"
          >
            <div>
              <!--              <v-card
                                :class="`d-flex justify-space-between mb-6 align-items-center`"
                                flat
                                tile
                            >
                              <v-card
                                  class="pa-2 elevation-0 justify"
                                  tile
                                  style="width: 30%;margin-left: 5%"
                              >
                                <v-icon>fas fa-users</v-icon>
                              </v-card>
                              <v-card
                                  class="pa-1 elevation-0 justify"
                                  tile
                                  style="width: 70%;"
                              >
                                <span
                                    class="subtitle-1 font-weight-medium"
                                    style="color:#546E7A"
                                >
                                  De consulter les informations de votre compte client :</span
                                >
                                <br />
                                <span
                                    class="subtitle-1 font-weight-regular"
                                    style="color:#546E7A"
                                >
                                  Adresse, N° de contrat, date de création, contact et code
                                  pin général de l’entreprise.</span
                                >
                                <br />
                                <span
                                    class="subtitle-1 font-weight-regular"
                                    style="color:#546E7A"
                                >
                                  Le code pin de l’entreprise est un code unique pour
                                  l’ensemble des cartes.</span
                                >
                                <br />
                                <span
                                    class="subtitle-1 font-weight-regular"
                                    style="color:#546E7A"
                                >
                                  Vous avez la possibilité de modifier individuellement le
                                  code des cartes.</span
                                >
                              </v-card>
                            </v-card>-->
              <v-card
                  class="pa-1 elevation-0 justify"
                  tile
                  style="width: 90%;"
              >
                  <span
                      class="subtitle-1 font-weight-regular"
                      style="color:#546E7A"
                  >
                    Suivre les instructions affichées à l'écran.</span
                  >
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
               <span
                   class="subtitle-1 font-weight-medium"
                   style="color:#546E7A"
               >   1- </span>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Cliquer sur <span style="color:#B22222">Recharge</span></span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
               <span
                   class="subtitle-1 font-weight-medium"
                   style="color:#546E7A"
               >   2- </span>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Saisir le montant de la recharge</span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
               <span
                   class="subtitle-1 font-weight-medium"
                   style="color:#546E7A"
               >   3- </span>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                   Remplir le formulaire de paiement (numéro carte bancaire, date. CVV)</span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
               <span
                   class="subtitle-1 font-weight-medium"
                   style="color:#546E7A"
               >   4- </span>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                    Valider le règlement</span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
               <span
                   class="subtitle-1 font-weight-medium"
                   style="color:#546E7A"
               >   5- </span>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                   Si le paiement est accepté le solde de la carte est immédiatement actualisé</span
                  >
                </v-card>
              </v-card>
              <v-card
                  :class="`d-flex justify-space-between mb-6 align-items-center`"
                  flat
                  tile
              >
                <v-card
                    class="pa-2 elevation-0 justify"
                    tile
                    style="width: 10%;margin-left: 5%"
                >
               <span
                   class="subtitle-1 font-weight-medium"
                   style="color:#546E7A"
               >   6- </span>
                </v-card>
                <v-card
                    class="pa-1 elevation-0 justify"
                    tile
                    style="width: 90%;"
                >
                  <span
                      class="subtitle-1 font-weight-medium"
                      style="color:#546E7A"
                  >
                   L'opération de Recharge apparait dans Transaction et dans Historique de recharge</span
                  >
                </v-card>
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="role === 'ROLE_ADMIN'">
      <b-col lg="12" sm="6" xs="12">
        <div class="pb-xlg h-100">
          <Widget
            class="mx-auto elevation-0"
            bodyClass="p-0 mt"
            customHeader
            title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Profil
          </h4>"
          >
            <div>
              <v-card
                :class="`d-flex justify-space-around mb-6 align-items-center`"
                flat
                tile
              >
                <v-card class="pa-2 elevation-0" tile>
                  <v-img
                    :src="require('../../assets/admin.png')"
                    max-width="100"
                    max-height="100"
                  />
                </v-card>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                    >ADMIN</span
                  >
                </v-card>
                <!--  <v-divider
                        class="mx-4"
                        vertical
                ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                  </span>
                </v-card>
                <!--  <v-divider
                       class="mx-4"
                       vertical
               ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  ></span>
                </v-card>
                <!--  <v-divider
                         class="mx-4"
                         vertical
                 ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  ></span>
                </v-card>
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="role === 'ROLE_CONTROLEUR'">
      <b-col lg="12" sm="6" xs="12">
        <div class="pb-xlg h-100">
          <Widget
            class="mx-auto elevation-0"
            bodyClass="p-0 mt"
            customHeader
            title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Profil
          </h4>"
          >
            <div>
              <v-card
                :class="`d-flex justify-space-around mb-6 align-items-center`"
                flat
                tile
              >
                <v-card class="pa-2 elevation-0" tile>
                  <v-img
                    :src="require('../../assets/admin.png')"
                    max-width="100"
                    max-height="100"
                  />
                </v-card>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                    >OLA CONTROLEUR</span
                  >
                </v-card>
                <!--  <v-divider
                          class="mx-4"
                          vertical
                  ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                  </span>
                </v-card>
                <!--  <v-divider
                       class="mx-4"
                       vertical
               ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  ></span>
                </v-card>
                <!--  <v-divider
                         class="mx-4"
                         vertical
                 ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  ></span>
                </v-card>
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="role === 'ROLE_COMMERCIAL'">
      <b-col lg="12" sm="6" xs="12">
        <div class="pb-xlg h-100">
          <Widget
            class="mx-auto elevation-0"
            bodyClass="p-0 mt"
            customHeader
            title="<h5 class='d-flex align-items-center pb-1'>
            <span class='circle bg-warning mr-sm' style='font-size: 6px;'></span>
            Profil
          </h4>"
          >
            <div>
              <v-card
                :class="`d-flex justify-space-around mb-6 align-items-center`"
                flat
                tile
              >
                <v-card class="pa-2 elevation-0" tile>
                  <v-img
                    :src="require('../../assets/admin.png')"
                    max-width="100"
                    max-height="100"
                  />
                </v-card>
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                    >OLA COMMERCIAL</span
                  >
                </v-card>
                <!--  <v-divider
                          class="mx-4"
                          vertical
                  ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  >
                  </span>
                </v-card>
                <!--  <v-divider
                       class="mx-4"
                       vertical
               ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  ></span>
                </v-card>
                <!--  <v-divider
                         class="mx-4"
                         vertical
                 ></v-divider> -->
                <v-card class="pa-2 elevation-0" tile>
                  <span
                    class="subtitle-1 font-weight-medium"
                    style="color:#546E7A"
                  ></span>
                </v-card>
              </v-card>
            </div>
          </Widget>
        </div>
      </b-col>
    </b-row>

    <v-dialog v-model="dialogEdit" max-width="700px" persistent>
      <v-card>
        <v-card-title class="headline">Information Obligatoire</v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="validForm">
              <v-row justify="center">
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="editedItem.nom"
                    label="Nom Contact *"
                    :rules="[v => !!v || '* Champs Requis']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="editedItem.prenom"
                    label="Prenom Contact *"
                    :rules="[v => !!v || '* Champs Requis']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editedItem.adresse.adresse1"
                    label="Adresse Livraison *"
                    :rules="[v => !!v || '* Champs Requis']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="editedItem.adresse.codePostal"
                    label="Code Postal *"
                    :rules="[v => !!v || '* Champs Requis']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="editedItem.adresse.ville"
                    label="Ville *"
                    :rules="[v => !!v || '* Champs Requis']"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="editedItem.adresse.pays"
                    label="Pays *"
                    :rules="[v => !!v || '* Champs Requis']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="editedItem.contact.telephone1"
                    label="Téléphone *"
                    :rules="[v => !!v || '* Champs Requis']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col cols="12" md="10">
                  <v-text-field
                    v-model="editedItem.contact.email"
                    label="Adresse Email *"
                    :rules="[v => !!v || '* Champs Requis']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn
            color="#03498E"
            text
            @click="dialogEdit = false"
            :loading="loadBtn"
            :disabled="loadBtn"
            >Annuler</v-btn
          >
          <v-btn
            color="#03498E"
            text
            @click="editingItem"
            :loading="loadBtn"
            :disabled="loadBtn || !validForm"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import { mapGetters } from "vuex";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";

export default {
  name: "Dashboard",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      entity: {},
      validForm: true,
      editedItem: {
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: "",
          pays: "",
          ville: ""
        },
        contact: {
          email: "",
          telephone1: "",
          telephone2: ""
        },
        nom: "",
        prenom: ""
      },
      sb: { status: false, text: "", color: "", y: "top" },
      dialogEdit: false,
      loadBtn: false,
      PM: false,
      entityPM: {},
    };
  },
  methods: {
    initialize() {
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      if (
        this.role === "ROLE_STATION" ||
        this.role === "ROLE_GERANT" ||
        this.role === "ROLE_MANAGER"
      ) {
        axios
          .get(

              host +
              "/stations/" +
              this.entity.id,
            auth
          )
          .then(result => {
            this.entity = result.data;
          });
      }

      if (this.role === "ROLE_CLIENT") {
        axios
          .get(

              host +
              "/clients/" +
              this.entity.id,
            auth
          )
          .then(result => {
            this.entity = result.data;
            if (result.data.account.username.startsWith('PM') || result.data.account.username.startsWith('MO')) {
              this.PM = true;
              this._getCarte(result.data);
            } else {
              this._checkInfoClient();
            }
          });
      }
    },
    _checkInfoClient() {
      this.editedItem = Object.assign({}, this.entity);
      if (
        this._checkAttribute("account", "username") ||
        this._checkAttribute("nom") ||
        this._checkAttribute("prenom") ||
        this._checkAttribute("contact", "telephone1") ||
        this._checkAttribute("contact", "email") ||
        this._checkAttribute("adresse", "adresse1") ||
        this._checkAttribute("adresse", "codePostal") ||
        this._checkAttribute("adresse", "ville") ||
        this._checkAttribute("adresse", "pays")
      ) {
        this.dialogEdit = true;
      }
    },
    _checkAttribute(attribute, attribute2) {
      attribute2 =
        typeof attribute2 === "undefined" ? "defaultValue" : attribute2;
      if (attribute2 === "defaultValue") {
        if (
          this.editedItem[attribute] === null ||
          this.editedItem[attribute] === 0
        ) {
          this.editedItem[attribute] = "";
          return true;
        } else {
          return false;
        }
      } else {
        if (
          this.editedItem[attribute][attribute2] === null ||
          this.editedItem[attribute][attribute2] === 0
        ) {
          this.editedItem[attribute][attribute2] = "";
          return true;
        }
      }
    },
    _getCarte(item) {
     let sn = item.account.username.substring(2);

      axios({
        method: "GET",
        url: host + "/cartes/sn/" + sn,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
          Accept: "application/json"
        },
      })
          .then(result => {
            if (result.status === 200) {
              this.entityPM = result.data;
            } else {
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
    editingItem() {
      this.loadBtn = true;

      axios({
        method: "POST",
        url:

          host +
          "/clients/update/" +
          this.editedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        data: JSON.stringify(this.editedItem)
      })
        .then(result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Fiche Client mis a jour ! ";
            this.sb.color = "success";
            this.initialize();
            this.loadBtn = false;
            this.dialogEdit = false;
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.loadBtn = false;
            this.dialogEdit = false;
          }
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log(error);
          this.loadBtn = false;
          this.dialogEdit = false;
        });
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ])
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.initialize();
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
          .locale("fr")
          .format("L");

        return date;
      }
    }
  }
};
</script>

<style src="./Dashboard.scss" lang="scss" scoped />
