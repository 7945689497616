<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Transactions</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="text-right">
        <v-btn
          color="success"
          class="ma-2 white--text"
          @click="sortTransactions()"
          :loading="loadCSV"
          :disabled="loadCSV || transactions.length === 0"
        >
          Télécharger CSV <v-icon right dark>mdi-cloud-download</v-icon>
        </v-btn>
      </div>
      <div class="text-right" v-if="!loadData">
        <v-row justify="center">
          <v-col cols="7" md="3">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formatDateContrat"
                  label="Date Debut"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false"
                  >Annuler</v-btn
                >
                <v-btn text color="primary" @click="$refs.menu.save(date)"
                  >Valider</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="7" md="3">
            <v-menu
              ref="menuDF"
              v-model="menuDF"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="formatDateFin"
                  label="Date Fin"
                  readonly
                  v-on="on"
                  clearable
                ></v-text-field>
              </template>
              <v-date-picker v-model="dateFin" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menuDF = false"
                  >Annuler</v-btn
                >
                <v-btn text color="primary" @click="$refs.menuDF.save(dateFin)"
                  >Valider</v-btn
                >
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="7" md="1">
            <v-btn
              class="ma-2"
              tile
              large
              color="teal"
              icon
              @click="searchTrans()"
            >
              <v-icon right dark>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="table-responsive">
        <v-skeleton-loader :loading="loadData" type="table">
          <v-data-table
            :headers="headers"
            :items="transactions"
            :search="search"
            :loading="loadDataIn"
            class="elevation-1"
          >
            <template v-slot:item.createdDate="{ item }">
              {{ item.createdDate | formatUnix }}
            </template>
            <template v-slot:item.montant="{ item }">
              <span>{{ formatFloat(item.montant) }} €</span>
            </template>
            <template v-slot:item.produits="{ item }">
              <span>{item}</span>
            </template>

            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-row justify="center" class="mt-6">
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="search"
                      label="Recherche par N° Client ou n° Ticket"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="5">
                    <v-select
                      :items="TypeCartes"
                      v-model="cartesFilterValue"
                      label="Type de Cartes"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-dialog v-model="dialogInfo" max-width="700px" persistent>
                  <v-card>
                    <v-card-title class="headline">Information</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center" v-if="editedItem.carte.delete">
                          <v-badge
                                  bordered
                                  color="error"
                                  icon="mdi-lock"
                                  overlap
                          >
                            <v-btn
                                    class="white--text"
                                    color="error"
                                    depressed
                            >
                              Carte Supprimée
                            </v-btn>
                          </v-badge>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="editedItem.ticket.seq"
                              label="N° Ticket"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="editedItem.typeTransaction"
                              label="Type Transaction"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-if="
                                editedItem.carte.typePayement ===
                                  'CREDIT_CLIENT_CLASSIQUE'
                              "
                              value="PRO"
                              label="Type Carte"
                              readonly
                            ></v-text-field>
                            <v-text-field
                                    v-if="
                                editedItem.carte.typePayement ===
                                  'PRO'
                              "
                                    value="PRO"
                                    label="Type Carte"
                                    readonly
                            ></v-text-field>
                            <v-text-field
                              v-if="editedItem.carte.typePayement === 'PME'"
                              value="Porte Monnaie"
                              label="Type Carte"
                              readonly
                            ></v-text-field>
                            <v-text-field
                              v-if="
                                editedItem.carte.typePayement ===
                                  'CARTE_PRE_PAYEE'
                              "
                              value="PRE PAYEE"
                              label="Type Carte"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="editedItem.station.nom"
                              label="Station"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="editedItem.carte.serialNumber"
                              label="Numéro de carte"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="
                                editedItem.carte.client.entreprise.enseigne
                              "
                              label="Entreprise"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="formatDate"
                              label="Date"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6">
                            <v-text-field
                                    v-model="
                                editedItem.kilometrage
                              "
                                    label="Kilometrage"
                                    readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center" v-if="editedItem.produit">
                          <v-col>
                            <v-text-field
                              v-model="editedItem.produit.libelle"
                              label="Produit"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col
                            v-if="editedItem.produit.categorie === 'CARBURANT'"
                          >
                            <v-text-field
                              v-model="quantite"
                              label="Quantité"
                              suffix="L"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="editedItem.montant"
                              label="Montant"
                              suffix="€"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="#03498E" text @click="dialogInfo = false"
                        >Fermer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" v-on="on" @click="showDetails(item)"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span>Plus d'informations</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import Papa from "papaparse";
import moment from "moment";
import axios from "axios";
import { host } from "@/utils/constants";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";

global.EventSource = NativeEventSource || EventSourcePolyfill;
let evtSource = false;

export default {
  name: "Transactions",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      loadCSV: false,
      loadData: true,
      loadDataIn: true,
      loadDownload: false,
      loadBtn: false,
      dialogInfo: false,
      sb: { status: false, text: "", color: "", y: "top" },
      TypeCartes: [
        { text: "TOUT", value: null },
        { text: "PRO", value: "PRO" },
        { text: "PORTE MONNAIE", value: "PORTE_MONNAIE" },
        { text: "PRE PAYEE", value: "PRE_PAYEE" }
      ],
      cartesFilterValue: null,
      search: "",
      quantite: 1,
      headers: [
        {
          text: "Date / Heure",
          align: "left",
          value: "createdDate",
          filterable: false
        },
        { text: "N° Ticket", value: "ticket.seq" },
        { text: "Type", value: "typeTransaction", filterable: false },
        {
          text: "Enseigne",
          value: "carte.client.entreprise.enseigne",
          filterable: false
        },
        { text: "N° Client", value: "carte.client.entreprise.contratNumero" },
        { text: "Carte", value: "carte.serialNumber" },
        { text: "Nom Carte", value: "carte.libelle" },
        {
          text: "Type Carte",
          value: "carte.typePayement",
          filter: this.cartesFilter
        },
        { text: "Station", value: "station.nom", filterable: false },
        { text: "Montant", value: "montant", filterable: false },
        { text: "Actions", value: "action", sortable: false }
      ],
      transactions: [],
      editedItem: {
        carte: {
          client: { entreprise: { enseigne: "" } },
          serialNumber: "",
          uuid: ""
        },
        createdDate: "",
        id: "",
        seq: "",
        montant: "",
        prix_unitaire: "",
        produit: { code: "" },
        station: { adresse: "", contact: "", nom: "" },
        ticket: {},
        typeTransaction: ""
      },
      menu: false,
      menuDF: false,
      date: "",
      dateFin: "",
      transactionsCSV: []
    };
  },
  methods: {
    initialize() {
      /*  this.loadCSV = true;
      this.loadDataIn = true;
      this.loadData = true;*/
    },
    showDetails(item) {
      this.editedItem = Object.assign({}, item);
      this.editedItem.montant = this.formatFloat(item.montant);
      if (this.editedItem.produit !== null) {
        if (this.editedItem.produit.categorie === "CARBURANT") {
          let puht = this.editedItem.prix_unitaire;
          let quantite = parseFloat(this.editedItem.montant) / parseFloat(puht);
          this.quantite = this.formatFloat(quantite);
        }
      }
      this.dialogInfo = true;
    },
    sortTransactions() {
      this.loadCSV = true;
      this.transactionsCSV = [];
      let item = {};
      this.transactions.forEach(element => {
        item = {
          date: "",
          ticket: element.ticket.seq,
          type: element.typeTransaction,
          enseigne: "",
          client: "",
          carte: "",
          nom_carte: "",
          type_carte: "",
          libelle_carte: "",
          station: element.station.nom,
          montant: "",
          quantite: "",
          km: "",
          code_chauffeur: "",
          prix_unitaire: "",
          produit_code: "",
          produit_libelle: "",
          carte_supprime: ""
        };

        item.date =
          moment(element.createdDate)
            .locale("fr")
            .format("L") +
          " " +
          moment(element.createdDate)
            .locale("fr")
            .format("LT");

        item.montant = this.formatFloat(element.montant);

        if(element.code_chauffeur !== null) {
          item.code_chauffeur = element.code_chauffeur;
        }
        if(element.kilometrage !== null) {
          item.km = element.kilometrage;
        }
        if (element.carte !== null) {
          item.carte = element.carte.serialNumber;
          item.nom_carte = element.carte.libelle;
          item.type_carte = element.carte.typePayement;
          item.enseigne = element.carte.client.entreprise.enseigne;
          item.client = element.carte.client.entreprise.contratNumero;
          item.libelle_carte = element.carte.libelle;
          if(element.carte.delete){
            item.carte_supprime = "supprimée"
          }
        }
        if (element.produit !== null) {
          item.produit_code = element.produit.code;
          item.produit_libelle = element.produit.libelle;
          item.prix_unitaire = element.prix_unitaire.toString().replace(".", ",");
          if (element.produit.categorie === "CARBURANT") {
            let puht = element.prix_unitaire;
            let quantite = parseFloat(element.montant) / parseFloat(puht);
            item.quantite = this.formatFloat(quantite);
          }
        }
        this.transactionsCSV.push(item);
      });
      let csv = Papa.unparse(
        {
          fields: [
            "date",
            "ticket",
            "type",
            "enseigne",
            "client",
            "carte",
            "type_carte",
            "libelle_carte",
            "station",
            "montant",
            "quantite",
            "km",
            "code_chauffeur",
            "prix_unitaire",
            "produit_code",
            "produit_libelle",
            "carte_supprime"
          ],
          data: this.transactionsCSV
        },
        {
          delimiter: ";",
          header: true
        }
      );

      var blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      var link = document.createElement("a");
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "transactions.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link);
      this.loadCSV = false;
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    cartesFilter(value) {
      if (!this.cartesFilterValue) {
        return true;
      }
      return value === this.cartesFilterValue;
    },
    searchTrans() {
      evtSource.close();

      this.loadDataIn = true;
      this.loadData = true;

      let dateDebut = moment(this.date)
        .locale("fr")
        .format("DD-MM-YYYY");
      let dateFin = moment(this.dateFin)
        .locale("fr")
        .format("DD-MM-YYYY");

     let days =  moment(this.dateFin).startOf('day').diff( moment(this.date).startOf('day'), 'days', true);
     let url = "";

      if(this.role === "ROLE_ADMIN" || this.role === "ROLE_CONTROLEUR" || this.role === "ROLE_COMMERCIAL"){
        if(days > 7) {
          this.sb.status = true;
          this.sb.text = "Période de recherche limitée à 7 jours. Veuillez recommencer.";
          this.sb.color = "warning";
          this.loadDataIn = false;
          this.loadData = false;
        }
        else {
          url =
                  host +
                  "/transactions/all/date?start=" +
                  dateDebut +
                  "&end=" +
                  dateFin;
        }
      } else if(this.role === "ROLE_MANAGER" || this.role === "ROLE_STATION" || this.role === "ROLE_GERANT"){
        if(days > 31) {
          this.sb.status = true;
          this.sb.text = "Période de recherche limitée à 30 jours. Veuillez recommencer.";
          this.sb.color = "warning";
          this.loadDataIn = false;
          this.loadData = false;
        }
        else {
          url =
                  host +
                  "/transactions/all/date/station/" +
                  this.entity.id +
                  "?start=" +
                  dateDebut +
                  "&end=" +
                  dateFin;
        }
      } else if (this.role === "ROLE_CLIENT") {
        if (this.entity.entreprise.contratNumero === "DK01613" || this.entity.entreprise.contratNumero === "DR01648") {
          if(days > 15) {
            this.sb.status = true;
            this.sb.text = "Période de recherche limitée à 15 jours. Veuillez recommencer.";
            this.sb.color = "warning";
            this.loadDataIn = false;
            this.loadData = false;
          }
          else {
            url =
                    host +
                    "/transactions/all/date/client/" +
                    this.entity.id +
                    "?start=" +
                    dateDebut +
                    "&end=" +
                    dateFin;
          }
        }
        if(days > 92) {
          this.sb.status = true;
          this.sb.text = "Période de recherche limitée à 90 jours. Veuillez recommencer.";
          this.sb.color = "warning";
          this.loadDataIn = false;
          this.loadData = false;
        }
        else {
          url =
                   host +
                  "/transactions/all/date/client/" +
                  this.entity.id +
                  "?start=" +
                  dateDebut +
                  "&end=" +
                  dateFin;
        }
      }

      if(url !== "") {
        this.transactions = [];
        evtSource = new EventSourcePolyfill(url, {
          headers: {
            Authorization: "Bearer" + this.token
          }
        });

        var that = this;

        evtSource.addEventListener("message", function(e) {
          let transaction = JSON.parse(e.data);
          that.loadData = false;
          that.loadDataIn = false;
          that.transactions.push(transaction);
        });

        evtSource.addEventListener(
                "error",
                function(event) {
                  switch (event.target.readyState) {
                    case EventSource.CONNECTING:
                      evtSource.close();
                      console.log("Reconnecting...");
                      that.loadData = false;
                      that.loadDataIn = false;
                      break;

                    case EventSource.CLOSED:
                      console.log("Connection failed, will not reconnect");
                      that.loadData = false;
                      that.loadDataIn = false;
                      break;
                  }
                },
                false
        );

        evtSource.addEventListener(
                "close",
                // eslint-disable-next-line no-unused-vars
                function(e) {
                  evtSource.close();
                },
                false
        );
      }
    },
    _loadData(url) {
      evtSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: "Bearer" + this.token
        }
      });

      var that = this;

      evtSource.addEventListener("message", function(e) {
        let transaction = JSON.parse(e.data);
        that.loadData = false;
        that.loadDataIn = false;
        that.transactions.push(transaction);
      });

      evtSource.addEventListener(
          "error",
          function(event) {
            switch (event.target.readyState) {
              case EventSource.CONNECTING:
                evtSource.close();
                console.log("Reconnecting...");
                that.loadData = false;
                that.loadDataIn = false;
                break;

              case EventSource.CLOSED:
                console.log("Connection failed, will not reconnect");
                that.loadData = false;
                that.loadDataIn = false;
                break;
            }
          },
          false
      );

      evtSource.addEventListener(
          "close",
          // eslint-disable-next-line no-unused-vars
          function(e) {
            evtSource.close();
          },
          false
      );
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formatDateContrat() {
      return this.date
        ? moment(this.date)
            .locale("fr")
            .format("L")
        : "";
    },
    formatDateFin() {
      return this.dateFin
        ? moment(this.dateFin)
            .locale("fr")
            .format("L")
        : "";
    },
    formatDate() {
      let date = moment(this.editedItem.createdDate)
        .locale("fr")
        .format("L");

      let heure = moment(this.editedItem.createdDate)
        .locale("fr")
        .format("LT");

      return date + " " + heure;
    },
    formatDateD() {
      this.dateDFilter();
      return this.dateD
        ? moment(this.dateD)
            .locale("fr")
            .format("L")
        : "";
    },
  },
  created() {
    //this.initialize();
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
          .locale("fr")
          .format("L");

        let heure = moment(value)
          .locale("fr")
          .format("LT");

        return date + " " + heure;
      }
    }
  },
  mounted() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    const auth = {
      headers: {
        Authorization: "Bearer " + this.token,
        "X-Requested-Width": "XMLHttpRequest"
      }
    };
    //this.initialize();

    let today = moment()
      .locale("fr")
      .add(1, "days")
      .format("DD-MM-YYYY");
    let beforedays = moment()
      .locale("fr")
      .subtract(3, "days")
      .format("DD-MM-YYYY");

    let url = "";
    if (
      this.role === "ROLE_ADMIN" ||
      this.role === "ROLE_CONTROLEUR" ||
      this.role === "ROLE_COMMERCIAL"
    ) {
      url = host + "/transactions/all/date?start=" + beforedays + "&end=" + today;
      this._loadData(url);
    } else if (this.role === "ROLE_CLIENT") {
      axios
          .get(
              host +
              "/clients/" +
              this.entity.id,
              auth
          )
          .then(result => {
            this.entity = result.data;
            if (result.data.account.username.startsWith('PM') || result.data.account.username.startsWith('MO')) {
              let sn = result.data.account.username.substring(2);
              this.PM = true;

              axios
                  .get(host + "/cartes/sn/" + sn, auth)
                  .then(result => {
                    url =
                        host +
                        "/transactions/carte/" +
                        result.data.id +
                        "?start=" +
                        beforedays +
                        "&end=" +
                        today;
                    this._loadData(url);
                  })
            } else {
              url =
                  host +
                  "/transactions/all/date/client/" +
                  this.entity.id +
                  "?start=" +
                  beforedays +
                  "&end=" +
                  today;
              this._loadData(url);
            }
          });
    } else if (
      this.role === "ROLE_MANAGER" ||
      this.role === "ROLE_STATION" ||
      this.role === "ROLE_GERANT"
    ) {
      url =
        host +
        "/transactions/all/date/station/" +
        this.entity.id +
        "?start=" +
        beforedays +
        "&end=" +
        today;
      this._loadData(url);
    }
  },
  beforeDestroy() {
    evtSource.close();
  }
};
</script>
