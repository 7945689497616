<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white; margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Demandes My O'card</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-skeleton-loader type="table">
          <v-data-table
              :headers="headers"
              :items="demandes"
              sort-by="nom"
              class="elevation-1"
          >
            <template v-slot:item.immatriculation="{ item }">
              <span v-if="item.hors_parc">Hors Parc</span>
              <span v-else>{{ item.immatriculation }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div class="flex-grow-1"></div>
                <v-dialog v-model="dialog" max-width="850px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn class="ma-2" outlined color="#03498E" v-on="on" :disabled="demandes.length >= 3"
                    >Nouvelle demande My O'card</v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline"
                    >Demande My O'card</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form ref="formStep1" v-model="validStep1">
                          <v-row justify="center">
                            <v-col cols="12" md="10">
                              <v-autocomplete
                                  v-if="
                                  role === 'ROLE_ADMIN' ||
                                    role === 'ROLE_COMMERCIAL'
                                "
                                  v-model="client"
                                  :items="clients"
                                  item-text="entreprise.contratNumero"
                                  label="N° Client *"
                                  persistent-hint
                                  prepend-icon="mdi-account-box-outline"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                  return-object
                              >
                              </v-autocomplete>
                              <v-text-field
                                  v-if="role === 'ROLE_CLIENT'"
                                  label="N° Client"
                                  :placeholder="entity.entreprise.contratNumero"
                                  readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" sm="5">
                              <v-select
                                  v-if="
                                  role === 'ROLE_ADMIN' ||
                                    role === 'ROLE_COMMERCIAL'
                                "
                                  v-model="defaultItem.typePayement"
                                  :items="typePayements"
                                  item-text="libelle"
                                  return-object
                                  label="Type Paiement *"
                                  v-on:change="changeRoute"
                                  outlined
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                              >
                              </v-select>
                              <v-text-field
                                  v-if="role === 'ROLE_CLIENT'"
                                  v-model="defaultItem.typePayement.name"
                                  label="Type Paiement"
                                  readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-text-field
                                  v-model="defaultItem.libelle"
                                  label="Libellé Carte *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                md="5"
                                v-if="
                                role === 'ROLE_ADMIN' ||
                                  role === 'ROLE_COMMERCIAL'
                              "
                            >
                              <v-select
                                  v-model="defaultItem.station_destinataire"
                                  :items="stations"
                                  item-text="nom"
                                  label="Station Destinaire"
                                  hint="Sélectionner une station"
                                  outlined
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-checkbox
                                  v-model="defaultItem.hors_parc"
                                  :label="`Hors Parc`"
                                  v-on:change="changeHorsParc"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                  v-model="defaultItem.immatriculation"
                                  label="Immatriculation"
                                  :disabled="defaultItem.hors_parc"
                                  :counter="10"
                                  :required="defaultItem.hors_parc"
                                  :rules="defaultItem.hors_parc ? [] : [v => !!v || '* Champs Requis']"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-checkbox
                                  label="KM"
                                  v-model="defaultItem.km"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-checkbox
                                  label="Code chauffeur"
                                  v-model="defaultItem.code_chauffeur"
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" sm="10">
                              <v-select
                                  v-model="defaultItem.produits"
                                  :items="products"
                                  item-text="libelle"
                                  label="Produits *"
                                  return-object
                                  multiple
                                  outlined
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                              >
                                <template v-slot:prepend-item>
                                  <v-list-item ripple @click="toggle">
                                    <v-list-item-action>
                                      <v-icon
                                          :color="
                                          selectedProduits.length > 0
                                            ? 'indigo darken-4'
                                            : ''
                                        "
                                      >{{ icon }}</v-icon
                                      >
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title
                                      >Tout sélectionner</v-list-item-title
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                              </v-select>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="3">
                              <h4>Plage horaire d'utilisation</h4>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" sm="3">
                              <v-checkbox
                                  v-model="restrictionjour1.active"
                                  label="Lundi - Vendredi"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <v-menu
                                  :close-on-content-click="false"
                                  :disabled="!restrictionjour1.active"
                                  min-width="290px"
                                  offset-y
                                  ref="mEditHours1Start"
                                  transition="scale-transition"
                                  v-model="mEditHours1Start"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                      :disabled="!restrictionjour1.active"
                                      clearable
                                      label="Heure Début"
                                      v-model="restrictionjour1.heure_debut"
                                      readonly
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                    format="24hr"
                                    v-model="restrictionjour1.heure_debut"
                                    :max="restrictionjour1.heure_fin"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      @click="mEditHours1Start = false"
                                      color="primary"
                                      text
                                  >Annuler</v-btn
                                  >
                                  <v-btn
                                      @click="mEditHours1Start = false"
                                      color="primary"
                                      text
                                  >Valider</v-btn
                                  >
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <v-menu
                                  :close-on-content-click="false"
                                  :disabled="!restrictionjour1.active"
                                  min-width="290px"
                                  offset-y
                                  ref="mEditHours1End"
                                  transition="scale-transition"
                                  v-model="mEditHours1End"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                      :disabled="!restrictionjour1.active"
                                      clearable
                                      label="Heure Fin"
                                      v-model="restrictionjour1.heure_fin"
                                      readonly
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                    format="24hr"
                                    v-model="restrictionjour1.heure_fin"
                                    :min="restrictionjour1.heure_debut"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      @click="mEditHours1End = false"
                                      color="primary"
                                      text
                                  >Annuler</v-btn
                                  >
                                  <v-btn
                                      @click="mEditHours1End = false"
                                      color="primary"
                                      text
                                  >Valider</v-btn
                                  >
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" sm="3">
                              <v-checkbox
                                  v-model="restrictionjour2.active"
                                  label="Samedi"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <v-menu
                                  :close-on-content-click="false"
                                  :disabled="!restrictionjour2.active"
                                  min-width="290px"
                                  offset-y
                                  ref="mEditHours2Start"
                                  transition="scale-transition"
                                  v-model="mEditHours2Start"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                      :disabled="!restrictionjour2.active"
                                      clearable
                                      label="Heure Début"
                                      v-model="restrictionjour2.heure_debut"
                                      readonly
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                    format="24hr"
                                    v-model="restrictionjour2.heure_debut"
                                    :max="restrictionjour2.heure_fin"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      @click="mEditHours2Start = false"
                                      color="primary"
                                      text
                                  >Annuler</v-btn
                                  >
                                  <v-btn
                                      @click="mEditHours2Start = false"
                                      color="primary"
                                      text
                                  >Valider</v-btn
                                  >
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <v-menu
                                  :close-on-content-click="false"
                                  :disabled="!restrictionjour2.active"
                                  min-width="290px"
                                  offset-y
                                  ref="mEditHours2End"
                                  transition="scale-transition"
                                  v-model="mEditHours2End"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                      :disabled="!restrictionjour2.active"
                                      clearable
                                      label="Heure Fin"
                                      v-model="restrictionjour2.heure_fin"
                                      readonly
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                    format="24hr"
                                    v-model="restrictionjour2.heure_fin"
                                    :min="restrictionjour2.heure_debut"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      @click="mEditHours2End = false"
                                      color="primary"
                                      text
                                  >Annuler</v-btn
                                  >
                                  <v-btn
                                      @click="mEditHours2End = false"
                                      color="primary"
                                      text
                                  >Valider</v-btn
                                  >
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" sm="3">
                              <v-checkbox
                                  v-model="restrictionjour3.active"
                                  label="Dimanche"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <v-menu
                                  :close-on-content-click="false"
                                  :disabled="!restrictionjour3.active"
                                  min-width="290px"
                                  offset-y
                                  ref="mEditHours3Start"
                                  transition="scale-transition"
                                  v-model="mEditHours3Start"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                      :disabled="!restrictionjour3.active"
                                      clearable
                                      label="Heure Début"
                                      v-model="restrictionjour3.heure_debut"
                                      readonly
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                    format="24hr"
                                    v-model="restrictionjour3.heure_debut"
                                    :max="restrictionjour3.heure_fin"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      @click="mEditHours3Start = false"
                                      color="primary"
                                      text
                                  >Annuler</v-btn
                                  >
                                  <v-btn
                                      @click="mEditHours3Start = false"
                                      color="primary"
                                      text
                                  >Valider</v-btn
                                  >
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                            <v-col cols="12" sm="3">
                              <v-menu
                                  :close-on-content-click="false"
                                  :disabled="!restrictionjour3.active"
                                  min-width="290px"
                                  offset-y
                                  ref="mEditHours3End"
                                  transition="scale-transition"
                                  v-model="mEditHours3End"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                      :disabled="!restrictionjour3.active"
                                      clearable
                                      label="Heure Fin"
                                      v-model="restrictionjour3.heure_fin"
                                      readonly
                                      v-on="on"
                                  ></v-text-field>
                                </template>
                                <v-time-picker
                                    format="24hr"
                                    v-model="restrictionjour3.heure_fin"
                                    :min="restrictionjour3.heure_debut"
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                      @click="mEditHours3End = false"
                                      color="primary"
                                      text
                                  >Annuler</v-btn
                                  >
                                  <v-btn
                                      @click="mEditHours3End = false"
                                      color="primary"
                                      text
                                  >Valider</v-btn
                                  >
                                </v-time-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="3">
                              <h4>Limites</h4>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-select
                                  v-model="defaultItem.limit.periodicite"
                                  :items="periodes"
                                  label="Période *"
                                  outlined
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                              ></v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                md="5"
                                v-if="defaultItem.typePayement.name === 'PRO'"
                            >
                              <v-text-field
                                  v-model="defaultItem.limit.montant"
                                  label="Plafond *"
                                  :rules="[v => v > 0 || '* Champs Requis']"
                                  required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5" v-else>
                              <v-text-field
                                  v-model="defaultItem.limit.montant"
                                  label="Plafond *"
                                  :rules="[v => v >= 0 || '* Champs Requis']"
                                  required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="5">
                              <h4>Stations Non Autorisées</h4>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="6">
                              <v-select
                                  v-model="defaultItem.blacklist"
                                  :items="stations"
                                  item-text="nom"
                                  label="Station(s)"
                                  hint="Sélectionner un ou plusieurs stations à interdire"
                                  return-object
                                  multiple
                                  outlined
                              ></v-select>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <span v-if="showErr" style="color:#f55d5d"
                      >* Veuillez sélectionner au moins un produit CARBURANT
                        !</span
                      >
                      <div class="flex-grow-1"></div>
                      <v-btn
                          color="error"
                          text
                          @click="dialog = false"
                          :loading="loadBtn"
                          :disabled="loadBtn"
                      >Annuler</v-btn
                      >
                      <v-btn
                          color="#03498E"
                          text
                          @click="add"
                          :disabled="!validStep1"
                          :loading="loadBtn"
                      >Enregistrer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogAnswer" max-width="550px" persistent>
                  <v-card style="justify-content: center; display: flex; flex-direction: column; align-items: center;">
                    <v-card-title class="headline">Procéder au Paiement</v-card-title>
                    <!-- payment form -->
                    <div class="container">
                      <div id="myPaymentForm">
                        <div class="kr-embedded">
                          <div class="kr-pan"></div>
                          <div class="kr-expiry"></div>
                          <div class="kr-security-code"></div>
                          <div class="kr-form-error"></div>
                          <button class="kr-payment-button"></button>
                        </div>
                      </div>
                      <div>{{ message }}</div>
                    </div>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogAnswer = false">Annuler</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="350px">
                  <v-card>
                    <v-card-title class="headline"
                    >Supprimer cette demande ?</v-card-title
                    >
                    <v-card-text
                    >Êtes-vous sûr de vouloir supprimer cette demande
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogDelete = false"
                      >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="deletingItem"
                      >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon
                      size="20"
                      @click="deleteItem(item)"
                      color="red darken-2"
                  >
                    mdi-delete-outline</v-icon
                  >
                </template>
                <span>Suppression</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
        <br/>
        <div
            class="clearfix"
            style="display: flex;align-items: center;justify-content: center;"
        >
          <span>Total : {{total.toFixed(2)}}  €</span>
        </div>

        <div
            class="clearfix"
            style="display: flex;align-items: center;justify-content: center;"
        >
          <div class="btn-toolbar">
            <v-btn class="ma-2" type="submit" outlined color="#F6852D"
                   @click="getSeq" :disabled="demandes.length === 0"
            >Régler mes demandes</v-btn
            >
          </div>
        </div>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";
import "moment/locale/fr";
import KRGlue from "@lyracom/embedded-form-glue";

export default {
  name: "DemandeCartePM",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      loadBtn: false,
      loadData: true,
      loadDataIn: true,
      validStep1: true,
      dialog: false,
      dialogDelete: false,
      dialogEditOptions: false,
      dialogAnswer: false,
      showErr: false,
      sb: { status: false, text: "", color: "", y: "top" },
      headers: [],
      demandes: [],
      client: {},
      mEditHours1Start: false,
      mEditHours1End: false,
      mEditHours2Start: false,
      mEditHours2End: false,
      mEditHours3Start: false,
      mEditHours3End: false,
      restrictionjour1: {
        active: false,
        heure_debut: "00:01",
        heure_fin: "23:59",
        restrictionJour: "LUNDI_VENDREDI"
      },
      restrictionjour2: {
        active: false,
        heure_debut: "00:01",
        heure_fin: "23:59",
        restrictionJour: "SAMEDI"
      },
      restrictionjour3: {
        active: false,
        heure_debut: "00:01",
        heure_fin: "23:59",
        restrictionJour: "DIMANCHE"
      },
      defaultItem: {
        blacklist: [],
        client: { id: "" },
        libelle: "",
        immatriculation: "",
        dematerialiser: false,
        hors_parc: false,
        km: false,
        code_chauffeur: false,
        pinCode: "CARTE",
        typePayement: "",
        produits: [
          {
            id: "",
            code: "",
            libelle: "",
            status: "ACTIVE"
          }
        ],
        limit: {
          frequenceJour: 0,
          montant: 0,
          montantJour: 0,
          periodicite: "",
          volume: 0
        },
        restriction: {
          heure_debut: "0001",
          heure_fin: "2359",
          restrictionJour: "EVERYDAY",
          zone: "1"
        },
        station_destinataire: ""
      },
      produits: [],
      initProduits: [],
      products: [],
      selectedProduits: [],
      periodes: ["JOUR", "HEB", "MOIS", "TRIM", "AN"],
      selectedPinCode: [],
      pincodes: ["CARTE", "CLIENT", "USERS"],
      selectedTypePayement: [],
      typePayements: [
        { name: "PRO", libelle: "CARTE PRO" },
        { name: "PORTE_MONNAIE", libelle: "PORTE MONNAIE" },
        { name: "PRE_PAYEE", libelle: "PRE PAYEE" }
      ],
      clients: [],
      stations: [],
      labelTP: {},
      total : 0,
      message: '',
      publicKey: '18478120:publickey_rJow52LFDUeTcWLoCAKDju1UTML7TYiYvLfSGmWcKGqUX',
      endpoint: 'https://api.systempay.fr/',
      formToken: '',
    };
  },
  methods: {
    initialize() {
      this.headers = [
        { text: "Immatriculation", value: "immatriculation" },
        { text: "Libellé Carte", value: "libelle" },
        { text: "Type Carte", value: "typePayement" },
        { text: "Actions", value: "action", sortable: false }
      ];
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
          .get( host + "/produits/", auth)
          .then(result => {
            this.produits = result.data;
            this.products = this.produits;
            this.initProduits = result.data;
            this.defaultItem.hors_parc = false;
            this.defaultItem.km = false;
            this.defaultItem.code_chauffeur = false;
            this.defaultItem.libelle = "PM";
            this.restrictionjour1.active = true;
            this.restrictionjour2.active = true;
            this.restrictionjour3.active = true;
            this.defaultItem.limit.montant = 0;
            this.defaultItem.limit.periodicite = "AN";

            this.defaultItem.typePayement = {
              name: "PORTE_MONNAIE",
              libelle: "PORTE MONNAIE"
            };
            this.defaultItem.produits = [...this.initProduits];
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.produits = [];
          });

      axios
          .get( host + "/stations/", auth)
          .then(result => {
            this.stations = result.data;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.stations = [];
          });
    },
    add() {
      let authorize = false;
      let item = this.defaultItem;
      item.produits.forEach(element => {
        if (element.categorie === "CARBURANT") {
          authorize = true;
        }
      });

      if (authorize) {
        item.typePayement = item.typePayement.name;
        item.client.id = this.entity.id;

        let restrictions = [];
        if (this.restrictionjour1.active) {
          let hd = this.restrictionjour1.heure_debut.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
          let hf = this.restrictionjour1.heure_fin.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
          restrictions.push({
            restrictionJour: this.restrictionjour1.restrictionJour,
            heure_debut: hd,
            heure_fin: hf
          });
        }
        if (this.restrictionjour2.active) {
          let hd = this.restrictionjour2.heure_debut.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
          let hf = this.restrictionjour2.heure_fin.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
          restrictions.push({
            restrictionJour: this.restrictionjour2.restrictionJour,
            heure_debut: hd,
            heure_fin: hf
          });
        }
        if (this.restrictionjour3.active) {
          let hd = this.restrictionjour3.heure_debut.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
          let hf = this.restrictionjour3.heure_fin.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "");
          restrictions.push({
            restrictionJour: this.restrictionjour3.restrictionJour,
            heure_debut: hd,
            heure_fin: hf
          });
        }

        if (item.hors_parc) {
          item.immatriculation = "";
        }

        item.restriction = restrictions;

        this.demandes.push(item);
        this.dialog = false;
        let size = this.demandes.length;
        if (size === 1) {
          this.total = 10;
        } else if (size === 2){
          this.total = 15;
        } else if (size === 3){
          this.total = 20;
        }
        this.defaultItem = {
          blacklist: [],
          client: { id: "" },
          libelle: "",
          immatriculation: "",
          dematerialiser: false,
          hors_parc: false,
          km: false,
          code_chauffeur: false,
          pinCode: "CARTE",
          typePayement: {
            name: "PORTE_MONNAIE",
            libelle: "PORTE MONNAIE"
          },
          produits: [
            {
              id: "",
              code: "",
              libelle: "",
              status: "ACTIVE"
            }
          ],
          limit: {
            frequenceJour: 0,
            montant: 185,
            montantJour: 0,
            periodicite: "JOUR",
            volume: 0
          },
          restriction: {
            heure_debut: "0001",
            heure_fin: "2359",
            restrictionJour: "EVERYDAY",
            zone: "1"
          },
          station_destinataire: ""
        };

        this.defaultItem.hors_parc = false;
        this.defaultItem.km = false;
        this.defaultItem.code_chauffeur = false;
        this.defaultItem.libelle = "PM";
        this.restrictionjour1.active = true;
        this.restrictionjour2.active = true;
        this.restrictionjour3.active = true;
        this.defaultItem.limit.montant = 0;
        this.defaultItem.limit.periodicite = "AN";

        this.defaultItem.typePayement = {
          name: "PORTE_MONNAIE",
          libelle: "PORTE MONNAIE"
        };
        this.defaultItem.produits = [...this.initProduits];
      } else {
        this.showErr = true;
      }
    },
    getSeq() {
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
          .get(host + "/tickets/receipt/get-seq", auth)
          .then(result => {
            this.goPaiement(result.data);
            this.loadDownload = false;
          })
          .catch(err => {
            console.log(err);
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
          });
    },
    goPaiement(order){
      this.dialogAnswer = true;
      const endpoint = 'https://api.systempay.fr/'
       const publicKey = '18478120:publickey_rJow52LFDUeTcWLoCAKDju1UTML7TYiYvLfSGmWcKGqUX'
       let identifiant = '18478120:prodpassword_GzHEwLfFLx94M47AFH4Cwr6e5VoVUfSUk3a5VszplpavA';
     // const publicKey = '18478120:testpublickey_gL13HMkBM0IJgUtYMSmWW2wbXgces82CB5RZVh0vAxnLP'
     // let identifiant = '18478120:testpassword_i8RyK7bbRW8IV3U1GkAOGtEexbQw3MhQWQZCOtkxi82hs';
      let identifiant64 = btoa(identifiant);
      let item = {
        amount: this.total*100,
        currency: "EUR",
        orderId: order.toString(),
        customer: {
          email: this.entity.entreprise.email,
          reference: this.entity.id.toString()
        },
        formAction: identifiant64
      };
      axios
          .post(host + '/tickets/systempay', item)
          .then(resp => {
            this.formToken = resp.data
            return KRGlue.loadLibrary(
                endpoint,
                publicKey
            )
          })
          .then(({ KR }) =>
              KR.setFormConfig({
                /* set the minimal configuration */
                formToken: this.formToken,
                'kr-language': 'fr-FR' /* to update initialization parameter */
              })
          )
          .then(({ KR }) => KR.onSubmit(this.validatePayment)) // Custom payment callback
          .then(({ KR }) =>
              KR.attachForm('#myPaymentForm')
          ) /* add a payment form  to myPaymentForm div*/
          .then(({ KR, result }) =>
              KR.showForm(result.formId)
          ) /* show the payment form */
          .catch(error =>
              this.message = error + ' (see console for more details)'
          )
    },
    validatePayment(paymentData) {
      if(paymentData.clientAnswer.orderStatus === 'PAID') {
        this._writeReceipt(paymentData);
      } else {
        this.dialogAnswer = false;
        this.sb.status = true;
        this.sb.text = "Un erreur est survenue ! ";
        this.sb.color = "error";
      }

      return false;
      // Return false to prevent the redirection
    },
    _saveDemande() {
      axios({
        method: "POST",
        url: host + "/demandes/demandes",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        data: JSON.stringify(this.demandes)
      })
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.dialogAnswer = false;
            this.sb.status = true;
            this.sb.text = "Paiement effectué ! ";
            this.sb.color = "success";
            this.demandes = [];
            this.total = 0;
            this.initialize();
            this.montant="";
            this.loadBtn = false;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            console.log("error ", error)
            this.dialogAnswer = false;
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.demandes = [];
            this.total = 0;
            this.initialize();
            this.montant="";
            this.loadBtn = false;
          });
    },
    _writeReceipt(paymentData) {
      let receipt = {
        amount: paymentData.clientAnswer.orderDetails.orderTotalAmount/100,
        client: this.entity,
        label: paymentData.clientAnswer.orderDetails.orderId,
        orderNumber: paymentData.clientAnswer.orderDetails.orderId
      };

      axios({
        method: "POST",
        url: host + "/tickets/receipt",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: receipt
      }) // eslint-disable-next-line no-unused-vars
          .then(result => {
            if (result.status === 200) {
              this._saveDemande();
            } else {
              this.dialogAnswer = false;
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue ! ";
              this.sb.color = "error";
              this.demandes = [];
              this.total = 0;
              this.initialize();
              this.montant="";
              this.loadBtn = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            console.log(error, 'error')
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.initialize();
            this.demandes = [];
            this.total = 0;
            this.montant="";
            this.loadBtn = false;
            this.dialogAnswer = false;
          });
    },
    editOptions(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEditOptions = true;
    },
    editingOptions() {},
    deleteItem(item) {
      let editedIndex = this.demandes.indexOf(item);
      this.deletedItem = Object.assign({}, item);
      this.demandes.splice(editedIndex, 1);
      if (this.demandes.length === 0) {
        this.total = 0;
      } else if (this.demandes.length === 1){
        this.total = 10;
      } else if (this.demandes.length === 2){
        this.total = 15;
      }
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:

            host +
            "/demandes/" +
            this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
          // eslint-disable-next-line no-unused-vars
          .then(result => {
            this.sb.status = true;
            this.sb.text = "Demande supprimée ! ";
            this.sb.color = "success";
            this.initialize();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
          });

      this.dialogDelete = false;
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else return "orange";
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllProduit) {
          this.defaultItem.produits = [];
        } else {
          this.defaultItem.produits = this.produits.slice();
        }
      });
    },
    changeRoute(a) {
      this.produits = [...this.initProduits];
      this.defaultItem.produits = [];
      this.products = [];
      let arr = [];
      switch (a.name) {
        case "PRO":
          this.defaultItem.hors_parc = true;
          this.defaultItem.km = true;
          this.defaultItem.code_chauffeur = true;
          this.defaultItem.libelle = "";
          this.restrictionjour1.active = true;
          this.restrictionjour2.active = false;
          this.restrictionjour3.active = false;
          this.defaultItem.limit.montant = 185;
          this.defaultItem.limit.periodicite = "JOUR";

          arr = [...this.initProduits];

          arr.splice(
              arr.findIndex(item => item.code === "50"),
              1
          );
          arr.splice(
              arr.findIndex(item => item.code === "51"),
              1
          );

          this.defaultItem.produits = arr;
          this.products = arr;
          break;
        case "PRE_PAYEE":
          this.defaultItem.hors_parc = true;
          this.defaultItem.km = true;
          this.defaultItem.code_chauffeur = true;
          this.defaultItem.libelle = "";
          this.restrictionjour1.active = true;
          this.restrictionjour2.active = false;
          this.restrictionjour3.active = false;
          this.defaultItem.limit.montant = 0;
          this.defaultItem.limit.periodicite = "";

          arr = [...this.initProduits];

          arr.splice(
              arr.findIndex(item => item.code === "50"),
              1
          );
          arr.splice(
              arr.findIndex(item => item.code === "51"),
              1
          );

          this.defaultItem.produits = arr;
          this.products = arr;
          break;
        case "PORTE_MONNAIE":
          this.defaultItem.hors_parc = false;
          this.defaultItem.km = false;
          this.defaultItem.code_chauffeur = false;
          this.defaultItem.libelle = "PM";
          this.restrictionjour1.active = true;
          this.restrictionjour2.active = true;
          this.restrictionjour3.active = true;
          this.defaultItem.limit.montant = 0;
          this.defaultItem.limit.periodicite = "AN";

          arr = [...this.initProduits];
          arr.forEach(element => {
            if (element.code === "50") {
              this.products.push(element);
              this.defaultItem.produits.push(element);
            } else if (element.code === "51") {
              this.products.push(element);
              this.defaultItem.produits.push(element);
            }
          });
          break;
      }
    },
    changeHorsParc(a) {
      if (this.role === "ROLE_ADMIN" || this.role === "ROLE_COMMERCIAL") {
        this.produits = this.initProduits;
        if (a) {
          this.defaultItem.produits = this.produits;
          this.defaultItem.produits.forEach(element => {
            if (
                element.libelle === "PM carburant" ||
                element.libelle === "PM boutique"
            ) {
              this.defaultItem.produits.splice(
                  this.defaultItem.produits.indexOf(element),
                  1
              );
            }
          });
        } else {
          this.defaultItem.produits = [];
        }
      }
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    likesAllProduit() {
      return this.defaultItem.produits.length === this.produits.length;
    },
    icon() {
      if (this.likesAllProduit) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
            .locale("fr")
            .format("L");

        let heure = moment(value)
            .locale("fr")
            .format("LT");

        return date + " " + heure;
      }
    }
  },
  mounted() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.initialize();
  },
};
</script>

<style src="./DemandeCartePM.scss" lang="scss" scoped />

