<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Fabrication Carte</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="text-right">
        <v-btn
                color="success"
                class="ma-2 white--text"
                :loading="loadCSV"
                :disabled="loadCSV"
        >
          <download-csv
                  :data="demandesCSV"
                  :labels="labels"
                  :fields="fields"
                  name="demandes_fabrication.csv"
                  delimiter=";"
                  separator-excel
          >
            Télécharger CSV <v-icon right dark>mdi-cloud-download</v-icon>
          </download-csv>
        </v-btn>

      </div>
      <div class="table-responsive">
        <v-skeleton-loader :loading="loadData" type="table">
          <v-data-table
            :headers="headers"
            :items="demandes"
            :loading="loadDataIn"
            sort-by="nom"
            class="elevation-1"
          >
            <template v-slot:item.datedemande="{ item }">
              {{ item.datedemande | formatUnix }}
            </template>
            <template v-slot:item.dateValidation="{ item }">
              {{ item.dateValidation | formatUnix }}
            </template>
            <template v-slot:item.immatriculation="{ item }">
              <span v-if="item.carte.hors_parc">Hors Parc</span>
              <span v-else>{{ item.immatriculation }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div class="flex-grow-1"></div>
                <v-dialog v-model="dialog" max-width="850px" persistent>
                  <v-card>
                    <v-card-title class="headline">Demande</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-form ref="formStep1" v-model="validStep1">
                          <v-row justify="center">
                            <span>Veuillez saisir un numéro de carte</span>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="10">
                              <v-autocomplete
                                v-model="carte"
                                :items="cartes"
                                item-text="serialNumber"
                                label="N° de Carte *"
                                persistent-hint
                                prepend-icon="mdi-account-badge-horizontal-outline"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              >
                              </v-autocomplete>
                            </v-col>
                          </v-row>
                          <v-row justify="center" v-if="errorMessage">
                            <span style="color:red">Le numéro de cette carte est déjà attribué</span>
                          </v-row>
                          <v-divider></v-divider>
                          <v-row justify="center">
                            <v-col cols="12" md="10">
                              <v-text-field
                                v-model="
                                  defaultItem.client.entreprise.contratNumero
                                "
                                label="N° Client"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="2">
                              <v-btn
                                class="ma-2"
                                tile
                                outlined
                                :color="getColor(defaultItem.client.status)"
                              >
                                <span
                                  v-if="defaultItem.client.status === 'ACTIVE'"
                                  >Active
                                  <v-icon color="green">mdi-check</v-icon></span
                                >
                                <span v-else
                                  >Inactive
                                  <v-icon color="orange"
                                    >mdi-close</v-icon
                                  ></span
                                >
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="3">
                              <v-checkbox
                                v-model="defaultItem.carte.hors_parc"
                                :label="`Hors Parc`"
                                readonly
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-text-field
                                v-model="defaultItem.immatriculation"
                                label="Immatriculation"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                              <v-text-field
                                v-model="defaultItem.carte.libelle"
                                label="Libellé Carte"
                                readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="4">
                              <v-checkbox
                                label="KM"
                                v-model="defaultItem.km"
                                readonly
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-checkbox
                                label="Code chauffeur"
                                v-model="defaultItem.code_chauffeur"
                                readonly
                              ></v-checkbox>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" sm="12">
                              <v-combobox
                                v-model="produits"
                                item-text="libelle"
                                label="Produits"
                                multiple
                                readonly
                              ></v-combobox>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="defaultItem.pinCode"
                                label="Type Pin Code"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                              <v-text-field
                                v-model="defaultItem.carte.typePayement"
                                label="Type Paiement"
                                readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="3">
                              <h4>Plage horaire d'utilisation</h4>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-list disabled>
                              <v-list-item-group color="primary">
                                <v-list-item
                                  v-for="(item, i) in defaultItem.carte
                                    .restriction"
                                  :key="i"
                                >
                                  <v-list-item-icon>
                                    <v-icon>mdi-clock</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-content>
                                    <v-list-item-title
                                      v-text="
                                        `${item.restrictionJour} - ${item.heure_debut} ${item.heure_fin}`
                                      "
                                    ></v-list-item-title>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="3">
                              <h4>Limites</h4>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="4">
                              <v-text-field
                                v-model="defaultItem.carte.limit.periodicite"
                                label="Périodes"
                                readonly
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                              <v-text-field
                                v-model="defaultItem.carte.limit.montant"
                                label="Plafond"
                                readonly
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="5">
                              <h4>Stations Non Autorisées</h4>
                            </v-col>
                          </v-row>
                          <v-row
                            justify="center"
                            v-if="defaultItem.carte.blacklist === null"
                          >
                            <span class="subtitle-2"
                              >Aucune station interdite</span
                            >
                          </v-row>
                          <v-row
                            justify="center"
                            v-if="defaultItem.carte.blacklist !== null"
                          >
                            <v-col cols="12" sm="10">
                              <v-combobox
                                v-model="defaultItem.carte.blacklist"
                                item-text="nom"
                                label="Stations(s)"
                                multiple
                                readonly
                              ></v-combobox>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="warning"
                        text
                        @click="dialog = false;errorMessage = false"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="success"
                        @click="checkCarte"
                        :disabled="!validStep1"
                        :loading="loadBtn"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon> Valider
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="350px">
                  <v-card>
                    <v-card-title class="headline"
                    >Supprimer cette demande ?</v-card-title
                    >
                    <v-card-text
                    >Êtes-vous sûr de vouloir supprimer cette demande
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogDelete = false"
                      >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="deletingItem"
                      >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top v-if="role === 'ROLE_ADMIN'">
                <template v-slot:activator="{ on }">
                  <v-icon
                    size="20"
                    color="orange"
                    v-on="on"
                    @click="showDialog(item)"
                    >mdi-send-clock-outline</v-icon
                  >
                </template>
                <span>Validation</span>
              </v-tooltip>
              <v-tooltip top v-if="role === 'ROLE_ADMIN'">
                <template v-slot:activator="{ on }">
                  <v-icon
                          size="20"
                          @click="deleteItem(item)"
                          color="red darken-2"
                  >
                    mdi-delete-outline</v-icon
                  >
                </template>
                <span>Suppression</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import JsonCSV from "vue-json-csv";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";
import "moment/locale/fr";
import * as _ from "lodash";

export default {
  name: "Clients",
  components: { Widget, "download-csv": JsonCSV },
  data() {
    return {
      role: null,
      token: null,
      loadBtn: false,
      loadCSV: false,
      loadData: true,
      loadDataIn: true,
      validStep1: true,
      dialog: false,
      dialogDelete: false,
      dialogEditOptions: false,
      sb: { status: false, text: "", color: "", y: "top" },
      headers: [
        {
          text: "N° Contrat",
          align: "left",
          value: "client.entreprise.contratNumero"
        },
        { text: "Date Demande", value: "datedemande" },
        { text: "Date Validation", value: "dateValidation" },
        { text: "Immatriculation", value: "immatriculation" },
        { text: "Libellé Carte", value: "carte.libelle" },
        { text: "Type Carte", value: "carte.typePayement" },
        { text: "Actions", value: "action", sortable: false }
      ],
      demandes: [],
      demandesCSV: [],
      cartes: [],
      produits: [],
      carte: "",
      defaultItem: {
        id: "",
        pinCode: "",
        km: false,
        code_chauffeur: false,
        client: { id: "", entreprise: { contratNumero: "" } },
        carte: {
          typeCarte: "",
          libelle: "",
          immtriculation: "",
          dematerialiser: false,
          hors_park: false,
          typePayement: "",
          produits: [],
          limit: {
            frequenceJour: 0,
            montant: 0,
            montantJour: 0,
            periodicite: "",
            volume: 0
          },
          restriction: {
            heure_debut: "",
            heure_fin: "",
            restrictionJour: "",
            zone: ""
          }
        }
      },
      labels: {
        contrat: "N Contrat",
        date_demande: "Date Demande",
        date_validation: "Date Validation",
        immatriculation: "Immatriculation",
        carte_libelle: "Carte Libelle",
        type_carte: "Type Carte",
        code_chauffeur: "Code Chauffeur",
        hors_parc: "Hors Parc",
        station_destinataire : "Station Destinataire",
        produitsCode : "Produits Code",
        produitsLibelle : "Produits Libelle",
        restrictionsJour : "Restrictions Jour",
        restrictions_HeureDebut : "Restrictions Heure Debut",
        restrictions_HeureFin : "Restrictions Heure Fin",
        limite_periode : "Limite Periode",
        limite_montant : "Limite_Montant"
      },
      fields: [
        "contrat",
        "date_demande",
        "date_validation",
        "immatriculation",
        "carte_libelle",
        "type_carte",
        "code_chauffeur",
        "hors_parc",
        "station_destinataire",
        "produitsCode",
        "produitsLibelle",
        "restrictionsJour",
        "restrictions_HeureDebut",
        "restrictions_HeureFin",
        "limite_periode",
        "limite_montant"
      ],
      errorMessage: false,
    };
  },
  methods: {
    initialize() {
      this.loadDataIn = true;
      this.loadData = true;
      axios
        .get(
           host + "/demandes/validation"
        )
        .then(result => {
          this.demandes = result.data;
          this.loadDataIn = false;
          this.loadData = false;
          this._sortDemandes();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.demandes = [];
          this.loadDataIn = false;
          this.loadData = false;
        });
      axios
        .get( host + "/demandes/uuid/")
        .then(result => {
          this.cartes = result.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.cartes = [];
        });
    },
    showDialog(item) {
      this.defaultItem = Object.assign({}, item);
      this.produits = _.sortBy(item.carte.produits, "code");
      this.dialog = true;
    },
    checkCarte(){
      this.loadBtn = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };
      axios
          .get(
              host + "/cartes/sn/"+this.carte,
              auth
          )
          .then(result => {
            if (result.status === 200) {
              this.loadBtn = false;
              this.errorMessage = true;
            }
            else {
              this.errorMessage = false;
              this.save();
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.loadBtn = false;
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.dialog = false;
          });
    },
    save() {
      this.loadBtn = true;
      this.defaultItem.carte.serialNumber = this.carte;

      axios({
        method: "POST",
        url:

          host +
          "/demandes/new/" +
          this.carte +
          "/" +
          this.defaultItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
        .then(result => {
          if (result.status === 200) {
            axios({
              method: "PUT",
              url:

                host +
                "/demandes/" +
                this.defaultItem.id +
                "/FABRICATION",
              baseUrl: host,
              headers: {
                Authorization: "Bearer " + this.token,
                "X-Requested-Width": "XMLHttpRequest",
                "Content-Type": "application/json",
                Accept: "application/json"
              }
            })
              .then(result => {
                if (result.status === 200) {
                  this.sb.status = true;
                  this.sb.text = "Demande mise à jour ! ";
                  this.sb.color = "success";
                  this.initialize();
                  this.loadBtn = false;
                  this.dialog = false;
                } else {
                  this.sb.status = true;
                  this.sb.text = "Une erreur est survenue ! ";
                  this.sb.color = "error";
                  this.initialize();
                  this.loadBtn = false;
                  this.dialog = false;
                }
              })
              .catch(error => {
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue ! ";
                this.sb.color = "error";
                console.log(error);
                this.initialize();
                this.loadBtn = false;
                this.dialog = false;
              });
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.loadBtn = false;
            this.initialize();
            this.dialog = false;
          }
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log(error);
          this.loadBtn = false;
          this.initialize();
          this.dialog = false;
        });
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:  host + "/demandes/" + this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width" : "XMLHttpRequest"
        }
      })
              .then(result => {
                this.sb.status = true;
                this.sb.text = "Demande supprimée ! ";
                this.sb.color = "success";
                this.initialize();
              })
              .catch(error => {
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue ! ";
                this.sb.color = "error";
                console.log("err : ", error);
              });

      this.dialogDelete = false;
    },
    _sortDemandes(){
      this.demandesCSV = [];
      let item = {};
      this.demandes.forEach(element => {
        item = {contrat : element.client.entreprise.contratNumero, date_demande : "", date_validation: "",
          immatriculation : element.immatriculation, carte_libelle : element.carte.libelle, type_carte : element.carte.typePayement,
        code_chauffeur : element.code_chauffeur, hors_parc : element.carte.hors_parc, station_destinataire: "", produitsCode: "", produitsLibelle: "",
          restrictionsJour: "", restrictions_HeureDebut: "", restrictions_HeureFin: "", limite_periode: "", limite_montant: ""};

        item.date_demande = moment(element.datedemande).locale("fr").format("L") + " " +
                moment(element.datedemande).locale("fr").format("LT");
        item.date_validation = moment(element.dateValidation).locale("fr").format("L") + " " +
                moment(element.dateValidation).locale("fr").format("LT");

        let produitsCode = "";
        let produitsLibelle = "";
        let restrictionsJour = "";
        let restrictions_HeureDebut = "";
        let restrictions_HeureFin = "";

        if(element.carte.produits !== null){
          element.carte.produits.forEach(produit =>{
            produitsCode = produitsCode.concat(produit.code, ' /');
            produitsLibelle = produitsLibelle.concat(produit.libelle, ' /');
          });
          item.produitsCode = produitsCode.slice(0, -1);
          item.produitsLibelle = produitsLibelle.slice(0, -1);
        }
        if(element.carte.restriction !== null){
          element.carte.restriction.forEach(restrict =>{
            restrictionsJour = restrictionsJour.concat(restrict.restrictionJour, ' /');
            restrictions_HeureDebut = restrictions_HeureDebut.concat(restrict.heure_debut, ' /');
            restrictions_HeureFin = restrictions_HeureFin.concat(restrict.heure_fin, ' /');
          });
          item.restrictionsJour = restrictionsJour.slice(0, -1);
          item.restrictions_HeureDebut = restrictions_HeureDebut.slice(0, -1);
          item.restrictions_HeureFin = restrictions_HeureFin.slice(0, -1);
        }

        if(element.carte.limit !== null){
          item.limite_periode = element.carte.limit.periodicite;
          item.limite_montant = this.formatFloat(element.carte.limit.montant);
        }

        if(element.station_destinataire !== null){
          item.station_destinataire = element.station_destinataire;
        }

        this.demandesCSV.push(item);
      });
      this.loadCSV = false;
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else return "orange";
    }
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationRole", "hasAuthenticationToken"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.initialize();
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
          .locale("fr")
          .format("L");

        let heure = moment(value)
          .locale("fr")
          .format("LT");

        return date + " " + heure;
      }
    }
  }
};
</script>

<style src="./ManageCartes.scss" lang="scss" scoped />
