<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Validation Carte</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-skeleton-loader :loading="loadData" type="table">
          <v-data-table
            :headers="headers"
            :items="demandes"
            :loading="loadDataIn"
            sort-by="nom"
            class="elevation-1"
          >
            <template v-slot:item.datedemande="{ item }">
              {{ item.datedemande | formatUnix }}
            </template>
            <template v-slot:item.immatriculation="{ item }">
              <span v-if="item.carte.hors_parc">Hors Parc</span>
              <span v-else>{{ item.immatriculation }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div class="flex-grow-1"></div>
                <v-dialog v-model="dialog" max-width="850px" persistent>
                  <v-card>
                    <v-card-title class="headline"
                      >Demande en cours</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" md="10">
                            <v-text-field
                              v-model="
                                defaultItem.client.entreprise.contratNumero
                              "
                              label="N° Client"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="2">
                            <v-btn
                              class="ma-2"
                              tile
                              outlined
                              :color="getColor(defaultItem.client.status)"
                            >
                              <span
                                v-if="defaultItem.client.status === 'ACTIVE'"
                                >Active
                                <v-icon color="green">mdi-check</v-icon></span
                              >
                              <span v-else
                                >Inactive
                                <v-icon color="orange">mdi-close</v-icon></span
                              >
                            </v-btn>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="3">
                            <v-checkbox
                              v-model="defaultItem.carte.hors_parc"
                              :label="`Hors Parc`"
                              readonly
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="defaultItem.immatriculation"
                              label="Immatriculation"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              v-model="defaultItem.carte.libelle"
                              label="Libellé Carte"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="4">
                            <v-checkbox
                              label="KM"
                              v-model="defaultItem.km"
                              readonly
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-checkbox
                              label="Code chauffeur"
                              v-model="defaultItem.code_chauffeur"
                              readonly
                            ></v-checkbox>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="12">
                            <v-combobox
                              v-model="defaultItem.carte.produits"
                              item-text="libelle"
                              label="Produits"
                              multiple
                              readonly
                            ></v-combobox>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" sm="5">
                            <v-text-field
                              v-model="defaultItem.pinCode"
                              label="Type Pin Code"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="5">
                            <v-text-field
                              v-model="defaultItem.carte.typePayement"
                              label="Type Paiement"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="3">
                            <h4>Plage horaire d'utilisation</h4>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-list disabled>
                            <v-list-item-group color="primary">
                              <v-list-item
                                v-for="(item, i) in defaultItem.carte
                                  .restriction"
                                :key="i"
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-clock</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="
                                      `${item.restrictionJour} - ${item.heure_debut} ${item.heure_fin}`
                                    "
                                  ></v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="3">
                            <h4>Limites</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="defaultItem.carte.limit.periodicite"
                              label="Périodes"
                              readonly
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="defaultItem.carte.limit.montant"
                              label="Plafond"
                              readonly
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="5">
                            <h4>Stations Non Autorisées</h4>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="6">
                            <v-select
                              v-model="defaultItem.carte.blacklist"
                              :items="stations"
                              item-text="nom"
                              label="Station(s)"
                              return-object
                              multiple
                              outlined
                            ></v-select>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="warning" text @click="dialog = false"
                        >Annuler</v-btn
                      >
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="error"
                        @click="save('REFUSER')"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                      >
                        <v-icon left>mdi-close-circle-outline</v-icon> Refuser
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        tile
                        outlined
                        color="success"
                        @click="save('VALIDER')"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                      >
                        <v-icon left>mdi-check-circle-outline</v-icon> Valider
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="350px">
                  <v-card>
                    <v-card-title class="headline"
                      >Supprimer cette demande ?</v-card-title
                    >
                    <v-card-text
                      >Êtes-vous sûr de vouloir supprimer cette demande
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogDelete = false"
                        >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="deletingItem"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip
                top
                v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR'"
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    size="20"
                    color="orange"
                    v-on="on"
                    @click="showDialog(item)"
                    >mdi-send-clock-outline</v-icon
                  >
                </template>
                <span>Validation</span>
              </v-tooltip>
              <v-tooltip top v-if="role === 'ROLE_ADMIN'">
                <template v-slot:activator="{ on }">
                  <v-icon
                    size="20"
                    @click="deleteItem(item)"
                    color="red darken-2"
                  >
                    mdi-delete-outline</v-icon
                  >
                </template>
                <span>Suppression</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";
import "moment/locale/fr";

export default {
  name: "Clients",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      loadBtn: false,
      loadData: true,
      loadDataIn: true,
      dialog: false,
      dialogDelete: false,
      dialogEditOptions: false,
      sb: { status: false, text: "", color: "", y: "top" },
      headers: [
        {
          text: "N° Contrat",
          align: "left",
          value: "client.entreprise.contratNumero"
        },
        { text: "Date Demande", value: "datedemande" },
        { text: "Immatriculation", value: "immatriculation" },
        { text: "Libellé Carte", value: "carte.libelle" },
        { text: "Type Carte", value: "carte.typePayement" },
        { text: "Actions", value: "action", sortable: false }
      ],
      demandes: [],
      defaultItem: {
        id: "",
        pinCode: "",
        client: { id: "", entreprise: { contratNumero: "" } },
        carte: {
          typeCarte: "",
          libelle: "",
          immtriculation: "",
          dematerialiser: false,
          hors_park: false,
          typePayement: "",
          produits: [],
          blacklist: [],
          limit: {
            frequenceJour: 0,
            montant: 0,
            montantJour: 0,
            periodicite: "",
            volume: 0
          },
          restriction: {
            heure_debut: "",
            heure_fin: "",
            restrictionJour: "",
            zone: ""
          }
        }
      },
      stations: []
    };
  },
  methods: {
    initialize() {
      this.loadDataIn = true;
      this.loadData = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
        .get(


            host +
            "/demandes/demande"
        )
        .then(response => {
          this.demandes = response.data;
          this.loadDataIn = false;
          this.loadData = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.demandes = [];
          this.loadDataIn = false;
          this.loadData = false;
        });

      axios
        .get( host + "/stations/", auth)
        .then(result => {
          this.stations = result.data;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.stations = [];
        });
    },
    showDialog(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialog = true;
    },
    // eslint-disable-next-line no-unused-vars
    save(action) {
      this.loadBtn = true;
      axios({
        method: "PUT",
        url:

          host +
          "/demandes/" +
          this.defaultItem.id +
          "/" +
          action,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Demande mise à jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.loadBtn = false;
          this.dialog = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.initialize();
          this.loadBtn = false;
          this.dialog = false;
        });
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:

          host +
          "/demandes/" +
          this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Demande supprimée ! ";
          this.sb.color = "success";
          this.initialize();
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log("err : ", error);
        });

      this.dialogDelete = false;
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else return "orange";
    }
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationRole", "hasAuthenticationToken"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.initialize();
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
          .locale("fr")
          .format("L");

        let heure = moment(value)
          .locale("fr")
          .format("LT");

        return date + " " + heure;
      }
    }
  }
};
</script>

<style src="./ManageCartes.scss" lang="scss" scoped />
