var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tables-basic"},[_c('v-snackbar',{attrs:{"top":_vm.sb.y === 'top',"color":_vm.sb.color},model:{value:(_vm.sb.status),callback:function ($$v) {_vm.$set(_vm.sb, "status", $$v)},expression:"sb.status"}},[_vm._v(" "+_vm._s(_vm.sb.text)+" ")]),_vm._m(0),_c('Widget',{staticClass:"mx-auto elevation-0"},[_c('div',{staticClass:"table-responsive"},[_c('v-skeleton-loader',{attrs:{"loading":_vm.loadData,"type":"table"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.demandes,"loading":_vm.loadDataIn,"sort-by":"datedemande","sort-desc":"datedemande"},scopedSlots:_vm._u([{key:"item.datedemande",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatUnix")(item.datedemande))+" ")]}},{key:"item.immatriculation",fn:function(ref){
var item = ref.item;
return [(item.carte.hors_parc)?_c('span',[_vm._v("Hors Parc")]):_c('span',[_vm._v(_vm._s(item.immatriculation))])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Supprimer cette demande ?")]),_c('v-card-text',[_vm._v("Êtes-vous sûr de vouloir supprimer cette demande ?")]),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("Annuler")]),_c('v-btn',{attrs:{"color":"#03498E","text":""},on:{"click":_vm.deletingItem}},[_vm._v("Confirmer")])],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.role === 'ROLE_CLIENT' || _vm.role === 'ROLE_ADMIN')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',{attrs:{"size":"20","color":"red darken-2"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete-outline")])]}}],null,true)},[_c('span',[_vm._v("Suppression")])]):_vm._e()]}},{key:"no-data",fn:function(){return undefined},proxy:true}])})],1)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h3',{staticClass:"page-title",staticStyle:{"color":"white","margin-top":"4%"}},[_vm._v(" Gestion des "),_c('span',{staticClass:"fw-semi-bold"},[_vm._v("Demandes de Carte")])])}]

export { render, staticRenderFns }