<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Reçus</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-skeleton-loader :loading="loadData" type="table">
          <v-data-table
              :headers="headers"
              :items="receipts"
              sort-by="createdDate"
              sort-desc="createdDate"
              class="elevation-0"
              :loading="loadDataIn"
          >
            <template v-slot:item.createdDate="{ item }">
              {{ item.createdDate | formatUnix }}
            </template>
            <template v-slot:item.amount="{ item }">
              <span>{{ formatFloat(item.amount) }} €</span>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import {host} from "@/utils/constants";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
  name: "Receipt",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      sb: { status: false, text: "", color: "", y: "top" },
      receipts: [],
      headers: [],
      loadData: true,
      loadDataIn: true,
    }
    },
  methods: {
    initialize() {
      this.loadDataIn = true;
      this.loadData = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };
      this.receipts = [];

      if(this.role === "ROLE_CLIENT") {
        this.headers = [
          {
            text: "Date",
            align: "left",
            value: "createdDate"
          },
          { text: "N° Reçu", value: "orderNumber" },
          { text: "Libellé", value: "label" },
          { text: "Montant", value: "amount" },
        ];
        axios.get(host + "/tickets/receipts/client/" + this.entity.id, auth)
            .then(result => {
              this.receipts = result.data;
              this.loadData = false;
              this.loadDataIn = false;
            })
            .catch(err => {
              console.log(err);
              this.receipts = [];
              this.loadDataIn = false;
              this.loadData = false;
            });
      } else {
        this.headers = [
          {
            text: "Date",
            align: "left",
            value: "createdDate"
          },
          { text: "N° Reçu", value: "orderNumber" },
          { text: "Libellé", value: "label" },
          { text: "Montant", value: "amount" },
          { text: "Client", value: "client.entreprise.enseigne" },
        ];
        axios.get(host + "/tickets/receipts", auth)
            .then(result => {
              this.receipts = result.data;
              this.loadData = false;
              this.loadDataIn = false;
            })
            .catch(err => {
              console.log(err);
              this.receipts = [];
              this.loadDataIn = false;
              this.loadData = false;
            });
      }
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ])
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
            .locale("fr")
            .format("L");

        return date;
      }
    }
  },
  mounted() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.initialize();
  },
  beforeDestroy() {}
}
</script>

<style scoped>

</style>
