<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Pétroliers</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-data-table
          :headers="headers"
          :items="petroliers"
          sort-by="societeNom"
          class="elevation-1"
        >
          <template v-slot:item.status="{ item }">
            <v-btn
              class="ma-2"
              tile
              outlined
              :color="getColor(item.status)"
              @click="updateStatus(item)"
            >
              <span v-if="item.status === 'ACTIVE'"
                >Active <v-icon color="green">mdi-check</v-icon></span
              >
              <span v-else
                >Inactive <v-icon color="orange">mdi-close</v-icon></span
              >
            </v-btn>
          </template>
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div class="flex-grow-1"></div>
              <v-dialog v-model="dialog" max-width="700px" persistent>
                <template v-slot:activator="{ on }">
                  <v-btn
                    class="ma-2"
                    outlined
                    color="#03498E"
                    v-on="on"
                    v-if="role === 'ROLE_ADMIN' || role === 'ROLE_COMMERCIAL' || role === 'ROLE_CONTROLEUR'"
                    >Ajouter un pétrolier</v-btn
                  >
                </template>
                <v-stepper v-model="e1" :vertical="false" :alt-labels="true">
                  <template>
                    <v-stepper-header>
                      <v-stepper-step step="1">Profil</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="2">Adresse</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="3">Contact</v-stepper-step>
                      <v-divider></v-divider>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-form ref="formStep1" v-model="validStep1">
                          <v-text-field
                            v-model="defaultItem.societeNom"
                            label="Nom de la société *"
                            :rules="[v => !!v || '* Champs Requis']"
                            required
                          ></v-text-field>
                          <v-textarea
                            v-model="defaultItem.description"
                            name="Description"
                            label="Description"
                          ></v-textarea>
                        </v-form>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="dialog = false"
                            >Annuler</v-btn
                          >
                          <v-btn
                            color="#03498E" :disabled="!validStep1"
                            text
                            @click="nextStep(2)"
                            >Suivant</v-btn
                          >
                        </v-card-actions>
                      </v-stepper-content>
                      <v-stepper-content step="2">
                        <v-form ref="formStep2" v-model="validStep2">
                        <v-row justify="center">
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="defaultItem.adresse.adresse1"
                              label="Adresse *"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="defaultItem.adresse.adresse2"
                              label="Adresse Complémentaire"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="defaultItem.adresse.codePostal"
                              label="Code Postale *"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="defaultItem.adresse.ville"
                              label="Ville *"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-text-field
                              v-model="defaultItem.adresse.pays"
                              label="Pays *"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        </v-form>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="warning" text @click="nextStep(1)"
                            >Précèdent</v-btn
                          >
                          <v-btn color="#03498E" text @click="nextStep(3)" :disabled="!validStep2"
                            >Suivant</v-btn
                          >
                        </v-card-actions>
                      </v-stepper-content>
                      <v-stepper-content step="3">
                        <v-form ref="formStep3" v-model="validStep3">
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="defaultItem.contact.nom"
                                label="Nom *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="defaultItem.contact.prenom"
                                label="Prenom *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="defaultItem.contact.telephone1"
                                label="Téléphone"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="defaultItem.contact.telephone2"
                                label="Mobile"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="6" md="5">
                              <v-text-field
                                v-model="defaultItem.contact.email"
                                label="Adresse Email"
                                :rules="[v => !!v || '* Champs Requis',  v => /.+@.+\..+/.test(v) || '* E-mail doit être valide']"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="6" md="5">
                              <v-text-field
                                      label="Fonction"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="warning" text @click="nextStep(2)"
                                 :loading="loadBtn" :disabled="loadBtn"
                            >Précèdent</v-btn
                          >
                          <v-btn color="#03498E" text @click="save()" :disabled="!validStep3"
                                 :loading="loadBtn"
                            >Enregistrer</v-btn
                          >
                        </v-card-actions>
                      </v-stepper-content>
                    </v-stepper-items>
                  </template>
                </v-stepper>
              </v-dialog>
              <v-dialog v-model="dialogEdit" max-width="700px" persistent>
                <v-stepper v-model="e2" :vertical="false" :alt-labels="true">
                  <template>
                    <v-stepper-header>
                      <v-stepper-step step="1">Profil</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="2">Adresse</v-stepper-step>
                      <v-divider></v-divider>
                      <v-stepper-step step="3">Contact</v-stepper-step>
                      <v-divider></v-divider>
                    </v-stepper-header>
                    <v-stepper-items>
                      <v-stepper-content step="1">
                        <v-form ref="formStep1" v-model="validStep1">
                          <v-text-field
                            v-model="editedItem.societeNom"
                            label="Nom de la société * "
                            :rules="[v => !!v || '* Champs Requis']"
                            required
                          ></v-text-field>
                          <v-textarea
                            v-model="editedItem.description"
                            name="Description"
                            label="Description"
                          ></v-textarea>
                        </v-form>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="error" text @click="dialogEdit = false"
                            >Annuler</v-btn
                          >
                          <v-btn color="#03498E" text @click="nextStepEdit(2)" :disabled="!validStep1">Suivant</v-btn>
                        </v-card-actions>
                      </v-stepper-content>
                      <v-stepper-content step="2">
                        <v-form ref="formStep2" v-model="validStep2">
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.adresse.adresse1"
                                label="Adresse *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.adresse.adresse2"
                                label="Adresse Complémentaire"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.adresse.codePostal"
                                label="Code Postale *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.adresse.ville"
                                label="Ville *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.adresse.pays"
                                label="Pays *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-form>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="warning" text @click="nextStepEdit(1)"
                            >Précèdent</v-btn
                          >
                          <v-btn color="#03498E" text @click="nextStepEdit(3)" :disabled="!validStep2"
                            >Suivant</v-btn
                          >
                        </v-card-actions>
                      </v-stepper-content>
                      <v-stepper-content step="3">
                        <v-form ref="formStep3" v-model="validStep3">
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.contact.nom"
                                label="Nom *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.contact.prenom"
                                label="Prenom *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.contact.telephone1"
                                label="Téléphone"
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                v-model="editedItem.contact.telephone2"
                                label="Mobile"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-text-field
                            v-model="editedItem.contact.email"
                            label="Adresse Email *"
                            :rules="[v => !!v || '* Champs Requis',  v => /.+@.+\..+/.test(v) || '* E-mail doit être valide']"
                            required
                          ></v-text-field>
                        </v-form>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="warning" text @click="nextStepEdit(2)"
                                 :loading="loadBtn" :disabled="loadBtn"
                            >Précèdent</v-btn
                          >
                          <v-btn color="#03498E" text @click="editingItem()" :disabled="!validStep3"
                                 :loading="loadBtn"
                            >Enregistrer</v-btn
                          >
                        </v-card-actions>
                      </v-stepper-content>
                    </v-stepper-items>
                  </template>
                </v-stepper>
              </v-dialog>
              <v-dialog v-model="dialogUpdateStatus" max-width="290px">
                <v-card>
                  <v-card-title
                    class="headline"
                    v-show="updatedItem.status === 'ACTIVE'"
                    >Désactiver ce pétrolier ?</v-card-title
                  >
                  <v-card-title
                    class="headline"
                    v-show="updatedItem.status === 'INACTIVE'"
                    >Activer ce pétrolier ?</v-card-title
                  >
                  <v-card-text v-show="updatedItem.status === 'ACTIVE'"
                    >Êtes-vous sûr de vouloir désactiver ce pétrolier
                    ?</v-card-text
                  >
                  <v-card-text v-show="updatedItem.status === 'INACTIVE'"
                    >Êtes-vous sûr de vouloir activer ce pétrolier
                    ?</v-card-text
                  >
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      color="error"
                      text
                      @click="dialogUpdateStatus = false"
                      >Annuler</v-btn
                    >
                    <v-btn color="#03498E" text>Confirmer</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogDelete" max-width="290px">
                <v-card>
                  <v-card-title class="headline"
                    >Supprimer ce pétrolier ?</v-card-title
                  >
                  <v-card-text
                    >Êtes-vous sûr de vouloir supprimer ce pétrolier
                    ?</v-card-text
                  >
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="error" text @click="dialogDelete = false"
                      >Annuler</v-btn
                    >
                    <v-btn color="#03498E" text @click="deletingItem"
                      >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'" size="20" @click="editItem(item)">
              mdi-lead-pencil
            </v-icon>
            <v-icon
              v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
              size="20"
              @click="deleteItem(item)"
              color="red darken-2"
            >
              mdi-delete-outline
            </v-icon>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";

export default {
  name: "Petroliers",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      loadBtn: false,
      dialog: false,
      dialogEdit: false,
      dialogUpdateStatus: false,
      dialogDelete: false,
      sb: { status: false, text: "", color: "", y: "top" },
      e1: 1,
      e2: 1,
      steps: 3,
      validStep1: true,
      validStep2: true,
      validStep3: true,
      headers: [
        {
          text: "Société",
          align: "left",
          value: "societeNom"
        },
        { text: "Pays", value: "adresse.pays" },
        { text: "Active", value: "status" },
        { text: "Actions", value: "action", sortable: false }
      ],
      petroliers: [],
      editedIndex: -1,
      deletedIndex: -1,
      defaultIndex: -1,
      editedItem: {
        societeNom: "",
        description: "",
        contact: {
          email: "",
          nom: "",
          prenom: "",
          telephone1: "",
          telephone2: ""
        },
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: "",
          pays: "",
          ville: ""
        }
      },
      defaultItem: {
        societeNom: "",
        status: "ACTIVE",
        description: "",
        contact: {
          email: "",
          nom: "",
          prenom: "",
          telephone1: "",
          telephone2: ""
        },
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: "",
          pays: "",
          ville: ""
        }
      },
      deletedItem: {},
      updatedItem: {}
    };
  },
  methods: {
    initialize() {
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width" : "XMLHttpRequest"
        }
      };

      axios.get( host + "/petroliers/", auth).then(result => {
        this.petroliers = result.data;
      });
    },
    editItem(item) {
      this.editedIndex = this.petroliers.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogEdit = true;
    },
    editingItem() {
      this.loadBtn = true;
      axios({
        method: "PUT",
        url:  host + "/petroliers/" + this.editedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width" : "XMLHttpRequest"
        },
        data: this.editedItem
      })
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Pétrolier mis a jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.loadBtn = false;
          this.dialogEdit = false;
          this.e2 = 1;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          console.log("err : ", error);
        });
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:  host + "/petroliers/" + this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width" : "XMLHttpRequest"
        }
      })
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Pétrolier supprimé ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogDelete = false;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log("err : ", error);
        });
    },
    save() {
      this.loadBtn = true;
      axios({
        method: "POST",
        url:  host + "/petroliers/",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width" : "XMLHttpRequest"
        },
        data: this.defaultItem
      })
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Pétrolier ajouté ! ";
          this.sb.color = "success";
          this.initialize();
          this.loadBtn = false;
          this.dialog = false;
          this.e1 = 1;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.initialize();
          this.loadBtn = false;
          this.dialog = false;
          this.e1 = 1;
          console.log("err : ", error);
        });
    },
    updateStatus(item) {
      this.updatedItem = Object.assign({}, item);
      this.dialogUpdateStatus = true;
    },
    updatingStatus() {
      if (this.updatedItem.status === "ACTIVE") {
        this.updatedItem.status = "INACTIVE";
      } else {
        this.updatedItem.status = "ACTIVE";
      }

      axios({
        method: "PUT",
        url:  host + "/petroliers/" + this.updatedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width" : "XMLHttpRequest"
        },
        data: this.updatedItem
      })
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Pétrolier mis à jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogUpdateStatus = false;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.initialize();
          this.dialogUpdateStatus = false;
          console.log("err : ", error);
        });
    },
    nextStep(n) {
      this.e1 = n;
    },
    nextStepEdit(n) {
      this.e2 = n;
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else return "orange";
    }
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationRole", "hasAuthenticationToken"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.initialize();
  }
};
</script>

<style src="./Petroliers.scss" lang="scss" scoped />
