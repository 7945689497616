<template>
  <v-img
      :src="require('../../assets/bg.jpg')"
      style="width: 100%; height: 100%"
  >
    <div class="login-page" style="background-color: rgba(3,73,142, 0.8)">
      <b-container>
        <h5 class="logo" style="color:#03498E">
          <v-row justify="center">
            <v-col cols="12" sm="4"> </v-col>
            <v-img
                :src="require('../../assets/ocard1.png')"
                aspect-ratio="1.7"
                contain
                width="200"
                height="200"
            ></v-img>
            <v-col cols="12" sm="4"> </v-col>
          </v-row>
        </h5>
        <Widget
            class="mx-auto elevation-0"
            title="<h3 class='mt-0'>Activer mon espace</h3>"
            customHeader
        >
          <p class="text-muted mb-0 mt fs-sm"></p>
          <form class="mt" @submit.prevent="activeSpace">
            <v-alert
                border="right"
                colored-border
                type="error"
                elevation="2"
                v-if="errorMessage"
            >
              {{ errorMessageText }}
            </v-alert>
            <div class="form-group">
              <v-text-field
                  v-model="username"
                  type="text"
                  name="username"
                  label="carte"
              >
              </v-text-field>
              <v-text-field
                  v-model="password"
                  type="password"
                  name="password"
                  label="pin"
              >
              </v-text-field>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
              <div class="btn-toolbar">
                <v-btn class="ma-2" type="submit" outlined color="#F6852D"
                       :loading="loadBtn" :disabled="loadBtn"
                >Valider</v-btn
                >
              </div>
            </div>
            <div
                class="clearfix"
                style="display: flex;align-items: center;justify-content: center;"
            >
              <div class="btn-toolbar">
                <v-btn
                    class="ma-1"
                    color="gray"
                    outlined
                    @click="goToBack"
                >
                  Retour
                </v-btn>
              </div>
            </div>
            <div class="row no-gutters mt-3"></div>
          </form>
        </Widget>
      </b-container>
      <footer class="footer" style="color:#fff" href="/signIn">
        {{ new Date().getFullYear() }} &copy;
        <v-btn text href="/" style="color:#fff">ocard.olaenergy.re</v-btn>
      </footer>
    </div>
  </v-img>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import store from "@/store";
import router from "@/router";
import { host } from "@/utils/constants";
import { mapGetters } from "vuex";
import qs from "querystring";
import axios from "axios";

export default {
  components: { Widget },
  data() {
    return {
      username: "",
      password: "",
      errorMessage: false,
      errorMessageText: 'Une erreur est survenue ! Veuillez réessayer.',
      showPwd: false,
      loadBtn: false
    };
  },
  computed: {
    ...mapGetters("auth", ["hasAuthentication"])
  },
  created() {
    if (this.hasAuthentication) {
      router.push("/app/home");
    }
  },
  methods: {
    async activeSpace() {
      this.loadBtn = true;

      axios({
        method: "GET",
        url: host + "/cartes/check-card/" + this.username,
        baseUrl: host,
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      })
          .then(async result => {
            if (result.status === 200) {
              if (result.data.typePayement === 'PORTE_MONNAIE') {
                if(result.data.pinCode === this.password) {
                  this.loadBtn = false;
                  router.push({ name: 'new-space', params: { username: this.username, password: this.password, id: result.data.id } })
                } else {
                  this.loadBtn = false;
                  this.errorMessage = true;
                  this.errorMessageText = 'Erreur pin ! Veuillez réessayer.';
                }
              } else {
                this.loadBtn = false;
                this.errorMessage = true;
                this.errorMessageText = "Ce type de carte n'est pas autorisé.";
              }
            } else {
              this.loadBtn = false;
              this.errorMessage = true;
              this.errorMessageText = 'Une erreur est survenue ! Veuillez réessayer.';
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.loadBtn = false;
            this.errorMessage = true;
          });
    },
    goToBack() {
      router.push('/signIn');
    }
  }
};
</script>

<style src="./ActiveSpace.scss" lang="scss" scoped />
