<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white; margin-top: 4%">
      Gestion des <span class="fw-semi-bold">BO</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          :items-per-page="20"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-dialog
                hide-overlay
                persistent
                width="300"
                v-model="dialogLoad"
              >
                <v-card color="primary" dark style="padding: 15px">
                  <v-card-text>
                    Chargement ...
                    <v-progress-linear
                      indeterminate
                      color="white"
                      class="mb-0"
                    ></v-progress-linear>
                  </v-card-text>
                </v-card>
              </v-dialog>
              <v-dialog max-width="500px" v-model="dialogExport">
                <v-card>
                  <v-card-title class="headline"
                    >Veuillez renseigner les dates</v-card-title
                  >
                  <v-card-text>
                    <v-row justify="center">
                      <v-col cols="10" md="5">
                        <v-menu
                          ref="menuExportDebut"
                          v-model="menuExportDebut"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formatDateContrat"
                              label="Date Debut"
                              readonly
                              v-on="on"
                              clearable
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menuExportDebut = false"
                              >Annuler</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menuExportDebut.save(date)"
                              >Valider</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="10" md="5">
                        <v-menu
                          ref="menuExportFin"
                          v-model="menuExportFin"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="formatDateFin"
                              label="Date Fin"
                              readonly
                              v-on="on"
                              clearable
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="dateFin" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="menuExportFin = false"
                              >Annuler</v-btn
                            >
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.menuExportFin.save(dateFin)"
                              >Valider</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      @click="closeDialogExport"
                      color="error"
                      text
                      :loading="loadBtn"
                      :disabled="loadBtn"
                      >Annuler</v-btn
                    >
                    <v-btn
                      @click="getExport"
                      color="#03498E"
                      text
                      :loading="loadBtn"
                      :disabled="loadBtn || date === '' || dateFin === ''"
                      >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog max-width="500px" v-model="dialogSolde">
                <v-card>
                  <v-card-title class="headline"
                    >Veuillez sélectionner une période</v-card-title
                  >
                  <v-card-text>
                    <v-row justify="center">
                      <v-col class="d-flex" cols="12" sm="6">
                        <v-select
                          v-model="selectPeriode"
                          :items="periodes"
                          item-text="libelle"
                          return-object
                          label="Périodicité"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      @click="dialogSolde = false"
                      color="error"
                      text
                      :loading="loadBtn"
                      :disabled="loadBtn"
                      >Annuler</v-btn
                    >
                    <v-btn
                      @click="launchFile"
                      color="#03498E"
                      text
                      :loading="loadBtn"
                      :disabled="loadBtn || selectPeriode === ''"
                      >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog max-width="600px" v-model="dialogCartes">
                <v-card>
                  <v-card-title class="headline"
                    >Recharge Cartes KORBEY</v-card-title
                  >
                  <v-card-text>
                    <div
                      class="mt-12 subtitle-1 text-center"
                      v-if="lastFilename === ''"
                    >
                      Aucun fichier présent
                    </div>
                    <div class="mt-12 subtitle-1 text-center" v-else>
                      Fichier présent : {{ lastFilename }}
                    </div>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      @click="dialogCartes = false"
                      color="error"
                      text
                      :loading="loadBtn"
                      :disabled="loadBtn"
                      >Annuler</v-btn
                    >
                    <v-btn
                      @click="executeFile"
                      color="success"
                      text
                      :loading="loadBtn"
                      :disabled="loadBtn || lastFilename === ''"
                      >Exécuter ce fichier</v-btn
                    >
                    <v-btn
                      @click="showUploadFile"
                      color="#03498E"
                      text
                      :loading="loadBtn"
                      :disabled="loadBtn"
                      >Charger un autre fichier</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog max-width="600px" v-model="dialogUploadFile">
                <v-card>
                  <v-card-title class="headline"
                    >Mise à jour Fichier Cartes KORBEY</v-card-title
                  >
                  <v-card-text>
                    <v-file-input
                      label="Fichier"
                      show-size
                      clearable
                      v-model="chosenFile"
                      @click:clear="cleanFile"
                      :disabled="loadBtn"
                      accept=".csv"
                    ></v-file-input>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      @click="closeDialogUpload"
                      color="error"
                      text
                      :loading="loadBtn"
                      :disabled="loadBtn"
                      >Annuler</v-btn
                    >
                    <v-btn
                      @click="uploadingFile"
                      color="#03498E"
                      text
                      :loading="loadBtn"
                      :disabled="
                        loadBtn ||
                          chosenFile === null ||
                          chosenFile === undefined
                      "
                      >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog max-width="350px" v-model="dialogConfirm">
                <v-card>
                  <v-card-title class="headline"
                  >Confirmer l'initialisation ?</v-card-title
                  >
                  <v-card-text
                  >Êtes-vous sûr de vouloir initialiser les soldes ?</v-card-text
                  >
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn @click="dialogConfirm = false" color="error" text :loading="loadBtn" :disabled="loadBtn"
                    >Annuler</v-btn
                    >
                    <v-btn @click="_launchInitSeuil" color="#03498E" text :loading="loadBtn" :disabled="loadBtn"
                    >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog max-width="500px" v-model="dialogMail">
                <v-card>
                  <v-card-title class="subtitle-1" v-if="actionItem=== 'mailOneClient'"
                  >Veuillez renseigner un numéro client</v-card-title
                  >
                  <v-card-text v-if="actionItem=== 'mailOneClient'">
                    <v-row justify="center">
                      <v-col cols="10" md="6">
                        <v-text-field
                                label="Numéro Client"
                                v-model="numClientMail"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-title class="subtitle-1" v-if="actionItem=== 'mailOneGerant'"
                  >Veuillez renseigner le numéro de Station</v-card-title
                  >
                  <v-card-text v-if="actionItem=== 'mailOneGerant'">
                    <v-row justify="center">
                      <v-col cols="10" md="6">
                        <v-text-field
                                label="Numéro de Station"
                                v-model="numStationMail"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-title class="subtitle-1"
                  >Veuillez renseigner une période</v-card-title
                  >
                  <v-card-text>
                    <v-row justify="center">
                      <v-col cols="10" md="5">
                        <v-menu
                                ref="menuMail"
                                v-model="menuMail"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                                    v-model="formatMail"
                                    label="Période"
                                    readonly
                                    v-on="on"
                                    clearable
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="dateMail" no-title type="month" scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                    text
                                    color="primary"
                                    @click="menuMail = false"
                            >Annuler</v-btn
                            >
                            <v-btn
                                    text
                                    color="primary"
                                    @click="$refs.menuMail.save(dateMail)"
                            >Valider</v-btn
                            >
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                            @click="closeDialogMail"
                            color="error"
                            text
                            :loading="loadBtn"
                            :disabled="loadBtn"
                    >Annuler</v-btn
                    >
                    <v-btn
                            @click="getSendMail"
                            color="#03498E"
                            text
                            :loading="loadBtn"
                            :disabled="loadBtn || dateMail === ''"
                    >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog max-width="500px" v-model="dialogPinSecure">
                <v-card>
                  <v-card-title class="headline"
                  >Veuillez renseigner le numéro de Station</v-card-title
                  >
                  <v-card-text>
                    <v-row justify="center">
                      <v-col cols="10" md="6">
                        <v-text-field
                                label="Numéro de Station"
                                v-model="numStation"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row justify="center" v-if="pinSecure !== ''">
                      <v-col cols="10" md="6">
                        <v-text-field
                                label="Pin de Sécurité"
                                v-model="pinSecure"
                                readonly
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                            @click="closeDialogPinSecure"
                            color="error"
                            text
                            :loading="loadBtn"
                            :disabled="loadBtn"
                    >Annuler</v-btn
                    >
                    <v-btn
                            @click="getPinSecure"
                            color="#03498E"
                            text
                            :loading="loadBtn"
                            :disabled="loadBtn || numStation === ''"
                    >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog max-width="600px" v-model="dialogUID">
                <v-card>
                  <v-card-title class="headline"
                  >Import UID</v-card-title
                  >
                  <v-card-text>
                    <v-file-input
                        label="Fichier"
                        show-size
                        clearable
                        v-model="chosenFileUID"
                        @click:clear="cleanFileUID"
                        :disabled="loadBtn"
                        accept=".csv"
                    ></v-file-input>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                        @click="closeDialogUID"
                        color="error"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                    >Annuler</v-btn
                    >
                    <v-btn
                        @click="uploadingFileUID"
                        color="#03498E"
                        text
                        :loading="loadBtn"
                        :disabled="
                        loadBtn ||
                          chosenFileUID === null ||
                          chosenFileUID === undefined
                      "
                    >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  size="20"
                  color="green"
                  v-on="on"
                  @click="getAction(item)"
                  :disabled="loadDownload"
                  >mdi-play-circle-outline</v-icon
                >
              </template>
              <span>Exécuter</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "../../utils/constants";
import moment from "moment";

export default {
  name: "BO",
  components: { Widget },
  data() {
    return {
      token: null,
      role: null,
      entity: null,
      loadDownload: false,
      loadBtn: false,
      dialogExport: false,
      dialogSolde: false,
      dialogCartes: false,
      dialogLoad: false,
      dialogMail: false,
      dialogPinSecure: false,
      dialogUID: false,
      dialogUploadFile: false,
      dialogExecFile: false,
      menuExportDebut: false,
      menuExportFin: false,
      menuMail: false,
      dateMail: "",
      date: "",
      dateFin: "",
      numStation: "",
      numStationMail: "",
      numClientMail: "",
      pinSecure : "",
      sb: { status: false, text: "", color: "", y: "top" },
      headers: [
        { text: "Opération", value: "name" },
        { text: "Statut", value: "status" },
        { text: "Actions", value: "action", sortable: false }
      ],
      items: [
        {
          id: 1,
          name: "Fichier BO Carte",
          file: "BO_Cartes.csv",
          status: "ACTIVE",
          function: "download",
          path: "/cartes/export"
        },
        {
          id: 2,
          name: "Fichier BO Client",
          file: "BO_Clients.csv",
          status: "ACTIVE",
          function: "download",
          path: "/clients/export"
        },
        {
          id: 3,
          name: "Fichier BO Client Remises",
          file: "BO_Clients_remises.csv",
          status: "ACTIVE",
          function: "download",
          path: "/clients/export/remise"
        },
        {
          id: 4,
          name: "Fichier BO Demande",
          file: "BO_Demandes.csv",
          status: "ACTIVE",
          function: "download",
          path: "/demandes/export"
        },
        {
          id: 5,
          name: "Fichier BO Gérant",
          file: "BO_Gerants.csv",
          status: "ACTIVE",
          function: "download",
          path: "/gerants/export"
        },
        {
          id: 6,
          name: "Fichier BO Produit",
          file: "BO_Produits.csv",
          status: "ACTIVE",
          function: "download",
          path: "/produits/export"
        },
        {
          id: 7,
          name: "Fichier BO Produit Prix",
          file: "BO_Produits_prix.csv",
          status: "ACTIVE",
          function: "download",
          path: "/produits/export/prix-produit"
        },
        {
          id: 8,
          name: "Fichier BO Station",
          file: "BO_Stations.csv",
          status: "ACTIVE",
          function: "download",
          path: "/stations/export"
        },
        {
          id: 9,
          name: "Fichier BO Station Participations",
          file: "BO_Stations_participations.csv",
          status: "ACTIVE",
          function: "download",
          path: "/stations/export/participation"
        },
        {
          id: 10,
          name: "Fichier BO Transaction",
          file: "BO_Transactions.csv",
          status: "ACTIVE",
          function: "download",
          path: "/transactions/export"
        },
        {
          id: 11,
          name: "Mise à jour SOLDE",
          file: "solde",
          status: "ACTIVE",
          function: "cron",
          path: ""
        },
        {
          id: 12,
          name: "Recharge carte KORBEY",
          file: "cartes",
          status: "ACTIVE",
          function: "cron",
          path: ""
        },
        {
          id: 13,
          name: "Init SOLDE Client",
          file: "seuil",
          status: "ACTIVE",
          function: "cron",
          path: ""
        },
        {
          id: 14,
          name: "Envoyer des factures CLIENTS par mail",
          file: "mail",
          status: "ACTIVE",
          function: "cron",
          path: "mailAllClients"
        },
        {
          id: 15,
          name: "Envoyer une facture CLIENT par mail",
          file: "mail",
          status: "ACTIVE",
          function: "cron",
          path: "mailOneClient"
        },
        {
          id: 16,
          name: "Envoyer des factures GERANTS par mail",
          file: "mail",
          status: "ACTIVE",
          function: "cron",
          path: "mailAllGerants"
        },
        {
          id: 17,
          name: "Envoyer une facture GERANT par mail",
          file: "mail",
          status: "ACTIVE",
          function: "cron",
          path: "mailOneGerant"
        },
        {
          id: 18,
          name: "Code OFFLINE de sécurité station",
          file: "pin",
          status: "ACTIVE",
          function: "cron",
          path: ""
        },
        {
          id: 19,
          name: "Import UID",
          file: "uid",
          status: "ACTIVE",
          function: "cron",
          path: ""
        }
      ],
      periodes: [
        { name: "JOUR", libelle: "JOUR" },
        { name: "HEB", libelle: "HEBDO" },
        { name: "MOIS", libelle: "MOIS" },
        { name: "TRIM", libelle: "TRIMESTRE" },
        { name: "SEM", libelle: "SEMESTRE" },
        { name: "AN", libelle: "ANNUEL" }
      ],
      selectItem: {
        id: 1,
        name: "",
        file: "",
        function: "",
        path: ""
      },
      selectPeriode: { name: "", libelle: "" },
      lastFilename: "",
      chosenFile: null,
      chosenFileUID: null,
      dialogConfirm: false,
      actionItem: ""
    };
  },
  methods: {
    getAction(item) {
      if (item.function === "download") {
        if (item.path === "/transactions/export") {
          this.dialogExport = true;
        } else {
          this.downloadFile(item);
        }
      } else if (item.function === "cron") {
        this.selectItem = Object.assign({}, item);
        if (item.file === "solde") {
          this.dialogSolde = true;
        } else if (item.file === "cartes") {
          this._getLastFile();
        } else if (item.file === "seuil") {
          this.dialogConfirm = true;
        } else if (item.file === "mail") {
          this.actionItem = item.path;
          this.dialogMail = true;
        } else if (item.file === "pin") {
          this.dialogPinSecure = true;
        } else if (item.file === "uid") {
          this.dialogUID = true;
        }
      }
    },
    downloadFile(item) {
      this.loadDownload = true;
      this.dialogLoad = true;

      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        responseType: "blob"
      };

      axios
        .get( host + item.path, auth)
        .then(result => {
          var fileURL = window.URL.createObjectURL(new Blob([result.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", item.file);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loadDownload = false;
          this.dialogLoad = false;
        })
        .catch(err => {
          if (err.response.data) {
            this.sb.status = true;
            this.sb.text = "Aucun résultat trouvé ! ";
            this.sb.color = "warning";
            this.loadDownload = false;
            this.dialogLoad = false;
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.loadDownload = false;
            this.dialogLoad = false;
          }
        });
    },
    closeDialogExport() {
      this.dialogExport = false;
      this.menuExportDebut = false;
      this.menuExportFin = false;
      this.date = "";
      this.dateFin = "";
    },
    closeDialogMail() {
      this.dialogMail = false;
      this.menuMail = false;
      this.dateMail = "";
    },
    closeDialogPinSecure() {
      this.dialogPinSecure = false;
      this.numStation = "";
      this.pinSecure = "";
    },
    getExport() {
      this.loadBtn = true;

      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        responseType: "blob"
      };

      let dateDebut = moment(this.date)
        .locale("fr")
        .format("DD-MM-YYYY");
      let dateFin = moment(this.dateFin)
        .locale("fr")
        .add(1, "days")
        .format("DD-MM-YYYY");

      axios
        .get(

            host +
            "/transactions/export?start=" +
            dateDebut +
            "&end=" +
            dateFin,
          auth
        )
        .then(result => {
          var fileURL = window.URL.createObjectURL(new Blob([result.data]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "BO_transactions.csv");
          document.body.appendChild(fileLink);
          fileLink.click();
          this.loadBtn = false;
          this.dialogExport = false;
        })
        .catch(err => {
          console.error(err);
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          this.dialogExport = false;
        });
    },
    getSendMail() {
      this.loadBtn = true;
      let month = moment(this.dateMail).format("MM");
      let year = moment(this.dateMail).format("YY");
      let url= "";

      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      if(this.actionItem === "mailAllClients") {
        url = host + "/clients/facturation/"+year+"/"+month
      } else if(this.actionItem === "mailAllGerants") {
        url = host + "/stations/facturation/"+year+"/"+month
      } else if(this.actionItem === "mailOneGerant") {
        url = host + "/stations/facturation/"+this.numStationMail+"/"+year+"/"+month
      } else if(this.actionItem === "mailOneClient") {
        url = host + "/clients/facturation/"+this.numClientMail+"/"+year+"/"+month
      }

      axios.get(url, auth)
              .then(result => {
                if (result.status === 200) {
                  this.sb.status = true;
                  this.sb.text = "Email(s) envoyé(s)! ";
                  this.sb.color = "success";
                  this.dialogMail = false;
                  this.loadBtn = false;
                } else {
                  this.sb.status = true;
                  this.sb.text = "Une erreur est survenue ! ";
                  this.sb.color = "error";
                  this.dialogMail= false;
                  this.loadBtn = false;
                }
              })
              .catch(err => {
                console.error(err);
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue ! ";
                this.sb.color = "error";
                this.dialogMail = false;
                this.loadBtn = false;
              });
    },
    getPinSecure() {
      this.loadBtn = true;
      let station = parseInt(this.numStation);
      let date = parseInt(moment().format("MMDD"));
      let fResult = parseFloat(date) / parseFloat(station);
      let hex = this.convertStringToHex(fResult.toString());
      let lastSix = hex.substr(hex.length - 6);
      this.pinSecure = lastSix;
      this.loadBtn = false;

    },
    launchFile() {
      this.loadBtn = true;

      axios({
        method: "PUT",
        url:

          host +
          "/cartes/server/solde-periode/{periode}?periodicite=" +
          this.selectPeriode.name,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        }
      })
        .then(async result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Opération Réussie ! ";
            this.sb.color = "success";
            this.loadBtn = false;
            this.dialogSolde = false;
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.loadBtn = false;
            this.dialogSolde = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          console.log(error.response);
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          this.dialogSolde = false;
        });
    },
    _getLastFile() {
      this.loadDownload = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
        .get(

            host +
            "/cartes/download/last-file/update_solde",
          auth
        )
        .then(result => {
          this.loadDownload = false;
          if (result.status === 200) {
            this.lastFilename = result.data;
            this.dialogCartes = true;
          } else {
            this.dialogCartes = true;
          }
        })
        .catch(err => {
          console.log(err);
          this.lastFilename = "";
          this.dialogCartes = true;
        });
    },
    showUploadFile() {
      this.dialogCartes = false;
      this.dialogUploadFile = true;
    },
    closeDialogUpload() {
      this.chosenFile = null;
      this.dialogUploadFile = false;
    },
    closeDialogUID() {
      this.chosenFileUID = null;
      this.dialogUID = false;
    },
    cleanFile() {
      this.chosenFile = null;
    },
    cleanFileUID() {
      this.chosenFileUID = null;
    },
    uploadingFile() {
      if (this.chosenFile !== null || this.chosenFile !== undefined) {
        this.loadBtn = true;

        let formData = new FormData();
        formData.append("file", this.chosenFile);

        if (this.lastFilename !== "") {
          axios({
            method: "DELETE",
            url:

              host +
              "/cartes/download/delete/update_solde/" +
              this.lastFilename,
            headers: {
              Authorization: "Bearer " + this.token,
              "X-Requested-Width": "XMLHttpRequest"
            }
          })
            .then(result => {
              if (result.status === 200) {
                this.lastFilename = "";
                axios({
                  method: "POST",
                  url:

                    host +
                    "/cartes/upload/file/csv-solde/",
                  baseUrl: host,
                  headers: {
                    Authorization: "Bearer " + this.token,
                    "X-Requested-Width": "XMLHttpRequest",
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json"
                  },
                  data: formData
                })
                  .then(res => {
                    if (res.status === 200) {
                      this.sb.status = true;
                      this.sb.text = "Fichier déposé !";
                      this.sb.color = "success";
                      this.loadBtn = false;
                      this.dialogUploadFile = false;
                      this._getLastFile();
                    } else {
                      this.sb.status = true;
                      this.sb.text = "Une erreur est survenue !";
                      this.sb.color = "error";
                      this.loadBtn = false;
                      this.dialogUploadFile = false;
                    }
                  })
                  // eslint-disable-next-line no-unused-vars
                  .catch(error => {
                    this.sb.status = true;
                    this.sb.text = "Une erreur est survenue !";
                    this.sb.color = "error";
                    this.loadBtn = false;
                    this.dialogUploadFile = false;
                  });
              } else {
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue !";
                this.sb.color = "error";
                this.loadBtn = false;
                this.dialogUploadFile = false;
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              console.log(error.response);
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue !";
              this.sb.color = "error";
              this.loadBtn = false;
              this.dialogUploadFile = false;
            });
        } else {
          axios({
            method: "POST",
            url:

              host +
              "/cartes/upload/file/csv-solde/",
            baseUrl: host,
            headers: {
              Authorization: "Bearer " + this.token,
              "X-Requested-Width": "XMLHttpRequest",
              "Content-Type": "multipart/form-data",
              Accept: "application/json"
            },
            data: formData
          })
            .then(res => {
              if (res.status === 200) {
                this.sb.status = true;
                this.sb.text = "Fichier déposé !";
                this.sb.color = "success";
                this.loadBtn = false;
                this.dialogUploadFile = false;
              } else {
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue !";
                this.sb.color = "error";
                this.loadBtn = false;
                this.dialogUploadFile = false;
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue !";
              this.sb.color = "error";
              this.loadBtn = false;
              this.dialogUploadFile = false;
            });
        }
      }
    },
    uploadingFileUID() {
      if (this.chosenFileUID !== null || this.chosenFileUID !== undefined) {
        this.loadBtn = true;

        let formData = new FormData();
        formData.append("file", this.chosenFileUID);

        axios({
          method: "POST",
          url:

              host +
              "/demandes/uuid/upload/file",
          baseUrl: host,
          headers: {
            Authorization: "Bearer " + this.token,
            "X-Requested-Width": "XMLHttpRequest",
            "Content-Type": "multipart/form-data",
            Accept: "application/json"
          },
          data: formData
        })
            .then(res => {
              if (res.status === 200) {
                this.sb.status = true;
                this.sb.text = "Fichier déposé !";
                this.sb.color = "success";
                this.loadBtn = false;
                this.dialogUID = false;
              } else {
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue !";
                this.sb.color = "error";
                this.loadBtn = false;
                this.dialogUID = false;
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue !";
              this.sb.color = "error";
              this.loadBtn = false;
              this.dialogUID = false;
            });
      }
    },
    executeFile() {
      this.loadBtn = true;

      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
        .get(

            host +
            "/tickets/download/read/csv/5e4e279af3395461439a5072/" +
            this.lastFilename,
          auth
        )
        .then(result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Mise à jour réussie ! ";
            this.sb.color = "success";
            this.dialogCartes = false;
            this.loadBtn = false;
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.dialogCartes = false;
            this.loadBtn = false;
          }
        })
        .catch(err => {
          console.error(err);
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.dialogCartes = false;
          this.loadBtn = false;
        });
    },
    _launchInitSeuil() {
      this.loadDownload = true;
      this.loadBtn = true;

      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
        .get(
           host + "/clients/init-seuil",
          auth
        )
        .then(result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Mise à jour réussie ! ";
            this.sb.color = "success";
            this.dialogConfirm = false;
            this.loadBtn = false;
            this.loadDownload = false;
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.dialogConfirm = false;
            this.loadBtn = false;
            this.loadDownload = false;
          }
        })
        .catch(err => {
          console.error(err);
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.dialogConfirm = false;
          this.loadBtn = false;
          this.loadDownload = false;
        });
    },
    convertStringToHex(str){
      var arr1 = [];
      for (var n = 0, l = str.length; n < l; n ++)
      {
        var hex = Number(str.charCodeAt(n)).toString(16);
        arr1.push(hex);
      }
      return arr1.join('');
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ]),
    formatDateContrat() {
      return this.date
        ? moment(this.date)
            .locale("fr")
            .format("L")
        : "";
    },
    formatDateFin() {
      return this.dateFin
        ? moment(this.dateFin)
            .locale("fr")
            .format("L")
        : "";
    },
    formatMail() {
      return this.dateMail
              ? moment(this.dateMail)
                      .locale("fr")
                      .format("MM/YYYY")
              : "";
    }
  }
};
</script>

<style scoped></style>
