<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Stations</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="text-right">
      </div>
      <div class="table-responsive">
        <v-skeleton-loader :loading="loadData" type="table">
          <v-data-table
            :headers="headers"
            :items="stations"
            :loading="loadDataIn"
            sort-by="stationNom"
            class="elevation-1"
          >
            <template v-slot:item.status="{ item }">
              <v-btn
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
                class="ma-2"
                tile
                outlined
                :color="getColor(item.status)"
                @click="updateStatus(item)"
              >
                <span v-if="item.status === 'ACTIVE'"
                  >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else
                  >Inactive <v-icon color="orange">mdi-close</v-icon></span
                >
              </v-btn>
              <v-btn
                v-else
                class="ma-2"
                tile
                outlined
                :color="getColor(item.status)"
              >
                <span v-if="item.status === 'ACTIVE'"
                  >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else
                  >Inactive <v-icon color="orange">mdi-close</v-icon></span
                >
              </v-btn>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div class="flex-grow-1"></div>
                <v-dialog v-model="dialog" max-width="850px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ma-2"
                      outlined
                      color="#03498E"
                      v-on="on"
                      v-if="
                        role === 'ROLE_ADMIN' ||
                          role === 'ROLE_CONTROLEUR' ||
                          role === 'ROLE_COMMERCIAL'
                      "
                      >Ajouter une station</v-btn
                    >
                  </template>
                  <v-stepper v-model="e1" :vertical="false" :alt-labels="true">
                    <template>
                      <v-stepper-header>
                        <v-stepper-step step="1">Entreprise</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">Adresse</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">Représentant</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4"
                          >Données Complémentaires</v-stepper-step
                        >
                        <v-divider></v-divider>
                        <v-stepper-step step="5">Participation</v-stepper-step>
                      </v-stepper-header>
                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <v-form ref="formStep1" v-model="validStep1">
                            <v-container fluid justify="center">
                              <v-row justify="center">
                                <v-col cols="12" md="10">
                                  <v-text-field
                                    v-model="defaultItem.numStation"
                                    label="Numéro de Station *"
                                    :rules="[v => !!v || '* Champs Requis']"
                                    required
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                <v-col cols="12" md="10">
                                  <v-text-field
                                    v-model="defaultItem.nom"
                                    label="Nom *"
                                    :rules="[v => !!v || '* Champs Requis']"
                                    required
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                <v-col cols="12" md="10">
                                  <v-select
                                    v-model="selectedGerant"
                                    :items="gerants"
                                    item-text="contact.nom"
                                    label="Gérant"
                                    return-object
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" text @click="dialog = false"
                              >Annuler</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStep(2)"
                              :disabled="!validStep1"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                          <v-form ref="formStep2" v-model="validStep2">
                            <v-row justify="center">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="defaultItem.adresse.adresse1"
                                  label="Adresse *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="defaultItem.adresse.adresse2"
                                  label="Adresse Complémentaire"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="defaultItem.adresse.codePostal"
                                  label="Code Postal *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="defaultItem.adresse.ville"
                                  label="Ville * "
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  v-model="defaultItem.adresse.pays"
                                  label="Pays * "
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStep(1)"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStep(3)"
                              :disabled="!validStep2"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                          <v-form ref="formStep3" v-model="validStep3">
                            <v-row justify="center">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="defaultItem.contact.nom"
                                  label="Nom du Responsable *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="defaultItem.contact.prenom"
                                  label="Prénom du Responsable *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="defaultItem.contact.telephone1"
                                  label="Téléphone"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="defaultItem.contact.telephone2"
                                  label="Mobile"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-col cols="12" md="12">
                              <v-text-field
                                v-model="defaultItem.contact.email"
                                label="Adresse Email *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStep(2)"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStep(4)"
                              :disabled="!validStep3"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                          <v-form>
                            <v-row justify="center">
                              <v-col cols="12" md="12">
                                <v-text-field
                                  v-model="defaultItem.nbrPompe"
                                  label="Nombre de pompe"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-select
                                  v-model="selectedProduits"
                                  :items="produits"
                                  item-text="libelle"
                                  label="Produits"
                                  return-object
                                  multiple
                                  :rules="[v => !!v || 'Un produit est requis']"
                                >
                                  <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggle">
                                      <v-list-item-action>
                                        <v-icon
                                          :color="
                                            selectedProduits.length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                                          >{{ icon }}</v-icon
                                        >
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >Tout sélectionner</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStep(3)"
                              >Précèdent</v-btn
                            >
                            <v-btn color="#03498E" text @click="nextStep(5)"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="5">
                          <v-form>
                            <v-card
                              class="mx-auto"
                              max-width="500"
                              elevation="0"
                            >
                              <v-list>
                                <v-list-item-group color="primary">
                                  <v-list-item
                                    v-for="(item, i) in participationProduits"
                                    :key="i"
                                  >
                                    <v-list-item-action>
                                      <v-checkbox
                                        v-model="item.active"
                                      ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item.produit.libelle
                                      }}</v-list-item-title>
                                      <v-list-item-subtitle>{{
                                        item.produit.code
                                      }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                      <v-text-field
                                        v-model="item.reverser"
                                        label="Reverser"
                                        :disabled="!item.active"
                                      ></v-text-field>
                                    </v-list-item-icon>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-card>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="warning"
                              text
                              @click="nextStep(4)"
                              :loading="loadBtn"
                              :disabled="loadBtn"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="save"
                              :loading="loadBtn"
                              :disabled="loadBtn"
                              >Enregistrer</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                      </v-stepper-items>
                    </template>
                  </v-stepper>
                </v-dialog>
                <v-dialog v-model="dialogEdit" max-width="850px" persistent>
                  <v-stepper v-model="e2" :vertical="false" :alt-labels="true">
                    <template>
                      <v-stepper-header>
                        <v-stepper-step step="1">Entreprise</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">Adresse</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">Représentant</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4"
                          >Données Complémentaire</v-stepper-step
                        >
                        <v-divider></v-divider>
                        <v-stepper-step step="5">Participation</v-stepper-step>
                      </v-stepper-header>
                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <v-form ref="formStep1" v-model="validStep1">
                            <v-container fluid justify="center">
                              <v-row justify="center">
                                <v-col cols="12" md="10">
                                  <v-text-field
                                    v-model="editedItem.numStation"
                                    label="Numéro de Station *"
                                    :rules="[v => !!v || '* Champs Requis']"
                                    required
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                <v-col cols="12" md="10">
                                  <v-text-field
                                    label="Nom *"
                                    v-model="editedItem.nom"
                                    :rules="[v => !!v || '* Champs Requis']"
                                    required
                                  >
                                  </v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                <v-col cols="12" md="10">
                                  <v-select
                                    v-model="editedItem.gerant"
                                    :items="gerants"
                                    item-text="contact.nom"
                                    label="Gérant"
                                    return-object
                                  ></v-select>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="error"
                              text
                              @click="dialogEdit = false"
                              >Annuler</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStepEdit(2)"
                              :disabled="!validStep1"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                          <v-form ref="formStep2" v-model="validStep2">
                            <v-row justify="center">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.adresse.adresse1"
                                  label="Adresse *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.adresse.adresse2"
                                  label="Adresse Complémentaire"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="editedItem.adresse.codePostal"
                                  label="Code Postal *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  v-model="editedItem.adresse.ville"
                                  label="Ville *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  v-model="editedItem.adresse.pays"
                                  label="Pays *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="warning"
                              text
                              @click="nextStepEdit(1)"
                              :disabled="!validStep2"
                              >Précèdent</v-btn
                            >
                            <v-btn color="#03498E" text @click="nextStepEdit(3)"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                          <v-form ref="formStep3" v-model="validStep3">
                            <v-row justify="center">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.contact.nom"
                                  label="Nom du Responsable *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.contact.prenom"
                                  label="Prénom du Responsable *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.contact.telephone1"
                                  label="Téléphone"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="6">
                                <v-text-field
                                  v-model="editedItem.contact.telephone2"
                                  label="Mobile"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-col cols="12" md="12">
                              <v-text-field
                                v-model="editedItem.contact.email"
                                label="Adresse Email"
                                :rules="[
                                  v => !!v || '* Champs Requis',
                                  v =>
                                    /.+@.+\..+/.test(v) ||
                                    '* E-mail doit être valide'
                                ]"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStepEdit(2)"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStepEdit(4)"
                              :disabled="!validStep3"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                          <form>
                            <v-row justify="center">
                              <v-col cols="12" md="12">
                                <v-text-field
                                  v-model="editedItem.nbrPompe"
                                  label="Nombre de pompe"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-select
                                  v-model="editedItem.produits"
                                  :items="produits"
                                  item-text="libelle"
                                  label="Produits"
                                  return-object
                                  multiple
                                >
                                  <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggle">
                                      <v-list-item-action>
                                        <v-icon
                                          :color="
                                            selectedProduits.length > 0
                                              ? 'indigo darken-4'
                                              : ''
                                          "
                                          >{{ icon }}</v-icon
                                        >
                                      </v-list-item-action>
                                      <v-list-item-content>
                                        <v-list-item-title
                                          >Tout sélectionner</v-list-item-title
                                        >
                                      </v-list-item-content>
                                    </v-list-item>
                                    <v-divider class="mt-2"></v-divider>
                                  </template>
                                </v-select>
                              </v-col>
                            </v-row>
                          </form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStepEdit(3)"
                              >Précèdent</v-btn
                            >
                            <v-btn color="#03498E" text @click="nextStepEdit(5)"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="5">
                          <v-form>
                            <v-card
                              class="mx-auto"
                              max-width="500"
                              elevation="0"
                            >
                              <v-list>
                                <v-list-item-group color="primary">
                                  <v-list-item
                                    v-for="(item, i) in participationProduits"
                                    :key="i"
                                  >
                                    <v-list-item-action>
                                      <v-checkbox
                                        v-model="item.active"
                                      ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item.produit.libelle
                                      }}</v-list-item-title>
                                      <v-list-item-subtitle>{{
                                        item.produit.code
                                      }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                      <v-text-field
                                        v-model="item.reverser"
                                        label="Reverser"
                                        :disabled="!item.active"
                                      ></v-text-field>
                                    </v-list-item-icon>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-card>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="warning"
                              text
                              @click="nextStepEdit(4)"
                              :loading="loadBtn"
                              :disabled="loadBtn"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="editingItem"
                              v-if="
                                role === 'ROLE_ADMIN' ||
                                  role === 'ROLE_CONTROLEUR' ||
                                  role === 'ROLE_COMMERCIAL'
                              "
                              :loading="loadBtn"
                              :disabled="loadBtn"
                              >Enregistrer</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="dialogEdit = false"
                              v-if="
                                role !== 'ROLE_ADMIN' ||
                                  role === 'ROLE_CONTROLEUR' ||
                                  role === 'ROLE_COMMERCIAL'
                              "
                              >Fermer</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                      </v-stepper-items>
                    </template>
                  </v-stepper>
                </v-dialog>
                <v-dialog v-model="dialogUpdateStatus" max-width="290px">
                  <v-card>
                    <v-card-title
                      class="headline"
                      v-show="updatedItem.status === 'ACTIVE'"
                      >Désactiver cette station ?</v-card-title
                    >
                    <v-card-title
                      class="headline"
                      v-show="updatedItem.status === 'INACTIVE'"
                      >Activer cette station ?</v-card-title
                    >
                    <v-card-text v-show="updatedItem.status === 'ACTIVE'"
                      >Êtes-vous sûr de vouloir désactiver cette station
                      ?</v-card-text
                    >
                    <v-card-text v-show="updatedItem.status === 'INACTIVE'"
                      >Êtes-vous sûr de vouloir activer cette station
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="error"
                        text
                        @click="dialogUpdateStatus = false"
                        >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="updatingStatus"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="290px">
                  <v-card>
                    <v-card-title class="headline"
                      >Supprimer cette station ?</v-card-title
                    >
                    <v-card-text
                      >Êtes-vous sûr de vouloir supprimer cette station
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogDelete = false"
                        >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="deletingItem"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon v-if="authorize" size="20" @click="editItem(item)">
                mdi-lead-pencil
              </v-icon>
              <v-icon
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
                size="20"
                @click="deleteItem(item)"
                color="red darken-2"
              >
                mdi-delete-outline
              </v-icon>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import * as _ from "lodash";

export default {
  name: "Stations",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      loadData: true,
      loadDataIn: true,
      loadBtn: false,
      validStep1: true,
      validStep2: true,
      validStep3: true,
      validStep4: true,
      validStep5: true,
      dialog: false,
      dialogEdit: false,
      dialogUpdateStatus: false,
      dialogDelete: false,
      e1: 1,
      e2: 1,
      steps: 3,
      status: false,
      sb: { status: false, text: "", color: "", y: "top" },
      headers: [],
      stations: [],
      editedIndex: -1,
      editedItem: {
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: "",
          latitude: "",
          longgitude: "",
          pays: "",
          ville: ""
        },
        contact: {
          email: "",
          nom: "",
          prenom: "",
          telephone1: "",
          telephone2: ""
        },
        participation: [
          {
            produit: {
              code: "",
              libelle: ""
            },
            reverser: 0,
            vente: 0
          }
        ],
        nbrPompe: 0,
        numStation: "",
        nom: "",
        gerant: {
          debutAcivite: "",
          finAcivite: "",
          id: "",
          nom: "",
          status: ""
        },
        picture: "",
        produits: [],
        servicesActif: [],
        status: ""
      },
      defaultItem: {
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: "",
          latitude: "",
          longgitude: "",
          pays: "REUNION",
          ville: ""
        },
        contact: {
          email: "",
          nom: "",
          prenom: "",
          telephone1: "",
          telephone2: ""
        },
        nbrPompe: 0,
        numStation: "",
        nom: "",
        participation: [
          {
            produit: {
              code: "",
              libelle: ""
            },
            reverser: 0,
            vente: 0
          }
        ],
        picture: "",
        produits: [
          {
            categorie: "",
            code: "",
            codeTVA: 0,
            id: "",
            libelle: "",
            puht: "",
            status: "ACTIVE"
          }
        ],
        servicesActif: ["FIDELITE"],
        status: "ACTIVE"
      },
      gerants: [],
      produits: [],
      selectedProduits: [],
      selectedGerant: [],
      deletedItem: {},
      updatedItem: {},
      participationProduits: []
    };
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllProduit) {
          this.selectedProduits = [];
        } else {
          this.selectedProduits = this.produits.slice();
        }
      });
    },
    initialize() {
      this.loadDataIn = true;
      this.loadData = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      if (
        this.role === "ROLE_MANAGER" ||
        this.role === "ROLE_STATION" ||
        this.role === "ROLE_GERANT"
      ) {
        this.headers = [
          { text: "Ville", value: "adresse.ville" },
          { text: "Téléphone", value: "contact.telephone1" },
          { text: "Active", value: "status" },
          { text: "Actions", value: "action", sortable: false }
        ];
        axios
          .get(

              host +
              "/stations/" +
              this.entity.id,
            auth
          )
          .then(result => {
            this.stations.push(result.data);
            this.loadDataIn = false;
            this.loadData = false;
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.loadDataIn = false;
            this.loadData = false;
          });
      } else {
        this.headers = [
          {
            text: "Entreprise",
            align: "left",
            sortable: false,
            value: "nom"
          },
          { text: "Numéro", value: "numStation" },
          { text: "Ville", value: "adresse.ville" },
          { text: "Code Gérant", value: "gerant.codeGerant" },
          { text: "Téléphone", value: "contact.telephone1" },
          { text: "Active", value: "status" },
          { text: "Actions", value: "action", sortable: false }
        ];
        axios
          .get(
             host + "/stations/",
            auth
          )
          .then(result => {
            this.stations = result.data;
            this.loadDataIn = false;
            this.loadData = false;
          });
      }

      axios
        .get( host + "/gerants/", auth)
        .then(result => {
          this.gerants = result.data;
        });

      axios
        .get( host + "/produits/", auth)
        .then(result => {
          this.produits = _.sortBy(result.data, "code");
          this.initParticipationProduits();
        });
    },
    initParticipationProduits() {
      this.participationProduits = [];
      this.produits.forEach(element => {
        this.participationProduits.push({
          produit: element,
          active: false,
          reverser: 0,
          vente: 0
        });
      });
    },
    editItem(item) {
      this.editedIndex = this.produits.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.initParticipationProduits();
      this.participationProduits.forEach(element => {
        this.editedItem.participation.forEach(el => {
          if (el.produit.code === element.produit.code) {
            element.active = true;
            element.reverser = el.reverser;
            element.vente = el.vente;
          }
        });
      });
      this.dialogEdit = true;
    },
    editingItem() {
      this.loadBtn = true;
      let arrpp = [];
      this.participationProduits.forEach(element => {
        if (element.active) {
          arrpp.push(element);
        }
      });
      this.editedItem.participation = arrpp;

      axios({
        method: "PUT",
        url:

          host +
          "/stations/" +
          this.editedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        data: this.editedItem
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Station mise a jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.loadBtn = false;
          this.dialogEdit = false;
          this.e2 = 1;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
        });
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:

          host +
          "/stations/" +
          this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Station supprimée ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogDelete = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
        });
    },
    close() {
      this.dialogEdit = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      this.loadBtn = true;
      let arrpp = [];
      if (this.status === true) {
        this.defaultItem.status = "ACTIVE";
      } else {
        this.defaultItem.status = "INACTIVE";
      }
      delete this.selectedGerant.contact;
      this.defaultItem.adresse.codePostal = parseInt(
        this.defaultItem.adresse.codePostal
      );
      this.defaultItem.nbrPompe = parseInt(this.defaultItem.nbrPompe);
      this.defaultItem.gerant = this.selectedGerant;
      this.participationProduits.forEach(element => {
        if (element.active) {
          arrpp.push(element);
        }
      });
      this.defaultItem.participation = arrpp;

      let item = Object.assign({}, this.defaultItem);
      item.produits = [];
      this.selectedProduits.forEach(p => {
        let id = { id: p.id };
        item.produits.push(id);
      });

      axios({
        method: "POST",
        url:  host + "/stations/",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        data: JSON.stringify(item)
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Station ajoutée ! ";
          this.sb.color = "success";
          this.initialize();
          this.loadBtn = false;
          this.dialog = false;
          this.e1 = 1;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          this.e1 = 1;
        });
    },
    updateStatus(item) {
      this.updatedItem = Object.assign({}, item);
      this.dialogUpdateStatus = true;
    },
    updatingStatus() {
      if (this.updatedItem.status === "ACTIVE") {
        this.updatedItem.status = "INACTIVE";
      } else {
        this.updatedItem.status = "ACTIVE";
      }

      axios({
        method: "PUT",
        url:

          host +
          "/stations/" +
          this.updatedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        data: this.updatedItem
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Station mise à jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogUpdateStatus = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
        });
    },
    nextStep(n) {
      this.e1 = n;
    },
    nextStepEdit(n) {
      this.e2 = n;
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else return "orange";
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    likesAllProduit() {
      return this.selectedProduits.length === this.produits.length;
    },
    icon() {
      if (this.likesAllProduit) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    authorize() {
      if (
        this.role === "ROLE_ADMIN" ||
        this.role === "ROLE_MANAGER" ||
        this.role === "ROLE_CONTROLEUR" ||
        this.role === "ROLE_COMMERCIAL"
      ) {
        return true;
      }
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.initialize();
  }
};
</script>

<style src="./Stations.scss" lang="scss" scoped />
