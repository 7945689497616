<template>
  <v-app id="app">
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App"
};
</script>

<style src="./styles/theme.scss" lang="scss" />
