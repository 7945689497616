<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Recharges</span> de Cartes
      Pré-Payée
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="text-right">
        <v-btn
          :loading="loadDownload"
          :disabled="loadDownload"
          class="ma-2 white--text"
          color="primary"
          @click="_getLastFile"
        >
          Déposer Fichier Recharge <v-icon dark right>mdi-cloud-upload</v-icon>
        </v-btn>
      </div>
      <div class="table-responsive">
        <v-skeleton-loader
          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR'"
          :loading="loadData"
          type="table"
        >
          <v-data-table
            :headers="headers"
            :items="cartes"
            :search="search"
            sort-by="nom"
            class="elevation-0"
            :loading="loadDataIn"
          >
            <template v-slot:item.status="{ item }">
              <v-btn class="ma-2" tile outlined :color="getColor(item.status)">
                <span v-if="item.status === 'ACTIVE'"
                  >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else-if="item.status === 'PREACTIVE'"
                  >Pré Active
                  <v-icon color="orange">mdi-exclamation</v-icon></span
                >
                <span v-else-if="item.status === 'DEMDEBLK'"
                  >Demande <v-icon color="orange">mdi-exclamation</v-icon></span
                >
                <span v-else-if="item.status === 'ERRPIN'"
                  >Erreur Pin<v-icon color="orange"
                    >mdi-exclamation</v-icon
                  ></span
                >
                <span v-else
                  >Inactive <v-icon color="red">mdi-close</v-icon></span
                >
              </v-btn>
            </template>
            <template v-slot:item.solde="{ item }">
              <span>{{ formatFloat(item.solde) }} €</span>
            </template>
            <template v-slot:item.hors_parc="{ item }">
              <span v-if="item.hors_parc === true">Hors Parc</span>
              <span v-else-if="item.vehicule !== null">{{
                item.vehicule.immatriculation
              }}</span>
            </template>
            <template v-slot:item.createdDate="{ item }">
              {{ item.createdDate | formatUnix }}
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-row justify="center" class="mt-6">
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="search"
                      label="Recherche par N° Carte, Client ou Immatriculation"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-dialog v-model="dialogRecharge" max-width="500px" persistent>
                  <v-card>
                    <v-card-title class="headline">Recharge Carte</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" sm="10">
                            <v-text-field
                              v-model="montant"
                              label="Montant *"
                              suffix="€"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="error"
                        text
                        @click="dialogRecharge = false"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        color="#03498E"
                        text
                        @click="rechargingCarte"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Valider</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="600px" v-model="dialogUpload" persistent>
                  <v-card>
                    <v-card-title class="headline"
                      >Déposer Fichier Cartes KORBEY</v-card-title
                    >
                    <v-card-text>
                      <v-file-input
                        label="Fichier"
                        show-size
                        clearable
                        v-model="chosenFile"
                        @click:clear="cleanFile"
                        :disabled="loadBtn"
                        accept=".csv"
                      ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        @click="closeDialogUpload"
                        color="error"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        @click="uploadingFile"
                        color="#03498E"
                        text
                        :loading="loadBtn"
                        :disabled="
                          loadBtn ||
                            chosenFile === null ||
                            chosenFile === undefined
                        "
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top v-if="role === 'ROLE_ADMIN'">
                <template v-slot:activator="{ on }">
                  <v-icon size="20" v-on="on" @click="rechargeCarte(item)"
                    >mdi-cash-100</v-icon
                  >
                </template>
                <span>Recharger</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
        <v-skeleton-loader
          v-if="role === 'ROLE_CLIENT'"
          :loading="loadData"
          type="table"
        >
          <v-data-table
            :headers="headersClient"
            :items="cartes"
            :search="search"
            sort-by="nom"
            class="elevation-0"
            :loading="loadDataIn"
          >
            <template v-slot:item.status="{ item }">
              <v-btn class="ma-2" tile outlined :color="getColor(item.status)">
                <span v-if="item.status === 'ACTIVE'"
                  >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else-if="item.status === 'PREACTIVE'"
                  >Pré Active
                  <v-icon color="orange">mdi-exclamation</v-icon></span
                >
                <span v-else-if="item.status === 'DEMDEBLK'"
                  >Demande <v-icon color="orange">mdi-exclamation</v-icon></span
                >
                <span v-else-if="item.status === 'ERRPIN'"
                  >Erreur Pin<v-icon color="orange"
                    >mdi-exclamation</v-icon
                  ></span
                >
                <span v-else
                  >Inactive <v-icon color="red">mdi-close</v-icon></span
                >
              </v-btn>
            </template>
            <template v-slot:item.hors_parc="{ item }">
              <span v-if="item.hors_parc === true">Hors Parc</span>
              <span v-else-if="item.vehicule !== null">{{
                item.vehicule.immatriculation
              }}</span>
            </template>
            <template v-slot:item.createdDate="{ item }">
              {{ item.createdDate | formatUnix }}
            </template>
            <template v-slot:item.solde="{ item }">
              <span>{{ formatFloat(item.solde) }} €</span>
            </template>
            <template v-slot:item.limit.montant="{ item }">
              <span>{{ formatFloat(item.limit.montant) }} €</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-row justify="center" class="mt-6">
                  <v-col cols="12" md="5">
                    <v-text-field
                      v-model="search"
                      label="Recherche par N° Carte ou Immatriculation"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-dialog v-model="dialogRecharge" max-width="500px" persistent>
                  <v-card>
                    <v-card-title class="headline">Recharge Carte</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" sm="10">
                            <v-text-field
                              v-model="montant"
                              label="Montant *"
                              suffix="€"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="error"
                        text
                        @click="dialogRecharge = false"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        color="#03498E"
                        text
                        @click="rechargingCarte"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Valider</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="600px" v-model="dialogUpload" persistent>
                  <v-card>
                    <v-card-title class="headline"
                      >Déposer Fichier Cartes KORBEY</v-card-title
                    >
                    <v-card-text>
                      <v-file-input
                        label="Fichier"
                        show-size
                        clearable
                        v-model="chosenFile"
                        @click:clear="cleanFile"
                        :disabled="loadBtn"
                        accept=".csv"
                      ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        @click="closeDialogUpload"
                        color="error"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        @click="uploadingFile"
                        color="#03498E"
                        text
                        :loading="loadBtn"
                        :disabled="
                          loadBtn ||
                            chosenFile === null ||
                            chosenFile === undefined
                        "
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon size="20" v-on="on" @click="rechargeCarte(item)"
                    >mdi-cash-100</v-icon
                  >
                </template>
                <span>Recharger</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";
import "moment/locale/fr";
import * as _ from "lodash";
import { mask } from "vue-the-mask";
import { NativeEventSource, EventSourcePolyfill } from "event-source-polyfill";

global.EventSource = NativeEventSource || EventSourcePolyfill;
let evtSource = false;

export default {
  name: "RechargeCartes",
  components: { Widget },
  directives: {
    mask
  },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      loadBtn: false,
      loadDownload: false,
      loadData: true,
      loadDataIn: true,
      loadCSV: false,
      dialog: false,
      dialogRecharge: false,
      dialogUpload: false,
      montant: null,
      sb: { status: false, text: "", color: "", y: "top" },
      cartesFilterValue: null,
      search: "",
      headers: [
        {
          text: "N° Carte",
          align: "left",
          value: "serialNumber"
        },
        { text: "N° Client", value: "client.entreprise.contratNumero" },
        { text: "Libelle", value: "libelle" },
        {
          text: "Enseigne Client",
          value: "client.entreprise.enseigne"
        },
        { text: "Date Création", value: "createdDate", filterable: false },
        { text: "Solde", value: "solde" },
        { text: "Type", value: "typePayement", filter: this.cartesFilter },
        { text: "Immat / HP", value: "hors_parc" },
        { text: "Statut", value: "status", filterable: false },
        { text: "Actions", value: "action", sortable: false, filterable: false }
      ],
      headersClient: [
        {
          text: "N° Carte",
          align: "left",
          value: "serialNumber"
        },
        { text: "Date Création", value: "createdDate", filterable: false },
        { text: "Immat / HP", value: "hors_parc" },
        { text: "Libelle", value: "libelle" },
        { text: "Type", value: "typePayement", filter: this.cartesFilter },
        { text: "Solde", value: "solde" },
        { text: "Plafond", value: "limit.montant" },
        { text: "Pincode", value: "pinCode" },
        { text: "Statut", value: "status", filterable: false },
        { text: "Actions", value: "action", sortable: false, filterable: false }
      ],
      cartes: [],
      initCartes: [],
      editedItem: {
        createdDate: "",
        status: null,
        serialNumber: "",
        libelle: "",
        dematerialiser: true,
        hors_parc: true,
        typePayement: "",
        client: {
          id: "",
          nom: "",
          prenom: "",
          entreprise: {
            enseigne: "",
            email: null,
            activite: "",
            siret: "",
            numeroRcs: 0,
            contratNumero: "",
            contratDate: "",
            contratExpire: true
          }
        },
        produits: [
          {
            id: "",
            code: "",
            libelle: "",
            status: "ACTIVE"
          }
        ],
        restriction: {
          heure_debut: "",
          heure_fin: "",
          restrictionJour: ""
        },
        limit: {
          frequenceJour: 0,
          montant: 0,
          montantJour: 0,
          periodicite: "",
          volume: 0
        },
        uuid: "",
        vehicule: {
          immatriculation: "",
          km: ""
        },
        blacklist: []
      },
      updatedItem: {},
      switchKm: false,
      produits: [],
      displayProduits: [],
      participationProduits: [],
      selectedProduits: [],
      periodes: ["JOUR", "HEB", "MOIS", "TRIM", "SEM", "AN"],
      selectedPeriodes: [],
      status: null,
      stations: [],
      cartesCSV: [],
      chosenFile: null,
      lastFilename: ""
    };
  },
  methods: {
    initialize() {
      this.loadData = true;
      this.loadDataIn = true;
      this.cartes= [];
      let url = "";

      if (this.role === "ROLE_ADMIN" || this.role === "ROLE_CONTROLEUR") {
        url = host + "/cartes/client/5e4edf8c0fed5b08ace6e9e7";
      } else if (this.role === "ROLE_CLIENT") {
        url = host + "/cartes/client/" + this.entity.id;
      }

      evtSource = new EventSourcePolyfill(url, {
        headers: {
          Authorization: "Bearer" + this.token
        }
      });

      var that = this;

      evtSource.addEventListener("message", function(e) {
        let carte = JSON.parse(e.data);
        //console.log(carte);
        that.loadData = false;
        that.loadDataIn = false;
        that.cartes.push(carte);
      });

      evtSource.addEventListener(
        "error",
        function(event) {
          switch (event.target.readyState) {
            case EventSource.CONNECTING:
              evtSource.close();
              console.log("Reconnecting...");
              break;

            case EventSource.CLOSED:
              console.log("Connection failed, will not reconnect");
              break;
          }
        },
        false
      );

      evtSource.addEventListener(
        "close",
        // eslint-disable-next-line no-unused-vars
        function(e) {
          console.log("finish");
          evtSource.close();
        },
        false
      );
    },
    rechargeCarte(item) {
      this.editedIndex = this.cartes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogRecharge = true;
    },
    rechargingCarte() {
      this.loadBtn = true;
      let item = Object.assign({}, this.editedItem);

      let montant = parseFloat(this.montant.replace(",", "."));
      let ticket = {
        carte: { id: item.id },
        transactions: [{ montant: montant }],
        station: { id: "5e4e279af3395461439a5072" },
        typeTicket: "RECHARGE"
      };

      axios({
        method: "POST",
        url:
          host +
          "/tickets/charge/5e4e279af3395461439a5072",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: ticket
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Carte rechargée ! ";
            this.sb.color = "success";
            this.initialize();
            this.montant="";
            this.loadBtn = false;
            this.dialogRecharge = false;
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.initialize();
            this.montant="";
            this.loadBtn = false;
            this.dialogRecharge = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.initialize();
          this.montant="";
          this.loadBtn = false;
          this.dialogRecharge = false;
        });
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else if (active === "PREACTIVE" || active === "DEMDEBLK") return "orange";
      else return "red";
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllProduit) {
          this.editedItem.produits = [];
        } else {
          let arr = this.produits.slice();
          this.editedItem.produits = _.sortBy(arr, "code");
        }
      });
    },
    cartesFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.cartesFilterValue) {
        return true;
      }
      // Check if the current loop value (The calories value)
      // equals to the selected value at the <v-select>.
      return value === this.cartesFilterValue;
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    cancel() {},
    open() {},
    close() {},
    _getLastFile() {
      this.loadDownload = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
        .get(

            host +
            "/cartes/download/last-file/update_solde",
          auth
        )
        .then(result => {
          this.loadDownload = false;
          if (result.status === 200) {
            this.lastFilename = result.data;
            this.dialogUpload = true;
          } else {
            this.dialogUpload = true;
          }
        })
        .catch(err => {
          console.log(err);
          this.lastFilename = "";
          this.dialogUpload = true;
        });
    },
    closeDialogUpload() {
      this.chosenFile = null;
      this.dialogUpload = false;
    },
    cleanFile() {
      this.chosenFile = null;
    },
    uploadingFile() {
      if (this.chosenFile !== null || this.chosenFile !== undefined) {
        this.loadBtn = true;

        let formData = new FormData();
        formData.append("file", this.chosenFile);

        if (this.lastFilename !== "") {
          axios({
            method: "DELETE",
            url:

              host +
              "/cartes/download/delete/update_solde/" +
              this.lastFilename,
            headers: {
              Authorization: "Bearer " + this.token,
              "X-Requested-Width": "XMLHttpRequest"
            }
          })
            .then(result => {
              if (result.status === 200) {
                this.lastFilename = "";
                axios({
                  method: "POST",
                  url:

                    host +
                    "/cartes/upload/file/csv-solde/",
                  baseUrl: host,
                  headers: {
                    Authorization: "Bearer " + this.token,
                    "X-Requested-Width": "XMLHttpRequest",
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json"
                  },
                  data: formData
                })
                  .then(res => {
                    if (res.status === 200) {
                      this.sb.status = true;
                      this.sb.text = "Fichier déposé !";
                      this.sb.color = "success";
                      this.loadBtn = false;
                      this.dialogUpload = false;
                    } else {
                      this.sb.status = true;
                      this.sb.text = "Une erreur est survenue !";
                      this.sb.color = "error";
                      this.loadBtn = false;
                      this.dialogUpload = false;
                    }
                  })
                  // eslint-disable-next-line no-unused-vars
                  .catch(error => {
                    this.sb.status = true;
                    this.sb.text = "Une erreur est survenue !";
                    this.sb.color = "error";
                    this.loadBtn = false;
                    this.dialogUpload = false;
                  });
              } else {
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue !";
                this.sb.color = "error";
                this.loadBtn = false;
                this.dialogUpload = false;
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue !";
              this.sb.color = "error";
              this.loadBtn = false;
              this.dialogUpload = false;
            });
        } else {
          axios({
            method: "POST",
            url:

              host +
              "/cartes/upload/file/csv-solde/",
            baseUrl: host,
            headers: {
              Authorization: "Bearer " + this.token,
              "X-Requested-Width": "XMLHttpRequest",
              "Content-Type": "multipart/form-data",
              Accept: "application/json"
            },
            data: formData
          })
            .then(res => {
              if (res.status === 200) {
                this.sb.status = true;
                this.sb.text = "Fichier déposé !";
                this.sb.color = "success";
                this.loadBtn = false;
                this.dialogUpload = false;
              } else {
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue !";
                this.sb.color = "error";
                this.loadBtn = false;
                this.dialogUpload = false;
              }
            })
            // eslint-disable-next-line no-unused-vars
            .catch(error => {
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue !";
              this.sb.color = "error";
              this.loadBtn = false;
              this.dialogUpload = false;
            });
        }
      }
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    likesAllProduit() {
      return this.editedItem.produits.length === this.produits.length;
    },
    icon() {
      if (this.likesAllProduit) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
          .locale("fr")
          .format("L");

        return date;
      }
    }
  },
  mounted() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    let url = "";

    if (this.role === "ROLE_ADMIN" || this.role === "ROLE_CONTROLEUR") {
      url = host + "/cartes/client/5e4edf8c0fed5b08ace6e9e7";
    } else if (this.role === "ROLE_CLIENT") {
      url = host + "/cartes/client/" + this.entity.id;
    }

    evtSource = new EventSourcePolyfill(url, {
      headers: {
        Authorization: "Bearer" + this.token
      }
    });

    var that = this;

    evtSource.addEventListener("message", function(e) {
      let carte = JSON.parse(e.data);
      //console.log(carte);
      that.loadData = false;
      that.loadDataIn = false;
      that.cartes.push(carte);
    });

    evtSource.addEventListener(
      "error",
      function(event) {
        switch (event.target.readyState) {
          case EventSource.CONNECTING:
            evtSource.close();
            console.log("Reconnecting...");
            break;

          case EventSource.CLOSED:
            console.log("Connection failed, will not reconnect");
            break;
        }
      },
      false
    );

    evtSource.addEventListener(
      "close",
      // eslint-disable-next-line no-unused-vars
      function(e) {
        console.log("finish");
        evtSource.close();
      },
      false
    );
  },
  beforeDestroy() {
    evtSource.close();
  }
};
</script>

<style src="./RechargeCartes.scss" lang="scss" scoped />
