import Vue from "vue";
import Vuetify from "vuetify/lib";
import fr from "vuetify/lib/locale/fr";
import "@fortawesome/fontawesome-free/css/all.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#03498E",
        secondary: "#F6852D",
        tertiary: "#495057",
        accent: "#82B1FF",
        error: "#f55a4e",
        info: "#00d3ee",
        success: "#5cb860",
        warning: "#ffa21a"
      }
    }
  },
  icons: {
    iconfont: "fa"
  },
  lang: {
    locales: { fr },
    current: "fr"
  }
});
