<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="tables-basic">
        <h3 class="page-title" style="color:white; margin-top: 4%">
            Gestion des <span class="fw-semi-bold">Télécharger PDF</span>
        </h3>
        <Widget class="mx-auto elevation-0">
            <div class="table-responsive">
                <v-data-table :headers="headers" :items="items" class="elevation-1">
                    <template v-slot:item.action="{ item }">
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-icon size="20" color="green" v-on="on"
                                >mdi-download</v-icon
                                >
                            </template>
                            <span>Télécharger</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:no-data> </template>
                </v-data-table>
            </div>
        </Widget>
    </div>
</template>

<script>
    export default {
        name: "DownloadFactures",
        data() {
            return {
                headers: [
                    { text: "Titre", value: "title" },
                    { text: "Actions", value: "action", sortable: false }
                ],
                items: []
            };
        },
        methods: {
        }
    };
</script>

<style scoped></style>
