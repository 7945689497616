import Vue from "vue";
import Router from "vue-router";

import Login from "@/pages/Login/Login";
import ForgotPassword from "@/pages/ForgotPassword/ForgotPassword";
import Layout from "@/components/Layout/Layout";
import Petroliers from "@/pages/Petroliers/Petroliers";
import Stations from "@/pages/Stations/Stations";
import Gerants from "@/pages/Gerants/Gerants";
import Produits from "@/pages/Produits/Produits";
import Terminaux from "@/pages/Terminaux/Terminaux";
import Vehicules from "@/pages/Vehicules/Vehicules";
import Collaborateurs from "@/pages/Collaborateurs/Collaborateurs";
import Dashboard from "@/pages/Dashboard/Dashboard";
import Cartes from "@/pages/Cartes/Cartes";
import RechargeCarte from "@/pages/RechargeCarte/RechargeCartes";
import Clients from "@/pages/Clients/Clients";
import Transactions from "@/pages/Transactions/Transactions";
import DebloqueCarte from "@/pages/ManageCarte/DebloqueCarte";
import DemandesCarte from "@/pages/ManageCarte/DemandesCarte";
import DemandeCartePM from "@/pages/DemandeCartePM/DemandeCartePM";
import HistoriqueDemandeCartePM from "@/pages/HistoriqueDemandeCartePM/HistoriqueDemandeCartePM";
import ValidationsCarte from "@/pages/ManageCarte/ValidationsCarte";
import FabricationsCarte from "@/pages/ManageCarte/FabricationsCarte";
import DownloadPDF from "@/pages/DownloadPDF/DownloadPDF";
import DownloadFactures from "@/pages/DownloadPDF/DownloadFactures";
import BO from "@/pages/BO/BO";
import Receipt from "@/pages/Receipt/Receipt";

import store from "@/store";
import moment from "moment";
import ActiveSpace from "@/pages/ActiveSpace/ActiveSpace";
import NewSpace from "@/pages/NewSpace/NewSpace";
import NewSpacePM from "@/pages/NewSpacePM/NewSpacePM";
import RechargePM from "@/pages/RechargePM/RechargePM";

Vue.use(Router);

const routes = [
  {
    path: "/signIn",
    name: "signIn",
    component: Login,
    meta: { auth: false }
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: { auth: false }
  },
  {
    path: "/active-space",
    name: "active-space",
    component: ActiveSpace,
    meta: { auth: false }
  },
  {
    path: "/new-space",
    name: "new-space",
    component: NewSpace,
    meta: { auth: false }
  },
  {
    path: "/new-spacePM",
    name: "new-spacePM",
    component: NewSpacePM,
    meta: { auth: false }
  },
  {
    path: "/app",
    name: "Layout",
    component: Layout,
    meta: { auth: true },
    children: [
      {
        path: "home",
        name: "Home",
        component: Dashboard,
        meta: { auth: true }
      },
      {
        path: "petroliers",
        name: "Petroliers",
        component: Petroliers,
        meta: { auth: true }
      },
      {
        path: "stations",
        name: "Stations",
        component: Stations,
        meta: { auth: true }
      },
      {
        path: "gerants",
        name: "Gerants",
        component: Gerants,
        meta: { auth: true }
      },
      {
        path: "produits",
        name: "Produits",
        component: Produits,
        meta: { auth: true }
      },
      {
        path: "clients",
        name: "Clients",
        component: Clients,
        meta: { auth: true }
      },
      {
        path: "terminaux",
        name: "Terminaux",
        component: Terminaux,
        meta: { auth: true }
      },
      {
        path: "collaborateurs",
        name: "Collaborateurs",
        component: Collaborateurs,
        meta: { auth: true }
      },
      {
        path: "vehicules",
        name: "Vehicules",
        component: Vehicules,
        meta: { auth: true }
      },
      {
        path: "cartes",
        name: "Cartes",
        component: Cartes,
        meta: { auth: true }
      },
      {
        path: "transactions",
        name: "Transactions",
        component: Transactions,
        meta: { auth: true }
      },
      {
        path: "debloque",
        name: "DebloqueCarte",
        component: DebloqueCarte,
        meta: { auth: true }
      },
      {
        path: "demandes",
        name: "DemandesCarte",
        component: DemandesCarte,
        meta: { auth: true }
      },
      {
        path: "demandes-cartepm",
        name: "DemandeCartePM",
        component: DemandeCartePM,
        meta: { auth: true }
      },
      {
        path: "historique-demandes-cartepm",
        name: "HistoriqueDemandeCartePM",
        component: HistoriqueDemandeCartePM,
        meta: { auth: true }
      },
      {
        path: "recharge",
        name: "RechargeCarte",
        component: RechargeCarte,
        meta: { auth: true }
      },
      {
        path: "recharge-pm",
        name: "RechargePM",
        component: RechargePM,
        meta: { auth: true }
      },
      {
        path: "receipt",
        name: "Receipt",
        component: Receipt,
        meta: { auth: true }
      },
      {
        path: "validation",
        name: "ValidationsCarte",
        component: ValidationsCarte,
        meta: { auth: true }
      },
      {
        path: "fabrication",
        name: "FabricationsCarte",
        component: FabricationsCarte,
        meta: { auth: true }
      },
      {
        path: "download",
        name: "DownloadPDF",
        component: DownloadPDF,
        meta: { auth: true }
      },
      {
        path: "downloadFacture",
        name: "DownloadFactures",
        component: DownloadFactures,
        meta: { auth: true }
      },
      {
        path: "bo",
        name: "BO",
        component: BO,
        meta: { auth: true }
      }
    ]
  },
  {
    path: "/",
    redirect: "/signIn"
  }
];

const router = new Router({ mode: "history", routes });

router.beforeEach(async (to, from, next) => {
  // Use the page"s router title to name the page
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }

  // is there a meta and auth attribute?
  if (to.meta && to.meta.auth !== undefined) {
    // if the page requires auth
    if (to.meta.auth) {
      // and we are authenticated?
      if (store.getters["auth/isAuthenticated"]) {
        next(); // route normally
        return;
      }
      // otherwise off to the sign in page
      router.push("/signIn");
      return;
    }
    // otherwise are we already authenticated?
    if (store.getters["auth/isAuthenticated"]) {
      var dateExpire = moment(store.getters["auth/hasAuthenticationExpireDate"])
        .add(parseInt(store.getters["auth/hasAuthenticationExpireDate"]), "s")
        .format();
      if (moment().isAfter(dateExpire)) {
        await store.dispatch("auth/signOut");
        router.push("/signIn");
      } else {
        router.push("/app/home");
      }

      return;
    }
    next(); // route normally
    return;
  }
  next(); // route normally
  return;
});

export default router;
