<template>
  <nav
      :class="{ sidebar: true, sidebarStatic, sidebarOpened }"
      @mouseenter="sidebarMouseEnter"
      @mouseleave="sidebarMouseLeave"
  >
    <header class="logo"></header>
    <header class="logo"></header>
    <ul class="nav">
      <NavLink
          header="Accueil"
          link="/app/home"
          iconName="home"
          index="home"
          isHeader
      />
      <NavLink
          header="Pétrolier"
          link="/app/petroliers"
          iconName="store-alt"
          index="petroliers"
          isHeader
          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
      />
      <NavLink
          header="Gérant"
          link="/app/gerants"
          iconName="user-tie"
          index="gerants"
          isHeader
          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_STATION' || role === 'ROLE_GERANT' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
      />
      <NavLink
          header="Station"
          link="/app/stations"
          iconName="gas-pump"
          index="stations"
          isHeader
          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_MANAGER' || role === 'ROLE_STATION' || role === 'ROLE_GERANT' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
      />
      <NavLink
          header="Produit"
          link="/app/produits"
          iconName="tint"
          index="produits"
          isHeader
          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
      />
      <NavLink
          header="Client"
          link="/app/clients"
          iconName="users"
          index="clients"
          isHeader
          v-if="role === 'ROLE_ADMIN' || (role === 'ROLE_CLIENT' && !PM) || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
      />
      <NavLink
          header="Carte"
          link="/app/cartes"
          iconName="money-check"
          index="cartes"
          isHeader
          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CLIENT' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
      />
      <NavLink
          header="Transaction"
          link="/app/transactions"
          iconName="list"
          index="transactions"
          isHeader
      />
      <v-divider :inset="false" vertical dark></v-divider>
      <!--<NavLink
              header="Débloquer des Cartes"
              link="/app/debloque"
              iconName="unlock-alt"
              index="debloque"
              isHeader
              v-if="role === 'ROLE_CLIENT'"
      />-->
      <NavLink
          header="Recharge"
          link="/app/recharge-pm"
          iconName="euro"
          index="recharge-pm"
          isHeader
          v-if="role === 'ROLE_CLIENT' && PM"
      />
      <v-divider :inset="false" vertical dark></v-divider>
      <NavLink
          header="Recharge PRE PAYEE"
          link="/app/recharge"
          iconName="euro"
          index="recharge"
          isHeader
          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || entity.prenom === 'DK01613'"
      />
      <v-divider :inset="false" vertical dark></v-divider>
      <NavLink
          header="Historique recharge"
          link="/app/receipt"
          iconName="file"
          index="receipt"
          isHeader
          v-if="(role === 'ROLE_CLIENT' && PM) || role === 'ROLE_ADMIN'"
      />
      <v-divider :inset="false" vertical dark></v-divider>
      <NavLink
          header="Demande de Carte"
          link="/app/demandes"
          iconName="file-upload"
          index="demandes"
          isHeader
          v-if="role === 'ROLE_ADMIN' || (role === 'ROLE_CLIENT' && !PM) || role === 'ROLE_COMMERCIAL'"
      />
      <NavLink
          header="Demande My O'card"
          link="/app/demandes-cartepm"
          iconName="file-upload"
          index="demandes-cartepm"
          isHeader
          v-if="(role === 'ROLE_CLIENT' && PM)"
      />
      <NavLink
          header="Historique Demande My O'card"
          link="/app/historique-demandes-cartepm"
          iconName="list"
          index="historique-demandes-cartepm"
          isHeader
          v-if="(role === 'ROLE_CLIENT' && PM)"
      />
      <NavLink
          header="Validation de Carte"
          link="/app/validation"
          iconName="file-export"
          index="validation"
          isHeader
          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR'"
      />
      <NavLink
          header="Fabrication de Carte"
          link="/app/fabrication"
          iconName="file-signature"
          index="fabrication"
          isHeader
          v-if="role === 'ROLE_ADMIN'"
      />

      <!-- <NavLink
               header="Téléchargement"
               link="/app/download"
               iconName="file-download"
               isHeader
       />-->
      <!--      <NavLink
                    header="Notes de credit"
                    link="/app/download"
                    iconName="file-download"
                    isHeader
                    v-if="role === 'ROLE_STATION' || role === 'ROLE_GERANT'"
            />-->

      <NavLink
          header="Téléchargement"
          link="/app/download"
          iconName="file-download"
          isHeader
          v-if="(role === 'ROLE_CLIENT' && !PM) || role === 'ROLE_STATION' || role === 'ROLE_GERANT'"
      />
      <NavLink
          header="BACK OFFICE"
          link="/app/bo"
          iconName="file-archive"
          isHeader
          v-if="role === 'ROLE_ADMIN'"
      />
    </ul>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import isScreen from "@/core/screenHelper";
import NavLink from "./NavLink/NavLink";
import axios from "axios";
import {host} from "@/utils/constants";

export default {
  name: "Sidebar",
  components: { NavLink },
  data() {
    return {
      role: null,
      entity: {prenom:""},
      entityPM: {},
      PM: false,
      user: null,
      token: null
    };
  },
  methods: {
    ...mapActions("layout", ["changeSidebarActive", "switchSidebar"]),
    setActiveByRoute() {
      const paths = this.$route.fullPath.split("/");
      paths.pop();
      this.changeSidebarActive(paths.join("/"));
    },
    sidebarMouseEnter() {
      if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
        this.switchSidebar(false);
        this.setActiveByRoute();
      }
    },
    sidebarMouseLeave() {
      if (!this.sidebarStatic && (isScreen("lg") || isScreen("xl"))) {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }
    },
    _getItem(){
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
          .get(
              host + "/clients/" + this.entity.id,
              auth
          )
          .then(result => {
            this.entity = result.data;
            if (result.data.account.username.startsWith('PM') || result.data.account.username.startsWith('MO')) {
              this.PM = true;
              this._getCarte(result.data);
            } else {
              this._checkInfoClient();
            }
          });
    },
    _getCarte(item) {
      let sn = item.account.username.substring(2);
      axios({
        method: "GET",
        url: host + "/cartes/sn/" + sn,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "Content-Type": "application/json",
          Accept: "application/json"
        },
      })
          .then(result => {
            if (result.status === 200) {
              this.entityPM = result.data;
            } else {
            }
          })
          .catch(error => {
            console.log(error);
          });
    },
  },
  created() {
    this.setActiveByRoute();
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    if(this.role === "ROLE_CLIENT" || this.role === "ROLE_STATION" || this.role === "ROLE_GERANT" || this.role === "ROLE_MANAGER" ){
      this.entity = this.hasAuthenticationEntity;
      if(this.role === "ROLE_CLIENT") {
        this._getItem();
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationRole", "hasAuthenticationEntity", "hasAuthenticationToken"]),
    ...mapState("layout", {
      sidebarStatic: state => state.sidebarStatic,
      sidebarOpened: state => !state.sidebarClose,
      activeItem: state => state.sidebarActiveElement
    })
  }
};
</script>

<!-- Sidebar styles should be scoped -->
<style src="./Sidebar.scss" lang="scss" scoped />
