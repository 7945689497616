<template>
  <div :class="{ root: true, sidebarClose, sidebarStatic }">
    <Sidebar />
    <div class="wrap" style="background: transparent">
      <v-img :src="require('../../assets/bg2.jpg')">
        <Header style="background: transparent" />
        <v-touch
          class="content"
          style="background: transparent"
          @swipeleft="handleSwipe"
          :swipe-options="{ direction: 'horizontal', threshold: 100 }"
        >
          <router-view />
          <footer
            class="contentFooter"
            style="color:white; text-align: center; width: 100%;position: absolute; bottom: 0;"
          >
            {{ new Date().getFullYear() }} — &copy; ocard.olaenergy.re
          </footer>
        </v-touch>
      </v-img>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import store from "@/store";
import Sidebar from "@/components/Sidebar/Sidebar";
import Header from "@/components/Header/Header";

import "./Layout.scss";

export default {
  name: "Layout",
  components: { Sidebar, Header },
  methods: {
    ...mapActions("layout", [
      "switchSidebar",
      "handleSwipe",
      "changeSidebarActive"
    ])
  },
  computed: {
    ...mapState("layout", {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic
    })
  },
  created() {
    const staticSidebar = JSON.parse(localStorage.getItem("sidebarStatic"));

    if (staticSidebar) {
      store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      setTimeout(() => {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }, 2500);
    }
  }
};
</script>

<style src="./Layout.scss" lang="scss" />
