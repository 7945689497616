<template>
  <b-navbar class="header d-print-none">
    <v-img
      :src="require('../../assets/logoImg.png')"
      max-width="100"
      max-height="100"
      style="margin-top:50px; margin-left:1%"
    />
    <v-img
      :src="require('../../assets/logoText.png')"
      max-width="100"
      max-height="100"
      style="margin-top:50px; margin-left:35%"
    />

    <span
      v-if="role === 'ROLE_GERANT'"
      class="subtitle-1"
      style="margin-top:6%; color: #A6A6A6; margin-left: 30%"
      >Station OLA</span
    >
    <span
      v-else-if="role === 'ROLE_STATION'"
      class="subtitle-1"
      style="margin-top:6%; color: #A6A6A6; margin-left: 30%"
      >Station {{ entity.nom }}</span
    >
    <span
      v-else-if="role === 'ROLE_CONTROLEUR'"
      class="subtitle-1"
      style="margin-top:6%; color: #A6A6A6; margin-left: 30%"
      >OLA CONTROLEUR</span
    >
    <span
      v-else-if="role === 'ROLE_COMMERCIAL'"
      class="subtitle-1"
      style="margin-top:6%; color: #A6A6A6; margin-left: 30%"
      >OLA COMMERCIAL</span
    >
    <span
      v-else
      class="headline"
      style="margin-top:6%; color: #A6A6A6; margin-left: 30%"
    ></span>

    <b-nav class="ml-auto">
      <b-nav-item-dropdown
        class="settingsDropdown d-sm-down-none"
        no-caret
        right
      >
        <template slot="button-content">
          <v-icon size="20" color="white">mdi-settings</v-icon>
        </template>
        <b-dropdown-item-button>
          <v-icon size="20" color="gray darken-2"
            >mdi-account-circle-outline</v-icon
          >
          {{ username }}
        </b-dropdown-item-button>
        <b-dropdown-divider />
        <b-dropdown-item-button @click="logout">
          <v-icon size="20" color="gray darken-2">mdi-login</v-icon> Se
          déconnecter
        </b-dropdown-item-button>
      </b-nav-item-dropdown>
    </b-nav>
  </b-navbar>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import { host } from "@/utils/constants";

export default {
  name: "Header",
  data() {
    return {
      role: null,
      username: null,
      entity: null,
      token: null
    };
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationUsername",
      "hasAuthenticationEntity",
      "hasAuthenticationToken"
    ]),
    ...mapState("layout", {
      sidebarClose: state => state.sidebarClose,
      sidebarStatic: state => state.sidebarStatic
    })
  },
  methods: {
    ...mapActions("layout", [
      "toggleSidebar",
      "toggleChat",
      "switchSidebar",
      "changeSidebarActive"
    ]),
    async logout() {
      await store.dispatch("auth/signOut");
      router.push("/signIn");
    },
    checkToken() {
     const auth = {
        headers: {
          Authorization: "Basic " + btoa("browser:1234"),
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      };

      axios
        .get(
            host +
            "/uaa/oauth/check_token?token=" +
            this.token,
          auth
        )
        .then(result => {
          if (result.status !== 200) {
            this.logout();
          }
        })
        .catch(err => {
          console.error(err);
          this.logout();
        });
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.username = this.hasAuthenticationUsername;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.checkToken();
  }
};
</script>

<style src="./Header.scss" lang="scss" scoped />
