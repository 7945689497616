import moment from "moment";

// initial state
const state = {
  role: null,
  entity: null,
  username: null,
  token: null,
  expires_in: null,
  expires_date: null,
  isAuthenticated: false,
  authenticationStatus: null
};

const getters = {
  authenticatedUser: state => state.user,
  isAuthenticated: state => state.isAuthenticated,
  authenticationStatus: state => {
    return state.authenticationStatus
      ? state.authenticationStatus
      : { variant: "secondary" };
  },
  hasAuthenticationStatus: state => {
    return !!state.authenticationStatus;
  },
  hasAuthenticationRole: state => {
    return state.role;
  },
  hasAuthenticationToken: state => {
    return state.token;
  },
  hasAuthenticationUsername: state => {
    return state.username;
  },
  hasAuthenticationEntity: state => {
    return state.entity;
  },
  hasAuthenticationExpireIn: state => {
    return state.expires_in;
  },
  hasAuthenticationExpireDate: state => {
    return state.expires_date;
  },
  hasAuthentication: state => {
    return state.isAuthenticated;
  }
};

const mutations = {
  setAuthenticationError(state, err) {
    state.authenticationStatus = {
      state: "failed",
      message: err.message,
      variant: "danger"
    };
  },
  clearAuthenticationStatus: state => {
    state.authenticationStatus = null;
  },
  setUserAuthenticated(state, params) {
    state.token = params.token;
    state.expires_in = params.expires_in;
    state.expires_date = moment().format();
    state.isAuthenticated = true;
    state.role = params.role;
    state.entity = params.entity;
    state.username = params.username;
  },
  clearAuthentication(state) {
    state.user = null;
    state.isAuthenticated = false;
    state.role = null;
    state.token = null;
    state.entity = null;
    state.username = null;
    state.expires_in = null;
    state.expires_date = null;
  }
};

const actions = {
  clearAuthenticationStatus: context => {
    context.commit("clearAuthenticationStatus", null);
  },
  signIn: async (context, params) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      //console.log(params);
      context.commit("setUserAuthenticated", params);
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
    }
  },
  signOut: async context => {
    context.commit("auth/clearAuthentication", null, { root: true });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
