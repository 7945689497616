<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white; margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Gérants</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-skeleton-loader :loading="loadData" type="table">
          <v-data-table
            :headers="headers"
            :items="gerants"
            sort-by="contact.nom"
            class="elevation-0"
            :loading="loadDataIn"
          >
            <template v-slot:item.status="{ item }">
              <v-btn
                class="ma-2"
                tile
                outlined
                :color="getColor(item.status)"
                @click="updateStatus(item)"
              >
                <span v-if="item.status === 'ACTIVE'"
                  >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else
                  >Inactive <v-icon color="orange">mdi-close</v-icon></span
                >
              </v-btn>
            </template>
            <template v-slot:item.debutAcivite="{ item }">
              {{ item.debutAcivite | formatUnix }}
            </template>
            <template v-slot:item.finAcivite="{ item }">
              <span v-if="item.finAcivite">{{
                item.finAcivite | formatUnix
              }}</span>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div class="flex-grow-1"></div>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ma-2"
                      outlined
                      color="#03498E"
                      v-on="on"
                      v-if="
                        role === 'ROLE_ADMIN' ||
                          role === 'ROLE_CONTROLEUR' ||
                          role === 'ROLE_COMMERCIAL'
                      "
                      >Ajouter un gérant</v-btn
                    >
                  </template>
                  <v-stepper v-model="e1" :vertical="false" :alt-labels="true">
                    <template>
                      <v-stepper-header>
                        <v-stepper-step step="1">Profil</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">Coordonnées</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">Banque</v-stepper-step>
                      </v-stepper-header>
                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <v-form ref="formStep1" v-model="validStep1">
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  v-model="defaultItem.codeGerant"
                                  label="Code Gérant *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.contact.nom"
                                  label="Nom *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.contact.prenom"
                                  label="Prénom *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  v-model="defaultItem.contact.email"
                                  label="Adresse Email *"
                                  :rules="[
                                    v => !!v || '* Champs Requis',
                                    v =>
                                      /.+@.+\..+/.test(v) ||
                                      '* E-mail doit être valide'
                                  ]"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.contact.telephone1"
                                  label="Téléphone"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.contact.telephone2"
                                  label="Mobile"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="11" sm="5">
                                <v-menu
                                  ref="menuDebut"
                                  v-model="menuDebut"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      :value="formatDefaultDebutActivite"
                                      label="Début Activité"
                                      readonly
                                      v-on="on"
                                      clearable
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="defaultDate.debutActivite"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menuDebut = false"
                                      >Cancel</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        $refs.menuDebut.save(
                                          defaultDate.debutActivite
                                        )
                                      "
                                      >OK</v-btn
                                    >
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="11" sm="5">
                                <v-menu
                                  ref="menu"
                                  v-model="menu"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      :value="formatDefaultFinActivite"
                                      label="Fin Activité"
                                      readonly
                                      v-on="on"
                                      clearable
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="defaultDate.finActivite"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menu = false"
                                      >Cancel</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        $refs.menu.save(defaultDate.finActivite)
                                      "
                                      >OK</v-btn
                                    >
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" text @click="dialog = false"
                              >Annuler</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStep(2)"
                              :disabled="!validStep1"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                          <v-form ref="formStep2" v-model="validStep2">
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.adresse.adresse1"
                                  label="Adresse *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.adresse.adresse2"
                                  label="Adresse Complémentaire"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.adresse.codePostal"
                                  label="Code Postale *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.adresse.ville"
                                  label="Ville *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="defaultItem.adresse.pays"
                                  label="Pays *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStep(1)"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStep(3)"
                              :disabled="!validStep2"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                          <v-form ref="formStep3" v-model="validStep3">
                            <h4>Banque</h4>
                            <v-text-field
                              v-model="defaultItem.banque.bic"
                              label="BIC *"
                              placeholder="XXXXXXXX"
                              v-mask="maskBIC"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="defaultItem.banque.iban"
                              label="IBAN *"
                              v-mask="maskIBAN"
                              placeholder="XXXX XXXX XXXX XXXX XXXX XXXX XXX"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="defaultItem.banque.compte"
                              label="Compte *"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-text-field>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStep(2)"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="save()"
                              :disabled="!validStep3"
                              >Enregistrer</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                      </v-stepper-items>
                    </template>
                  </v-stepper>
                </v-dialog>
                <v-dialog v-model="dialogEdit" max-width="700px">
                  <v-stepper v-model="e2" :vertical="false" :alt-labels="true">
                    <template>
                      <v-stepper-header>
                        <v-stepper-step step="1">Profil</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">Coordonnées</v-stepper-step>
                        <v-divider v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"></v-divider>
                        <v-stepper-step step="3" v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
                          >Banque</v-stepper-step
                        >
                      </v-stepper-header>
                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <v-form ref="formStep1" v-model="validStep1">
                            <v-row
                              justify="center"
                              v-if="role === 'ROLE_ADMIN'"
                            >
                              <v-col cols="12" md="10">
                                <v-text-field
                                  v-model="editedItem.codeGerant"
                                  label="Code Gérant *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.contact.nom"
                                  label="Nom *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.contact.prenom"
                                  label="Prénom *"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  required
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  v-model="editedItem.contact.email"
                                  label="Adresse Email *"
                                  :rules="[
                                    v => !!v || '* Champs Requis',
                                    v =>
                                      /.+@.+\..+/.test(v) ||
                                      '* E-mail doit être valide'
                                  ]"
                                  required
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.contact.telephone1"
                                  label="Téléphone"
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.contact.telephone2"
                                  label="Mobile"
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row
                              justify="center"
                              v-if="role === 'ROLE_ADMIN'"
                            >
                              <v-col cols="11" sm="5">
                                <v-menu
                                  ref="menuDebut"
                                  v-model="menuDebut"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                  :readonly="statusItem"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      :value="formatEditDebutActivite"
                                      label="Début Activité"
                                      readonly
                                      v-on="on"
                                      clearable
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="editedDate.debutActivite"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menuDebut = false"
                                      >Annuler</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        $refs.menuDebut.save(
                                          editedDate.debutActivite
                                        )
                                      "
                                      >Valider</v-btn
                                    >
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="11" sm="5">
                                <v-menu
                                  ref="menuFin"
                                  v-model="menuFin"
                                  :close-on-content-click="false"
                                  transition="scale-transition"
                                  offset-y
                                  min-width="290px"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      :value="formatEditFinActivite"
                                      label="Fin Activité"
                                      readonly
                                      v-on="on"
                                      clearable
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    v-model="editedDate.finActivite"
                                    no-title
                                    scrollable
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="menuFin = false"
                                      >Annuler</v-btn
                                    >
                                    <v-btn
                                      text
                                      color="primary"
                                      @click="
                                        $refs.menuFin.save(
                                          editedDate.finActivite
                                        )
                                      "
                                      >Valider</v-btn
                                    >
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="error" text @click="closeDialog"
                              >Annuler</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStepEdit(2)"
                              :disabled="!validStep1"
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                          <form>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.adresse.adresse1"
                                  label="Adresse"
                                  required
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.adresse.adresse2"
                                  label="Adresse Complémentaire"
                                  required
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.adresse.codePostal"
                                  label="Code Postale"
                                  required
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.adresse.ville"
                                  label="Ville"
                                  required
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  v-model="editedItem.adresse.pays"
                                  label="Pays"
                                  required
                                  :readonly="statusItem"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStepEdit(1)"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="nextStepEdit(3)"
                              v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
                              >Suivant</v-btn
                            >
                            <v-btn
                              v-else
                              color="#03498E"
                              text
                              @click="editingItem"
                              >Enregistrer</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content
                          step="3"
                          v-if="role === 'ROLE_ADMIN' || role === 'ROLE_CONTROLEUR' || role === 'ROLE_COMMERCIAL'"
                        >
                          <v-form ref="formStep2" v-model="validStep2">
                            <h4>Banque</h4>
                            <v-text-field
                              v-model="editedItem.banque.bic"
                              label="BIC *"
                              placeholder="XXXXXXXX"
                              v-mask="maskBIC"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                              :readonly="statusItem"
                            ></v-text-field>
                            <v-text-field
                              v-model="editedItem.banque.iban"
                              label="IBAN *"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                              v-mask="maskIBAN"
                              placeholder="XXXX XXXX XXXX XXXX XXXX XXXX XXX"
                              :readonly="statusItem"
                            ></v-text-field>
                            <v-text-field
                              v-model="editedItem.banque.compte"
                              label="Compte *"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                              :readonly="statusItem"
                            ></v-text-field>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="nextStepEdit(2)"
                              >Précèdent</v-btn
                            >
                            <v-btn
                              color="#03498E"
                              text
                              @click="editingItem"
                              :disabled="statusItem"
                              >Enregistrer</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                      </v-stepper-items>
                    </template>
                  </v-stepper>
                </v-dialog>
                <v-dialog v-model="dialogUpdateStatus" max-width="290px">
                  <v-card>
                    <v-card-title
                      class="headline"
                      v-show="updatedItem.status === 'ACTIVE'"
                      >Désactiver ce gérant ?</v-card-title
                    >
                    <v-card-title
                      class="headline"
                      v-show="updatedItem.status === 'INACTIVE'"
                      >Activer ce gérant ?</v-card-title
                    >
                    <v-card-text v-show="updatedItem.status === 'ACTIVE'"
                      >Êtes-vous sûr de vouloir désactiver ce gérant
                      ?</v-card-text
                    >
                    <v-card-text v-show="updatedItem.status === 'INACTIVE'"
                      >Êtes-vous sûr de vouloir activer ce gérant ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="error"
                        text
                        @click="dialogUpdateStatus = false"
                        >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="updatingStatus"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="290px">
                  <v-card>
                    <v-card-title class="headline"
                      >Supprimer ce gérant ?</v-card-title
                    >
                    <v-card-text
                      >Êtes-vous sûr de vouloir supprimer ce gérant
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogDelete = false"
                        >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="deletingItem"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="450px" v-model="dialogPassword">
                  <v-card>
                    <v-card-title class="headline"
                      >Modifier le mot de passe ?</v-card-title
                    >
                    <v-alert
                      border="right"
                      colored-border
                      type="error"
                      elevation="2"
                      v-if="errorMessage"
                    >
                      Les mots de passe ne correspondent pas.
                    </v-alert>
                    <v-card-text>
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            label="Mot de passe"
                            v-model="password"
                            :type="showPsw ? 'text' : 'password'"
                            :append-icon="showPsw ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.passwordRules]"
                            @click:append="showPsw = !showPsw"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            label="Confirmer Mot de passe"
                            v-model="passwordConfirm"
                            :type="showPswConfirm ? 'text' : 'password'"
                            :rules="[rules.confirmPasswordRules]"
                            :append-icon="
                              showPswConfirm ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="showPswConfirm = !showPswConfirm"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn @click="closeDialogPassword" color="error" text
                        >Annuler</v-btn
                      >
                      <v-btn @click="updatingPassword" color="#03498E" text
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="350px" v-model="dialogConfirmPassword">
                  <v-card>
                    <v-card-title class="subtitle"
                      >Confirmer la modification ?</v-card-title
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        @click="dialogConfirmPassword = false"
                        color="error"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        @click="confirmPassword"
                        color="#03498E"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip
                top
                v-if="role === 'ROLE_GERANT' || role === 'ROLE_STATION'"
              >
                <template v-slot:activator="{ on }">
                  <v-icon @click="showDialogPassword(item)" size="20">
                    mdi-pencil-lock
                  </v-icon>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_GERANT' ||
                    role === 'ROLE_COMMERCIAL' ||
                    role === 'ROLE_STATION'
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon size="20" @click="editItem(item)">
                    mdi-lead-pencil
                  </v-icon>
                </template>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    size="20"
                    @click="deleteItem(item)"
                    color="red darken-2"
                  >
                    mdi-delete-outline
                  </v-icon>
                </template>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import moment from "moment";
import { host } from "@/utils/constants";
import "moment/locale/fr";
import router from "@/router";
import store from "@/store";
import { mask } from "vue-the-mask";

export default {
  name: "Gerants",
  components: { Widget },
  directives: {
    mask
  },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      username: null,
      loadBtn: false,
      loadData: true,
      loadDataIn: true,
      validStep1: true,
      validStep2: true,
      validStep3: true,
      dialog: false,
      dialogEdit: false,
      dialogUpdateStatus: false,
      dialogDelete: false,
      dialogPassword: false,
      dialogConfirmPassword: false,
      sb: { status: false, text: "", color: "", y: "top" },
      defaultDate: {
        debutActivite: new Date().toISOString().substr(0, 10),
        finActivite: null
      },
      editedDate: {
        debutActivite: null,
        finActivite: null
      },
      menu: false,
      menuDebut: false,
      menuFin: false,
      e1: 1,
      e2: 1,
      steps: 3,
      headers: [
        {
          text: "Nom",
          align: "left",
          value: "contact.nom"
        },
        { text: "Prénom", value: "contact.prenom" },
        { text: "Code", value: "codeGerant" },
        { text: "Début Activité", value: "debutAcivite" },
        { text: "Fin Activité", value: "finAcivite" },
        { text: "Actions", value: "action", sortable: false }
      ],
      gerants: [],
      editedIndex: -1,
      deletedIndex: -1,
      defaultIndex: -1,
      statusItem: false,
      editedItem: {
        codeGerant: "",
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: "",
          pays: "",
          ville: ""
        },
        banque: {
          bic: "",
          compte: "",
          iban: ""
        },
        contact: {
          email: "",
          nom: "",
          prenom: "",
          telephone1: "",
          telephone2: ""
        },
        debutAcivite: "",
        finAcivite: "",
        id: "",
        status: ""
      },
      defaultItem: {
        codeGerant: "",
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: "",
          pays: "",
          ville: ""
        },
        banque: {
          bic: "",
          compte: "",
          iban: ""
        },
        contact: {
          email: "",
          nom: "",
          prenom: "",
          telephone1: "",
          telephone2: ""
        },
        debutAcivite: "",
        finAcivite: "",
        status: "ACTIVE"
      },
      maskBIC: "XXXXXXXXXXX",
      maskIBAN: "XXXX XXXX XXXX XXXX XXXX XXXX XXX",
      deletedItem: {},
      updatedItem: {},
      errorMessage: false,
      showPsw: false,
      showPswConfirm: false,
      password: "",
      passwordConfirm: "",
      rules: {
        passwordRules: v => !!v || "Password is required",
        confirmPasswordRules: v => !!v || "Password is required"
      }
    };
  },
  methods: {
    initialize() {
      this.loadDataIn = true;
      this.loadData = true;

      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      if (this.role === "ROLE_STATION" || this.role === "ROLE_GERANT") {
        this.gerants = [];
        axios
          .get(

              host +
              "/gerants/" +
              this.entity.gerant.id,
            auth
          )
          .then(result => {
            this.gerants.push(result.data);
            this.loadData = false;
            this.loadDataIn = false;
          })
          .catch(err => {
            console.log(err);
            this.gerants = [];
            this.loadDataIn = false;
            this.loadData = false;
          });
      } else {
        axios
          .get(
             host + "/gerants/find",
            auth
          )
          .then(result => {
            this.gerants = result.data;
            this.loadData = false;
            this.loadDataIn = false;
          })
          .catch(err => {
            console.log(err);
            this.gerants = [];
            this.loadDataIn = false;
            this.loadData = false;
          });
      }
    },
    editItem(item) {
      this.editedIndex = this.gerants.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedDate.debutActivite = this.editedItem.debutAcivite;
      this.editedDate.finActivite = this.editedItem.finAcivite;
      if (this.editedItem.status === "INACTIVE") {
        this.statusItem = true;
      } else {
        this.statusItem = false;
      }
      this.dialogEdit = true;
    },
    editingItem() {
      this.loadBtn = true;

      this.editedItem.debutAcivite = moment(
        this.editedDate.debutActivite
      ).format();
      if (this.editedDate.finActivite) {
        this.editedItem.finAcivite = moment(
          this.editedDate.finActivite
        ).format();
      } else {
        this.editedItem.finAcivite = null;
      }

      axios({
        method: "PUT",
        url:

         host +
          "/gerants/" +
          this.editedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        data: this.editedItem
      })
        .then(result => {
          if (result.status === 200) {
            if (this.editedItem.finAcivite !== null) {
              axios({
                method: "PUT",
                url:

                  host +
                  "/gerants/status/INACTIVE/" +
                  this.editedItem.id,
                baseUrl: host,
                headers: {
                  Authorization: "Bearer " + this.token,
                  "X-Requested-Width": "XMLHttpRequest"
                }
              })
                .then(res => {
                  if (res.status === 200) {
                    this.sb.status = true;
                    this.sb.text = "Gérant mis a jour ! ";
                    this.sb.color = "success";
                    this.initialize();
                    this.e2 = 1;
                    this.dialogEdit = false;
                    this.loadBtn = false;
                  }
                })
                .catch(error => {
                  this.sb.status = true;
                  this.sb.text = "Une erreur est survenue ! ";
                  this.sb.color = "error";
                  this.initialize();
                  this.e2 = 1;
                  this.dialogEdit = false;
                  console.log("err : ", error);
                  this.loadBtn = false;
                });
            } else {
              this.sb.status = true;
              this.sb.text = "Gérant mis a jour ! ";
              this.sb.color = "success";
              this.initialize();
              this.e2 = 1;
              this.dialogEdit = false;
              this.loadBtn = false;
            }
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.initialize();
            this.e2 = 1;
            this.dialogEdit = false;
            this.loadBtn = false;
          }
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.initialize();
          this.e2 = 1;
          this.dialogEdit = false;
          console.log("err : ", error);
          this.loadBtn = false;
        });
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:

          host +
          "/gerants/" +
          this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Gérant supprimé ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogDelete = false;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log("err : ", error);
        });
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    closeDialog() {
      this.initialize();
      this.dialogEdit = false;
    },
    save() {
      this.defaultItem.debutAcivite = moment(
        this.defaultDate.debutActivite
      ).format();
      if (this.defaultDate.finActivite) {
        this.defaultItem.finAcivite = moment(
          this.defaultDate.finActivite
        ).format();
      } else {
        this.defaultItem.finAcivite = null;
      }

      axios({
        method: "POST",
        url:  host + "/gerants/",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        data: this.defaultItem
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Gérant ajouté ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialog = false;
          this.e1 = 1;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log("err : ", error);
          this.dialog = false;
          this.e1 = 1;
        });
    },
    updateStatus(item) {
      this.updatedItem = Object.assign({}, item);
      this.dialogUpdateStatus = true;
    },
    updatingStatus() {
      let statut = "ACTIVE";
      if (this.updatedItem.status === "ACTIVE") {
        statut = "INACTIVE";
      }

      axios({
        method: "PUT",
        url:

          host +
          "/gerants/status/" +
          statut +
          "/" +
          this.updatedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Gérant mis à jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogUpdateStatus = false;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log("err : ", error);
        });
    },
    showDialogPassword(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogPassword = true;
    },
    closeDialogPassword() {
      this.dialogPassword = false;
      this.password = "";
      this.passwordConfirm = "";
      this.errorMessage = false;
    },
    updatingPassword() {
      if (this.password === this.passwordConfirm) {
        this.errorMessage = false;
        this.dialogConfirmPassword = true;
      } else {
        this.errorMessage = true;
      }
    },
    confirmPassword() {
      this.loadBtn = true;

      let item = {
        authorities: [this.role],
        password: this.password,
        username: this.username
      };

      axios({
        method: "PUT",
        url:

          host +
          "/accounts/change-password",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: item
      })
        .then(async result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Mot de Passe mis à jour ! ";
            this.sb.color = "success";
            setTimeout(async function() {
              await store.dispatch("auth/signOut");
              router.push("/signIn");
            }, 3000);
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.loadBtn = false;
            this.dialogConfirmPassword = false;
          }
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log("err : ", error);
          this.loadBtn = false;
          this.dialogConfirmPassword = true;
        });
    },
    nextStep(n) {
      this.e1 = n;
    },
    nextStepEdit(n) {
      this.e2 = n;
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else return "orange";
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity",
      "hasAuthenticationUsername"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formatDefaultDebutActivite() {
      return this.defaultDate.debutActivite
        ? moment(this.defaultDate.debutActivite)
            .locale("fr")
            .format("L")
        : "";
    },
    formatDefaultFinActivite() {
      if (this.defaultDate.finActivite !== null) {
        return this.defaultDate.finActivite
          ? moment(this.defaultDate.finActivite)
              .locale("fr")
              .format("L")
          : "";
      }
    },
    formatEditDebutActivite() {
      return this.editedDate.debutActivite
        ? moment(this.editedDate.debutActivite)
            .locale("fr")
            .format("L")
        : "";
    },
    formatEditFinActivite() {
      return this.editedDate.finActivite
        ? moment(this.editedDate.finActivite)
            .locale("fr")
            .format("L")
        : "";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.username = this.hasAuthenticationUsername;
    this.initialize();
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
          .locale("fr")
          .format("L");

        return date;
      }
    }
  }
};
</script>

<style src="./Gerants.scss" lang="scss" scoped />
