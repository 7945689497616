<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="tables-basic">
        <b-breadcrumb>
            <b-breadcrumb-item>Dashboard App</b-breadcrumb-item>
            <b-breadcrumb-item active>Collaborateurs</b-breadcrumb-item>
        </b-breadcrumb>
        <h2 class="page-title">Suivi des <span class="fw-semi-bold">Collaborateurs</span></h2>
        <Widget>
            <div class="table-responsive">
                <v-data-table
                        :headers="headers"
                        :items="collaborateurs"
                        sort-by="nom"
                        class="elevation-1"
                >
                    <template v-slot:item.active="{ item }">
                        <v-chip class="ma-2" :color="getColor(item.active)" label dark outlined>
                            <span v-if="item.active">Active <v-icon color="green">mdi-check</v-icon></span>
                            <span v-else>Inactive <v-icon color="orange">mdi-close</v-icon></span>
                        </v-chip>
                    </template>
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <div class="flex-grow-1"></div>
                            <v-dialog v-model="dialog" max-width="500px" persistent >
                                <template v-slot:activator="{ on }">
                                    <v-btn class="ma-2" outlined color="green darken-2" v-on="on" v-if="role === 'Admin'">Ajouter un Collaborateur</v-btn>
                                </template>
                                <v-card>
                                    <v-card-title justify="center">
                                        <span class="headline">Nouveau Collaborateur</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container>
                                            <form>
                                                <v-row justify="center">
                                                    <v-col cols="12" md="5">
                                                        <v-text-field
                                                                v-model="chauffeurNom"
                                                                label="Nom"
                                                                required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="5">
                                                        <v-text-field
                                                                v-model="chauffeurCode"
                                                                label="Code"
                                                                required></v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-select
                                                        :items="clients"
                                                        label="Client"
                                                        outlined
                                                ></v-select>
                                                <v-row justify="center">
                                                    <v-switch
                                                            v-model="active"
                                                            :label="`Activé: ${switch1.toString()}`"
                                                    ></v-switch>
                                                </v-row>
                                            </form>
                                        </v-container>
                                        <small>*indicates required field</small>
                                    </v-card-text>
                                    <v-card-actions>
                                        <div class="flex-grow-1"></div>
                                        <v-btn color="blue darken-1" text @click="dialog = false">Annuler</v-btn>
                                        <v-btn color="blue darken-1" text @click="save();dialog = false">Enregistrer</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                            <v-dialog v-model="dialogConfirm" max-width="290px">
                                <v-card>
                                    <v-card-title class="headline" v-show="deletedItem.active === true">Désactiver ce collaborateur ?</v-card-title>
                                    <v-card-title class="headline" v-show="deletedItem.active === false">Activer ce collaborateur ?</v-card-title>
                                    <v-card-text v-show="deletedItem.active === true">Êtes-vous sûr de vouloir désactiver ce collaborateur ?</v-card-text>
                                    <v-card-text v-show="deletedItem.active === false">Êtes-vous sûr de vouloir activer ce collaborateur ?</v-card-text>
                                    <v-card-actions>
                                        <div class="flex-grow-1"></div>
                                        <v-btn color="green darken-1" text @click="dialogConfirm = false">Annuler</v-btn>
                                        <v-btn color="green darken-1" text @click="disableItem">Confirmer</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-icon v-if="role === 'Admin'" size="20" @click="editItem(item)">
                            mdi-lead-pencil
                        </v-icon>
                        <v-icon v-if="role === 'Admin'" size="20" v-show="item.active === true" @click="deleteItem(item)" color="red darken-2">
                            mdi-eye-off
                        </v-icon>
                        <v-icon v-if="role === 'Admin'" size="20" v-show="item.active === false" @click="deleteItem(item)" color="green darken-2">
                            mdi-eye
                        </v-icon>
                    </template>
                    <template v-slot:no-data>
                    </template>
                </v-data-table>
            </div>
        </Widget>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
export default {
  name: "Collaborateurs",
  components: { Widget },
  data() {
    return {
      role: null,
      dialog: false,
      dialogConfirm: false,
      e1: 1,
      steps: 1,
      headers: [
        {
          text: "Nom",
          align: "left",
          value: "chauffeurNom"
        },
        { text: "Code Chauffeur", value: "chauffeurCode" },
        { text: "Client", value: "client.nom" },
        { text: "Active", value: "active" },
        { text: "Actions", value: "action", sortable: false }
      ],
      collaborateurs: [],
      clients: ["client 1", "client 2", "client 3"],
      switch1: true,
      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0
      },
      deletedItem: {}
    };
  },
  methods: {
    initialize() {
      this.collaborateurs = [
        {
          chauffeurId: 1,
          client: {
            id: 10,
            nom: "client 1"
          },
          chauffeurNom: "Clain",
          chauffeurCode: 20,
          active: true
        },
        {
          chauffeurId: 2,
          client: {
            id: 11,
            nom: "client 2"
          },
          chauffeurNom: "Fontaine",
          chauffeurCode: 21,
          active: true
        }
      ];
    },
    editItem(item) {
      this.editedIndex = this.collaborateurs.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogConfirm = true;
    },
    disableItem() {
      const index = this.stations.indexOf(this.deletedItem);
      this.deletedItem.active = !this.deletedItem.active;
      this.stations.splice(index, 1, this.deletedItem);
      this.dialogConfirm = false;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.collaborateurs[this.editedIndex], this.editedItem);
      } else {
        this.collaborateurs.push(this.editedItem);
      }
      this.close();
    },
    nextStep(n) {
      this.e1 = n;
    },
    getColor(active) {
      if (active) return "green";
      else return "orange";
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationRole"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.initialize();
    this.role = this.hasAuthenticationRole;
  }
};
</script>

<style src="./Collaborateurs.scss" lang="scss" scoped />
