<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar :color="sb.color" :top="sb.y === 'top'" v-model="sb.status">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white; margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Clients</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="text-right">
        <v-btn
          :disabled="loadCSV"
          :loading="loadCSV"
          class="ma-2 white--text"
          color="success"
          v-if="role !== 'ROLE_CLIENT'"
        >
          <download-csv
            :data="clientsCSV"
            :fields="fields"
            :labels="labels"
            delimiter=";"
            name="clients.csv"
            separator-excel
          >
            Télécharger CSV <v-icon dark right>mdi-cloud-download</v-icon>
          </download-csv>
        </v-btn>
      </div>
      <div class="table-responsive">
        <v-skeleton-loader :loading="loadData" type="table">
          <v-data-table
            :headers="headers"
            :items="clients"
            :loading="loadDataIn"
            :search="search"
            class="elevation-1"
            sort-by="nom"
          >
            <template v-slot:item.status="{ item }">
              <v-btn
                :color="getColor(item.status)"
                @click="updateStatus(item)"
                class="ma-2"
                outlined
                tile
              >
                <span v-if="item.status === 'ACTIVE'"
                  >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else
                  >Inactive <v-icon color="orange">mdi-close</v-icon></span
                >
              </v-btn>
            </template>
            <template v-slot:item.entreprise.contratDate="{ item }">
              {{ item.entreprise.contratDate | formatUnix }}
            </template>
            <template v-slot:top>
              <v-toolbar color="white" flat>
                <div class="flex-grow-1"></div>
                <v-row>
                  <v-col cols="12" md="8">
                    <v-text-field
                      hide-details
                      label="Recherche par N° Client ou Enseigne"
                      single-line
                      v-model="search"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-dialog max-width="900px" persistent v-model="dialog">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ma-2"
                      color="#03498E"
                      outlined
                      v-if="
                        role === 'ROLE_ADMIN' ||
                          role === 'ROLE_CONTROLEUR' ||
                          role === 'ROLE_COMMERCIAL'
                      "
                      v-on="on"
                      >Ajouter un Client</v-btn
                    >
                  </template>
                  <v-stepper :alt-labels="true" :vertical="false" v-model="e1">
                    <template>
                      <v-stepper-header>
                        <v-stepper-step step="1">Entreprise</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">Contact</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">Comptabilité</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4">Finance</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="5"
                          >Données Complémentaires</v-stepper-step
                        >
                        <v-divider></v-divider>
                        <v-stepper-step step="6">Remises</v-stepper-step>
                      </v-stepper-header>
                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <v-form ref="formStep1" v-model="validStep1">
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Enseigne *"
                                  required
                                  v-model="defaultItem.entreprise.enseigne"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Activité de l'entreprise"
                                  v-model="defaultItem.entreprise.activite"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Adresse Facturation *"
                                  required
                                  v-model="
                                    defaultItem.entreprise.adresseFacturation
                                      .adresse1
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Adresse Complémentaire"
                                  v-model="
                                    defaultItem.entreprise.adresseFacturation
                                      .adresse2
                                  "
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="2">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Code Postal *"
                                  required
                                  v-model="
                                    defaultItem.entreprise.adresseFacturation
                                      .codePostal
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Ville *"
                                  required
                                  v-model="
                                    defaultItem.entreprise.adresseFacturation
                                      .ville
                                  "
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="4">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Pays *"
                                  required
                                  v-model="
                                    defaultItem.entreprise.adresseFacturation
                                      .pays
                                  "
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Numéro de contrat *"
                                  required
                                  v-model="defaultItem.entreprise.contratNumero"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Numéro de SIRET"
                                  v-model="defaultItem.entreprise.siret"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="11" sm="5">
                                <v-menu
                                  :close-on-content-click="false"
                                  min-width="290px"
                                  offset-y
                                  ref="menu"
                                  transition="scale-transition"
                                  v-model="menu"
                                >
                                  <template v-slot:activator="{ on }">
                                    <v-text-field
                                      :rules="[v => !!v || '* Champs Requis']"
                                      clearable
                                      label="Date Contrat *"
                                      readonly
                                      v-model="formatDateContrat"
                                      v-on="on"
                                    ></v-text-field>
                                  </template>
                                  <v-date-picker
                                    no-title
                                    scrollable
                                    v-model="defaultItem.entreprise.contratDate"
                                  >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                      @click="menu = false"
                                      color="primary"
                                      text
                                      >Annuler</v-btn
                                    >
                                    <v-btn
                                      @click="
                                        $refs.menu.save(
                                          defaultItem.entreprise.contratDate
                                        )
                                      "
                                      color="primary"
                                      text
                                      >Valider</v-btn
                                    >
                                  </v-date-picker>
                                </v-menu>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Numéro de RCS"
                                  v-model="defaultItem.entreprise.numeroRcs"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="dialog = false" color="error" text
                              >Annuler</v-btn
                            >
                            <v-btn
                              :disabled="!validStep1"
                              @click="nextStep(2)"
                              color="#03498E"
                              text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                          <v-form ref="formStep2" v-model="validStep2">
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Nom *"
                                  required
                                  v-model="defaultItem.nom"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Prénom *"
                                  required
                                  v-model="defaultItem.prenom"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Téléphone"
                                  v-model="defaultItem.contact.telephone1"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Mobile"
                                  v-model="defaultItem.contact.telephone2"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  label="Adresse Email"
                                  v-model="defaultItem.contact.email"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <h5>Adresse de Livraison</h5>
                            <v-row justify="center">
                              <v-checkbox
                                class="mx-2"
                                label="Même adresse que Facturation"
                                v-model="sameAdresse"
                              ></v-checkbox>
                            </v-row>
                            <v-row justify="center" v-show="!sameAdresse">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Adresse"
                                  v-model="defaultItem.adresse.adresse1"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Adresse Complémentaire"
                                  v-model="defaultItem.adresse.adresse2"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center" v-show="!sameAdresse">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Code Postal"
                                  v-model="defaultItem.adresse.codePostal"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Ville"
                                  v-model="defaultItem.adresse.ville"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center" v-show="!sameAdresse">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  label="Pays"
                                  v-model="defaultItem.adresse.pays"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStep(1)" color="warning" text
                              >Précèdent</v-btn
                            >
                            <v-btn
                              :disabled="!validStep2"
                              @click="nextStep(3)"
                              color="#03498E"
                              text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                          <v-form ref="formStep3" v-model="validStep3">
                            <h4>Banque</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Banque *"
                                  required
                                  v-model="defaultItem.banque.banque"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="BIC *"
                                  placeholder="XXXXXXXXXXX"
                                  required
                                  v-mask="maskBIC"
                                  v-model="defaultItem.banque.bic"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="IBAN *"
                                  placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                                  required
                                  v-mask="maskIBAN"
                                  v-model="defaultItem.banque.iban"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <h4>Comptabilité</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Compte Comptabilité Tiers"
                                  v-model="defaultItem.banque.compteTiers"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Autre Compte *"
                                  required
                                  v-model="defaultItem.banque.numeroCompte"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-select
                                  :items="reglementEcheance"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Echéance de Réglement"
                                  outlined
                                  required
                                  v-model="defaultItem.banque.reglementEcheance"
                                >
                                </v-select>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-select
                                  :items="reglementMode"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Mode de Réglement"
                                  outlined
                                  required
                                  v-model="defaultItem.banque.reglementMode"
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStep(2)" color="warning" text
                              >Précèdent</v-btn
                            >
                            <v-btn
                              :disabled="!validStep3"
                              @click="nextStep(4)"
                              color="#03498E"
                              text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                          <form>
                            <h4>Finance</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Limite Credit"
                                  v-model="defaultItem.finance.credit_limite"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Seuil d'Alerte"
                                  v-model="defaultItem.finance.seuil_alerte"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Garantie 1"
                                  v-model="defaultItem.finance.garantie_1"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Garantie 2"
                                  v-model="defaultItem.finance.garantie_2"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="8">
                                <v-textarea
                                  label="Commentaire"
                                  v-model="defaultItem.comment"
                                  name="input-7-1"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStep(3)" color="warning" text
                              >Précèdent</v-btn
                            >
                            <v-btn @click="nextStep(5)" color="#03498E" text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="5">
                          <v-form>
                            <h4>Pin Code</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="4">
                                <v-checkbox
                                  :label="`Pin Code unique`"
                                  v-model="pincodeUnique"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field
                                  :disabled="!pincodeUnique"
                                  :rules="[
                                    v =>
                                      v.length === 4 ||
                                      'Le pin code doit faire 4 caratères !'
                                  ]"
                                  label="Pin Code"
                                  v-model="defaultItem.pincode"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <h4>Email de Facturation</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="4">
                                <v-checkbox
                                        label="Facturation par Email"
                                        v-model="defaultItem.factureMail"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field
                                        :disabled="!defaultItem.factureMail"
                                        label="Adresse Email"
                                        v-model="defaultItem.emailFacturation"
                                        :rules="[
                                     v => !!v || 'Email est requis',
                                     v => /.+@.+/.test(v) || 'E-mail doit être valide' ]"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStep(4)" color="warning" text
                              >Précèdent</v-btn
                            >
                            <v-btn @click="nextStep(6)" color="#03498E" text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="6">
                          <v-form>
                            <v-card
                              class="mx-auto"
                              elevation="0"
                              max-width="600"
                            >
                              <v-list>
                                <v-list-item-group color="primary">
                                  <v-list-item
                                    :key="i"
                                    v-for="(item, i) in remiseProduits"
                                  >
                                    <v-list-item-action>
                                      <v-checkbox
                                        v-model="item.active"
                                      ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item.produit.libelle
                                      }}</v-list-item-title>
                                      <v-list-item-subtitle>{{
                                        item.produit.code
                                      }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                      <v-text-field
                                        :disabled="!item.active"
                                        label="Remise 1"
                                        v-model="item.remise1"
                                      ></v-text-field>
                                    </v-list-item-icon>
                                    <v-list-item-icon>
                                      <v-text-field
                                        :disabled="!item.active"
                                        label="Remise 2"
                                        v-model="item.remise2"
                                      ></v-text-field>
                                    </v-list-item-icon>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-card>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              :disabled="loadBtn"
                              :loading="loadBtn"
                              @click="nextStep(5)"
                              color="warning"
                              text
                              >Précèdent</v-btn
                            >
                            <v-btn
                              :disabled="loadBtn"
                              :loading="loadBtn"
                              @click="save"
                              color="#03498E"
                              text
                              >Enregistrer</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                      </v-stepper-items>
                    </template>
                  </v-stepper>
                </v-dialog>
                <v-dialog max-width="900px" persistent v-model="dialogEdit">
                  <v-stepper :alt-labels="true" :vertical="false" v-model="e2">
                    <template>
                      <v-stepper-header>
                        <v-stepper-step step="1">Entreprise</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="2">Contact</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="3">Comptabilité</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="4">Finance</v-stepper-step>
                        <v-divider></v-divider>
                        <v-stepper-step step="5"
                          >Données Complémentaires</v-stepper-step
                        >
                        <v-divider></v-divider>
                        <v-stepper-step step="6">Remises</v-stepper-step>
                      </v-stepper-header>
                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <v-form ref="formStep1" v-model="validStep1">
                            <v-container fluid justify="center">
                              <v-row justify="center">
                                <v-col cols="12" md="5">
                                  <v-text-field
                                    :rules="[v => !!v || '* Champs Requis']"
                                    label="Enseigne *"
                                    required
                                    v-model="editedItem.entreprise.enseigne"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                  <v-text-field
                                    label="Activité de l'entreprise"
                                    v-model="editedItem.entreprise.activite"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                <v-col cols="12" md="5">
                                  <v-text-field
                                    :rules="[v => !!v || '* Champs Requis']"
                                    label="Adresse Facturation *"
                                    required
                                    v-model="
                                      editedItem.entreprise.adresseFacturation
                                        .adresse1
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                  <v-text-field
                                    label="Adresse Complémentaire"
                                    v-model="
                                      editedItem.entreprise.adresseFacturation
                                        .adresse2
                                    "
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                <v-col cols="12" md="2">
                                  <v-text-field
                                    :rules="[v => !!v || '* Champs Requis']"
                                    label="Code Postal *"
                                    required
                                    v-model="
                                      editedItem.entreprise.adresseFacturation
                                        .codePostal
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    :rules="[v => !!v || '* Champs Requis']"
                                    label="Ville *"
                                    required
                                    v-model="
                                      editedItem.entreprise.adresseFacturation
                                        .ville
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field
                                    :rules="[v => !!v || '* Champs Requis']"
                                    label="Pays *"
                                    required
                                    v-model="
                                      editedItem.entreprise.adresseFacturation
                                        .pays
                                    "
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                <v-col cols="12" md="5">
                                  <v-text-field
                                    :rules="[v => !!v || '* Champs Requis']"
                                    label="Numéro de contrat *"
                                    required
                                    v-model="
                                      editedItem.entreprise.contratNumero
                                    "
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5">
                                  <v-text-field
                                    label="Numéro de SIRET"
                                    v-model="editedItem.entreprise.siret"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row justify="center">
                                <v-col cols="11" sm="5">
                                  <v-menu
                                    :close-on-content-click="false"
                                    min-width="290px"
                                    offset-y
                                    ref="mEditDateContrat"
                                    transition="scale-transition"
                                    v-model="mEditDateContrat"
                                  >
                                    <template v-slot:activator="{ on }">
                                      <v-text-field
                                        clearable
                                        label="Date Contrat"
                                        readonly
                                        v-model="formatEditDateContrat"
                                        v-on="on"
                                      ></v-text-field>
                                    </template>
                                    <v-date-picker
                                      no-title
                                      scrollable
                                      v-model="
                                        editedItem.entreprise.contratDate
                                      "
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        @click="mEditDateContrat = false"
                                        color="primary"
                                        text
                                        >Annuler</v-btn
                                      >
                                      <v-btn
                                        @click="
                                          $refs.mEditDateContrat.save(
                                            editedItem.entreprise.contratDate
                                          )
                                        "
                                        color="primary"
                                        text
                                        >Valider</v-btn
                                      >
                                    </v-date-picker>
                                  </v-menu>
                                </v-col>
                                <v-col cols="12" md="5">
                                  <v-text-field
                                    label="Numéro de RCS"
                                    v-model="editedItem.entreprise.numeroRcs"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              @click="dialogEdit = false"
                              color="error"
                              text
                              >Annuler</v-btn
                            >
                            <v-btn
                              :disabled="!validStep1"
                              @click="nextStepEdit(2)"
                              color="#03498E"
                              text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="2">
                          <v-form ref="formStep2" v-model="validStep2">
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Nom *"
                                  required
                                  v-model="editedItem.nom"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Prénom *"
                                  required
                                  v-model="editedItem.prenom"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Téléphone"
                                  v-model="editedItem.contact.telephone1"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Mobile"
                                  v-model="editedItem.contact.telephone2"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  label="Adresse Email"
                                  v-model="editedItem.contact.email"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <h5>Adresse de Livraison</h5>
                            <v-row justify="center">
                              <v-checkbox
                                class="mx-2"
                                label="Même adresse que Facturation"
                                v-model="sameEditAdresse"
                              ></v-checkbox>
                            </v-row>
                            <v-row justify="center" v-show="!sameEditAdresse">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Adresse"
                                  v-model="editedItem.adresse.adresse1"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Adresse Complémentaire"
                                  v-model="editedItem.adresse.adresse2"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center" v-show="!sameEditAdresse">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Code Postal"
                                  v-model="editedItem.adresse.codePostal"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Ville"
                                  v-model="editedItem.adresse.ville"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center" v-show="!sameEditAdresse">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  label="Pays"
                                  v-model="editedItem.adresse.pays"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStepEdit(1)" color="warning" text
                              >Précèdent</v-btn
                            >
                            <v-btn
                              :disabled="!validStep2"
                              @click="nextStepEdit(3)"
                              color="#03498E"
                              text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="3">
                          <v-form ref="formStep3" v-model="validStep3">
                            <h4>Banque</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="10">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Banque *"
                                  required
                                  v-model="editedItem.banque.banque"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="BIC *"
                                  placeholder="XXXXXXXX"
                                  required
                                  v-mask="maskBIC"
                                  v-model="editedItem.banque.bic"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="IBAN *"
                                  placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX"
                                  required
                                  v-mask="maskIBAN"
                                  v-model="editedItem.banque.iban"
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <h4>Comptabilité</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Compte Comptabilité Tiers *"
                                  required
                                  v-model="editedItem.banque.compteTiers"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Autre Compte"
                                  required
                                  v-model="editedItem.banque.numeroCompte"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-select
                                  :items="reglementEcheance"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Echéance de Réglement"
                                  outlined
                                  required
                                  v-model="editedItem.banque.reglementEcheance"
                                >
                                </v-select>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-select
                                  :items="reglementMode"
                                  :rules="[v => !!v || '* Champs Requis']"
                                  label="Mode de Réglement"
                                  outlined
                                  required
                                  v-model="editedItem.banque.reglementMode"
                                >
                                </v-select>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStepEdit(2)" color="warning" text
                              >Précèdent</v-btn
                            >
                            <v-btn
                              :disabled="!validStep3"
                              @click="nextStepEdit(4)"
                              color="#03498E"
                              text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="4">
                          <form>
                            <h4>Finance</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Limite Credit"
                                  v-model="editedItem.finance.credit_limite"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Seuil d'Alerte"
                                  v-model="editedItem.finance.seuil_alerte"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Garantie 1"
                                  v-model="editedItem.finance.garantie_1"
                                >
                                </v-text-field>
                              </v-col>
                              <v-col cols="12" md="5">
                                <v-text-field
                                  label="Garantie 2"
                                  v-model="editedItem.finance.garantie_2"
                                >
                                </v-text-field>
                              </v-col>
                            </v-row>
                            <v-row justify="center">
                              <v-col cols="12" md="8">
                                <v-textarea
                                  label="Commentaire"
                                  v-model="editedItem.comment"
                                  name="input-7-1"
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStepEdit(3)" color="warning" text
                              >Précèdent</v-btn
                            >
                            <v-btn @click="nextStepEdit(5)" color="#03498E" text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="5">
                          <v-form>
                            <h4>Pin Code</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="4">
                                <v-checkbox
                                  :label="`Pin Code Unique`"
                                  v-model="pincodeUnique"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field
                                  :disabled="!pincodeUnique"
                                  :rules="[
                                    v =>
                                      v.length === 4 ||
                                      'Le pin code doit faire 4 caratères !'
                                  ]"
                                  label="Pin Code"
                                  v-model="editedItem.pincode"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                            <h4>Email de Facturation</h4>
                            <v-row justify="center">
                              <v-col cols="12" md="4">
                                <v-checkbox
                                        label="Facturation par Email"
                                        v-model="editedItem.factureMail"
                                ></v-checkbox>
                              </v-col>
                              <v-col cols="12" md="3">
                                <v-text-field
                                        :disabled="!editedItem.factureMail"
                                        label="Adresse Email"
                                        v-model="editedItem.emailFacturation"
                                        :rules="[
                                     v => !!v || 'Email est requis',
                                     v => /.+@.+/.test(v) || 'E-mail doit être valide' ]"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn @click="nextStepEdit(4)" color="warning" text
                              >Précèdent</v-btn
                            >
                            <v-btn @click="nextStepEdit(6)" color="#03498E" text
                              >Suivant</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                        <v-stepper-content step="6">
                          <v-form>
                            <v-card
                              class="mx-auto"
                              elevation="0"
                              max-width="600"
                            >
                              <v-list>
                                <v-list-item-group color="primary">
                                  <v-list-item
                                    :key="i"
                                    v-for="(item, i) in remiseProduits"
                                  >
                                    <v-list-item-action>
                                      <v-checkbox
                                        v-model="item.active"
                                      ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        item.produit.libelle
                                      }}</v-list-item-title>
                                      <v-list-item-subtitle>{{
                                        item.produit.code
                                      }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                      <v-text-field
                                        :disabled="!item.active"
                                        label="Remise 1"
                                        v-model="item.remise1"
                                      ></v-text-field>
                                    </v-list-item-icon>
                                    <v-list-item-icon>
                                      <v-text-field
                                        :disabled="!item.active"
                                        label="Remise 2"
                                        v-model="item.remise2"
                                      ></v-text-field>
                                    </v-list-item-icon>
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-card>
                          </v-form>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              :disabled="loadBtn"
                              :loading="loadBtn"
                              @click="closeEdit"
                              color="error"
                              text
                              >Annuler</v-btn
                            >
                            <v-btn
                              :disabled="loadBtn"
                              :loading="loadBtn"
                              @click="nextStepEdit(5)"
                              color="warning"
                              text
                              >Précèdent</v-btn
                            >
                            <v-btn
                              :disabled="loadBtn"
                              :loading="loadBtn"
                              @click="editingItem"
                              color="#03498E"
                              text
                              >Enregistrer</v-btn
                            >
                          </v-card-actions>
                        </v-stepper-content>
                      </v-stepper-items>
                    </template>
                  </v-stepper>
                </v-dialog>
                <v-dialog
                  max-width="700px"
                  persistent
                  v-model="dialogEditLight"
                >
                  <v-card>
                    <v-card-title class="headline">Modification</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" md="5">
                            <v-text-field
                              label="Nom"
                              v-model="editedItem.nom"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-text-field
                              label="Prenom"
                              v-model="editedItem.prenom"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="5">
                            <v-text-field
                              :rules="[v => !!v || '* Champs Requis']"
                              label="Adresse Livraison *"
                              required
                              v-model="editedItem.adresse.adresse1"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-text-field
                              label="Adresse Complémentaire"
                              v-model="editedItem.adresse.adresse2"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="2">
                            <v-text-field
                              :rules="[v => !!v || '* Champs Requis']"
                              label="Code Postal *"
                              required
                              v-model="editedItem.adresse.codePostal"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              :rules="[v => !!v || '* Champs Requis']"
                              label="Ville *"
                              required
                              v-model="editedItem.adresse.ville"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              :rules="[v => !!v || '* Champs Requis']"
                              label="Pays *"
                              required
                              v-model="editedItem.adresse.pays"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="5">
                            <v-text-field
                              label="Téléphone"
                              v-model="editedItem.contact.telephone1"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-text-field
                              label="Mobile"
                              v-model="editedItem.contact.telephone2"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="10">
                            <v-text-field
                              label="Adresse Email"
                              v-model="editedItem.contact.email"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="5">
                              <v-checkbox
                                      label="Facturation par Email"
                                      v-model="editedItem.factureMail"
                              ></v-checkbox>
                            </v-col>
                            <v-col cols="12" md="5">
                              <v-text-field
                                      :disabled="!editedItem.factureMail"
                                      label="Adresse Email"
                                      v-model="editedItem.emailFacturation"
                                      :rules="[
                                     v => !!v || 'Email est requis',
                                     v => /.+@.+/.test(v) || 'E-mail doit être valide' ]"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        :disabled="loadBtn"
                        :loading="loadBtn"
                        @click="dialogEditLight = false"
                        color="red"
                        text
                        >Fermer</v-btn
                      >
                      <v-btn
                        :disabled="loadBtn"
                        :loading="loadBtn"
                        @click="editingLightItem"
                        color="#03498E"
                        text
                        >Enregistrer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="700px" persistent v-model="dialogInfo">
                  <v-card>
                    <v-card-title class="headline">Information</v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row justify="center">
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Enseigne"
                              readonly
                              v-model="editedItem.entreprise.enseigne"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Activité de l'entreprise"
                              readonly
                              v-model="editedItem.entreprise.activite"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Adresse Facturation"
                              readonly
                              v-model="
                                editedItem.entreprise.adresseFacturation
                                  .adresse1
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Adresse Complémentaire Facturation"
                              readonly
                              v-model="
                                editedItem.entreprise.adresseFacturation
                                  .adresse2
                              "
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="4">
                            <v-text-field
                              label="Code Postal"
                              readonly
                              v-model="
                                editedItem.entreprise.adresseFacturation
                                  .codePostal
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              label="Ville"
                              readonly
                              v-model="
                                editedItem.entreprise.adresseFacturation.ville
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              label="Pays "
                              readonly
                              v-model="
                                editedItem.entreprise.adresseFacturation.pays
                              "
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Numéro de contrat"
                              readonly
                              v-model="editedItem.entreprise.contratNumero"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Numéro de SIRET"
                              readonly
                              v-model="editedItem.entreprise.siret"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="11" sm="6">
                            <v-text-field
                              label="Date Contrat"
                              readonly
                              v-model="formatEditDateContrat"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              label="Numéro de RCS"
                              readonly
                              v-model="editedItem.entreprise.numeroRcs"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row justify="center">
                          <v-col cols="12" md="4">
                            <v-checkbox
                              :label="`Pin Code Unique`"
                              readonly
                              v-model="pincodeUnique"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field
                              :disabled="!pincodeUnique"
                              :rules="[
                                v =>
                                  v.length === 4 ||
                                  'Le pin code doit faire 4 caratères !'
                              ]"
                              label="Pin Code"
                              readonly
                              v-model="editedItem.pincode"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <h4>Email de Facturation</h4>
                        <v-row justify="center">
                          <v-col cols="12" md="4">
                            <v-checkbox
                                    label="Facturation par Email"
                                    v-model="editedItem.factureMail"
                                    readonly
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" md="5">
                            <v-text-field
                                    :disabled="!editedItem.factureMail"
                                    label="Adresse Email"
                                    v-model="editedItem.emailFacturation"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn @click="dialogInfo = false" color="#03498E" text
                        >Fermer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="290px" v-model="dialogUpdateStatus">
                  <v-card>
                    <v-card-title
                      class="headline"
                      v-show="updatedItem.status === 'ACTIVE'"
                      >Désactiver ce client ?</v-card-title
                    >
                    <v-card-title
                      class="headline"
                      v-show="updatedItem.status === 'INACTIVE'"
                      >Activer ce client ?</v-card-title
                    >
                    <v-card-text v-show="updatedItem.status === 'ACTIVE'"
                      >Êtes-vous sûr de vouloir désactiver ce client
                      ?</v-card-text
                    >
                    <v-card-text v-show="updatedItem.status === 'INACTIVE'"
                      >Êtes-vous sûr de vouloir activer ce client ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        @click="dialogUpdateStatus = false"
                        color="error"
                        text
                        >Annuler</v-btn
                      >
                      <v-btn @click="updatingStatus" color="#03498E" text
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="450px" v-model="dialogPassword">
                  <v-card>
                    <v-card-title class="headline"
                      >Modifier le mot de passe ?</v-card-title
                    >
                    <v-alert
                      border="right"
                      colored-border
                      type="error"
                      elevation="2"
                      v-if="errorMessage"
                    >
                      Les mots de passe ne correspondent pas.
                    </v-alert>
                    <v-card-text>
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            label="Mot de passe"
                            v-model="password"
                            :type="showPsw ? 'text' : 'password'"
                            :append-icon="showPsw ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.passwordRules]"
                            @click:append="showPsw = !showPsw"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            label="Confirmer Mot de passe"
                            v-model="passwordConfirm"
                            :type="showPswConfirm ? 'text' : 'password'"
                            :rules="[rules.confirmPasswordRules]"
                            :append-icon="
                              showPswConfirm ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="showPswConfirm = !showPswConfirm"
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn @click="closeDialogPassword" color="error" text
                        >Annuler</v-btn
                      >
                      <v-btn @click="updatingPassword" color="#03498E" text
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="350px" v-model="dialogConfirmPassword">
                  <v-card>
                    <v-card-title class="subtitle"
                      >Confirmer la modification ?</v-card-title
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        @click="dialogConfirmPassword = false"
                        color="error"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        @click="confirmPassword"
                        color="#03498E"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="500px" v-model="dialogPincode">
                  <v-card>
                    <v-card-title class="headline"
                      >Modifier le Pin Code ?</v-card-title
                    >
                    <v-card-text
                      >Êtes-vous sûr de vouloir modifier le pin code de toutes
                      vos cartes ?</v-card-text
                    >
                    <v-card-text>
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            label="Pin Code"
                            v-model="editPincode"
                            v-mask="maskPincode"
                            :rules="[
                              v =>
                                v.length === 4 ||
                                'Le pin code doit faire 4 caratères !'
                            ]"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        @click="dialogPincode = false"
                        color="error"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        v-if="editPincode.length === 4"
                        @click="updatingPinCode"
                        color="#03498E"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="290px" v-model="dialogDelete">
                  <v-card>
                    <v-card-title class="headline"
                      >Supprimer ce client ?</v-card-title
                    >
                    <v-card-text
                      >Êtes-vous sûr de vouloir supprimer ce client
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn @click="dialogDelete = false" color="error" text
                        >Annuler</v-btn
                      >
                      <v-btn @click="deletingItem" color="#03498E" text
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="500px" v-model="dialogUpload">
                  <v-card>
                    <v-card-title class="headline"
                      >Déposer un fichier ?</v-card-title
                    >
                    <v-card-text>
                      <v-file-input
                        label="Fichier"
                        show-size
                        clearable
                        v-model="chosenFile"
                        @click:clear="cleanFile"
                        :disabled="loadBtn"
                      ></v-file-input>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        @click="closeDialogUpload"
                        color="error"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        @click="uploadingFile"
                        color="#03498E"
                        text
                        :loading="loadBtn"
                        :disabled="
                          loadBtn ||
                            chosenFile === null ||
                            chosenFile === undefined
                        "
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-tooltip
                top
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon @click="editItem(item)" size="20">
                    mdi-lead-pencil
                  </v-icon>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip top v-if="role === 'ROLE_CLIENT'">
                <template v-slot:activator="{ on }">
                  <v-icon @click="editLightItem(item)" size="20">
                    mdi-lead-pencil
                  </v-icon>
                </template>
                <span>Modifier</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon
                    @click="deleteItem(item)"
                    color="red darken-2"
                    size="20"
                  >
                    mdi-delete-outline
                  </v-icon>
                </template>
                <span>Supprimer</span>
              </v-tooltip>
              <v-tooltip top v-if="role === 'ROLE_CLIENT'">
                <template v-slot:activator="{ on }">
                  <v-icon @click="showDialogPassword(item)" size="20" v-on="on"
                    >mdi-pencil-lock</v-icon
                  >
                </template>
                <span>Modifier Mot de Passe</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon @click="showDialogInfo(item)" size="20" v-on="on"
                    >mdi-information-outline</v-icon
                  >
                </template>
                <span>Plus d'informations</span>
              </v-tooltip>
              <v-tooltip top v-if="role === 'ROLE_CLIENT'">
                <template v-slot:activator="{ on }">
                  <v-icon @click="showDialogPincode(item)" size="20" v-on="on"
                    >mdi-comment-edit</v-icon
                  >
                </template>
                <span>Modifier Pin Code</span>
              </v-tooltip>
              <v-tooltip
                top
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
              >
                <template v-slot:activator="{ on }">
                  <v-icon @click="showUpload(item)" size="20" v-on="on"
                    >mdi-cloud-upload-outline</v-icon
                  >
                </template>
                <span>Déposer un fichier</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import JsonCSV from "vue-json-csv";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";
import { mask } from "vue-the-mask";
import * as _ from "lodash";
import router from "@/router";
import store from "@/store";

export default {
  name: "Clients",
  components: { Widget, "download-csv": JsonCSV },
  directives: {
    mask
  },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      username: null,
      loadBtn: false,
      loadCSV: false,
      loadData: true,
      loadDataIn: true,
      validStep1: true,
      validStep2: true,
      validStep3: true,
      validStep4: true,
      dialog: false,
      dialogInfo: false,
      dialogEdit: false,
      dialogEditLight: false,
      dialogUpdateStatus: false,
      dialogPincode: false,
      dialogUpload: false,
      dialogDelete: false,
      dialogPassword: false,
      dialogConfirmPassword: false,
      sameAdresse: true,
      sameEditAdresse: false,
      editPincode: "",
      maskPincode: "####",
      e1: 1,
      e2: 1,
      menu: false,
      mEditDateContrat: false,
      sb: { status: false, text: "", color: "", y: "top" },
      search: "",
      headers: [
        {
          text: "Numéro Client",
          align: "left",
          value: "entreprise.contratNumero"
        },
        { text: "Enseigne", value: "entreprise.enseigne" },
        {
          text: "Date Création",
          value: "entreprise.contratDate",
          filterable: false
        },
        {
          text: "Ville",
          value: "entreprise.adresseFacturation.ville",
          filterable: false
        },
        { text: "Statut", value: "status", filterable: false },
        { text: "Actions", value: "action", sortable: false, filterable: false }
      ],
      clients: [],
      switch1: true,
      pincodeUnique: false,
      password: "",
      passwordConfirm: "",
      errorMessage: false,
      showPsw: false,
      showPswConfirm: false,
      validPassword: "",
      rules: {
        passwordRules: v => !!v || "Password is required",
        confirmPasswordRules: v => !!v || "Password is required"
      },
      editedIndex: -1,
      editedItem: {
        comment: "",
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: "",
          pays: "",
          ville: ""
        },
        banque: {
          banque: "",
          bic: "",
          compteTiers: "",
          iban: "",
          numeroCompte: 0,
          reglementEcheance: "",
          reglementMode: ""
        },
        contact: {
          email: "",
          telephone1: "",
          telephone2: ""
        },
        entreprise: {
          activite: "",
          adresseFacturation: {
            adresse1: "",
            adresse2: "",
            codePostal: "",
            pays: "",
            ville: ""
          },
          contratDate: "",
          contratExpire: true,
          contratNumero: "",
          enseigne: "",
          numeroRcs: 0,
          siret: ""
        },
        finance: {
          credit_limite: 0,
          garantie_1: "",
          garantie_2: "",
          seuil_alerte: 0
        },
        remises: [],
        nom: "",
        pinType: "",
        pincode: "",
        pincodeMode: "",
        prenom: "",
        status: "",
        factureMail: "", //bool
        emailFacturation: "",
      },
      defaultItem: {
        adresse: {
          adresse1: "",
          adresse2: "",
          codePostal: 0,
          pays: "REUNION",
          ville: ""
        },
        banque: {
          banque: "",
          bic: "",
          compteTiers: "",
          iban: "",
          numeroCompte: 0,
          reglementEcheance: "",
          reglementMode: ""
        },
        contact: {
          email: "",
          telephone1: "",
          telephone2: ""
        },
        entreprise: {
          activite: "",
          adresseFacturation: {
            adresse1: "",
            adresse2: "",
            codePostal: 0,
            pays: "",
            ville: ""
          },
          contratDate: "",
          contratExpire: false,
          contratNumero: "",
          enseigne: "",
          numeroRcs: 0,
          siret: ""
        },
        finance: {
          credit_limite: 0,
          garantie_1: "",
          garantie_2: "",
          seuil_alerte: 0
        },
        remises: [],
        nom: "",
        pinType: "CARTE",
        comment: "",
        pincode: "",
        pincodeMode: "GENERATE",
        prenom: "",
        status: "ACTIVE",
        factureMail: false, //bool
        emailFacturation: "",
      },
      deletedItem: {},
      updatedItem: {},
      editedDate: {
        dateNaissance: null,
        contratDate: null
      },
      reglementEcheance: ["J+0", "J+15", "J+30", "J+45", "J+60", "J+90"],
      reglementMode: [
        "ESPECE",
        "VIREMENT",
        "PRELEVEMENT",
        "CHEQUE",
        "CB",
        "MANDAT"
      ],
      maskBIC: "XXXXXXXXXXX",
      maskIBAN: "XXXX XXXX XXXX XXXX XXXX XXXX XXX",
      remiseProduits: [],
      stations: [],
      produits: [],
      clientsCSV: [],
      labels: {
        contratNumero: "N Client",
        enseigne: "Enseigne",
        activite: "Activite",
        siret: "Siret",
        rcs: "RCS",
        date_contrat: "Date Contrat",
        statut: "Statut",
        banque: "Banque",
        banque_bic: "BIC",
        banque_iban: "IBAN",
        reglement_echeance: "Reglement Echeance",
        reglement_mode: "Reglement Mode",
        finance_credit: "Finance Credit",
        finance_creditlimite: "Finance Credit Limite",
        finance_seuilalert: "Finance Seuil Alerte",
        finance_garantie1: "Finance Garantie1",
        finance_garantie2: "Finance Garantie2",
        remiseProduitCode: "Remise Produit Code",
        remiseProduitLibelle: "Remise Produit Libelle",
        remise1: "Remise 1",
        remise2: "Remise 2",
        nom_contact: "Nom Contact",
        prenom_contact: "Prenom Contact",
        email: "Email",
        tel1: "Telephone 1",
        tel2: "Telephone 2",
        adresse1: "Adresse 1",
        adresse2 :"Adresse 2",
        code_postal: "Code postal",
        ville: "Ville",
        fact_adresse1: "Fact. Adresse 1",
        fact_adresse2: "Fact. Adresse 2",
        fact_ville : "Fact. Ville",
        fact_codepostal: "Fact. Code postal",
      },
      fields: [
        "contratNumero",
        "enseigne",
        "activite",
        "siret",
        "rcs",
        "date_contrat",
        "statut",
        "banque",
        "banque_bic",
        "banque_iban",
        "reglement_echeance",
        "reglement_mode",
        "finance_credit",
        "finance_creditlimite",
        "finance_seuilalert",
        "finance_garantie1",
        "finance_garantie2",
        "remiseProduitCode",
        "remiseProduitLibelle",
        "remise1",
        "remise2",
        "nom_contact",
        "prenom_contact",
        "email",
        "tel1",
        "tel2",
        "adresse1",
        "adresse2",
        "code_postal",
        "ville",
        "fact_adresse1",
        "fact_adresse2",
        "fact_ville",
        "fact_codepostal",
      ],
      chosenFile: null
    };
  },
  methods: {
    initialize() {
      this.remiseProduits = [];
      this.loadCSV = true;
      this.loadDataIn = true;
      this.loadData = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      if (
        this.role === "ROLE_ADMIN" ||
        this.role === "ROLE_CONTROLEUR" ||
        this.role === "ROLE_COMMERCIAL"
      ) {
        axios
          .get(
            host + "/clients/",
            auth
          )
          .then(result => {
            this.clients = result.data;
            this.loadDataIn = false;
            this.loadData = false;
            this._sortClients();
          });
      } else if (this.role === "ROLE_CLIENT") {
        this.clients = [];
        axios
          .get(
              host +
              "/clients/" +
              this.entity.id,
            auth
          )
          .then(result => {
            this.clients.push(result.data);
            this.loadDataIn = false;
            this.loadData = false;
            this._sortClients();
          });
      }

      axios
        .get(host + "/produits/", auth)
        .then(result => {
          let produits = _.sortBy(result.data, "code");
          this.produits = produits;
          produits.forEach(element => {
            this.remiseProduits.push({
              produit: element,
              active: false,
              remise1: 0,
              remise2: 0
            });
          });
        });

      axios
        .get(host + "/stations/", auth)
        .then(result => {
          this.stations = result.data;
        });
    },
    showDialogInfo(item) {
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.finance === null) {
        this.editedItem.finance = this.defaultItem.finance;
      }
      if (this.editedItem.remises === null) {
        this.editedItem.remises = this.defaultItem.remises;
      }
      if (this.editedItem.pincodeMode === "UNIQUE") {
        this.pincodeUnique = true;
      } else {
        this.pincodeUnique = false;
      }
      if(this.editedItem.factureMail === "") {
        this.editedItem.factureMail = false;
      }
      this.dialogInfo = true;
    },
    editLightItem(item) {
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.finance === null) {
        this.editedItem.finance = this.defaultItem.finance;
      }
      if (this.editedItem.remises === null) {
        this.editedItem.remises = this.defaultItem.remises;
      }
      if(this.editedItem.factureMail === "") {
        this.editedItem.factureMail = false;
      }
      this.dialogEditLight = true;
    },
    editingLightItem() {
      this.loadBtn = true;
      if(this.editedItem.factureMail === "") {
        this.editedItem.factureMail = false;
      }
      axios({
        method: "POST",
        url:
          host +
          "/clients/update/" +
          this.editedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        data: JSON.stringify(this.editedItem)
      })
        .then(result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Client mis a jour ! ";
            this.sb.color = "success";
            this.initialize();
            this.loadBtn = false;
            this.dialogEditLight = false;
            this.e2 = 1;
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.loadBtn = false;
            this.dialogEdit = false;
            this.e2 = 1;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          this.dialogEditLight = false;
          this.e2 = 1;
        });
    },
    editItem(item) {
      this.remiseProduits = [];
      this.editedIndex = this.clients.indexOf(item);
      this.editedItem = Object.assign({}, item);

      if (this.editedItem.finance === null) {
        this.editedItem.finance = this.defaultItem.finance;
      }
      if (this.editedItem.remises === null) {
        this.editedItem.remises = this.defaultItem.remises;
      }
      if (this.editedItem.pincodeMode === "UNIQUE") {
        this.pincodeUnique = true;
      } else {
        this.pincodeUnique = false;
      }

      if(this.editedItem.factureMail === "") {
        this.editedItem.factureMail = false;
      }

      this.editedItem.banque.reglementEcheance = this._findReglementEchNum(
        this.editedItem.banque.reglementEcheance
      );

      if (
        this.editedItem.adresse.adresse1 ===
        this.editedItem.entreprise.adresseFacturation.adresse1
      ) {
        this.sameEditAdresse = true;
      }

      this.produits.forEach(element => {
        this.remiseProduits.push({
          produit: element,
          active: false,
          remise1: 0,
          remise2: 0
        });
      });

      if (this.editedItem.remises.length !== 0) {
        this.remiseProduits.forEach(element => {
          this.editedItem.remises.forEach(el => {
            if (el.produit.code === element.produit.code) {
              element.active = true;
              element.remise1 = el.remise1;
              element.remise2 = el.remise2;
            }
          });
        });
      }

      this.dialogEdit = true;
    },
    editingItem() {
      this.loadBtn = true;
      if (this.pincodeUnique === true) {
        this.editedItem.pincodeMode = "UNIQUE";
      } else {
        this.editedItem.pincodeMode = "GENERATE";
        this.editedItem.pincode = "";
      }

      if(this.editedItem.factureMail === "") {
        this.editedItem.factureMail = false;
      }

      if (this.sameEditAdresse) {
        this.editedItem.adresse.adresse1 = this.editedItem.entreprise.adresseFacturation.adresse1;
        this.editedItem.adresse.adresse2 = this.editedItem.entreprise.adresseFacturation.adresse2;
        this.editedItem.adresse.codePostal = this.editedItem.entreprise.adresseFacturation.codePostal;
        this.editedItem.adresse.ville = this.editedItem.entreprise.adresseFacturation.ville;
        this.editedItem.adresse.pays = this.editedItem.entreprise.adresseFacturation.pays;
      }

      this.editedItem.banque.reglementEcheance = this._findReglementEchLabel(this.editedItem.banque.reglementEcheance);

      let arrpp = [];
      this.remiseProduits.forEach(element => {
        if (element.active) {
          arrpp.push({
            produit: { id: element.produit.id },
            remise1: element.remise1,
            remise2: element.remise2
          });
        }
      });
      this.editedItem.remises = arrpp;

      axios({
        method: "PUT",
        url:
          host +
          "/clients/update/" +
          this.editedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        data: JSON.stringify(this.editedItem)
      })
        .then(result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Client mis a jour ! ";
            this.sb.color = "success";
            this.initialize();
            this.loadBtn = false;
            this.dialogEdit = false;
            this.e2 = 1;
          } else {
            this.initialize();
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.loadBtn = false;
            this.dialogEdit = false;
            this.e2 = 1;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          this.dialogEdit = false;
          this.e2 = 1;
          this.initialize();
        });
    },
    showDialogPassword(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogPassword = true;
    },
    closeDialogPassword() {
      this.dialogPassword = false;
      this.password = "";
      this.passwordConfirm = "";
      this.errorMessage = false;
    },
    updatingPassword() {
      if (this.password === this.passwordConfirm) {
        this.errorMessage = false;
        this.dialogConfirmPassword = true;
      } else {
        this.errorMessage = true;
      }
    },
    confirmPassword() {
      this.loadBtn = true;

      let item = {
        authorities: ["ROLE_CLIENT"],
        password: this.password,
        username: this.username
      };
      axios({
        method: "PUT",
        url:
          host +
          "/accounts/change-password",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: item
      })
        .then(async result => {
          if (result.status === 200) {
            this.sb.status = true;
            this.sb.text = "Mot de Passe mis à jour ! ";
            this.sb.color = "success";
            setTimeout(async function() {
              await store.dispatch("auth/signOut");
              router.push("/signIn");
            }, 3000);
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.loadBtn = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
        });
    },
    showDialogPincode(item) {
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.finance === null) {
        this.editedItem.finance = this.defaultItem.finance;
      }
      if (this.editedItem.remises === null) {
        this.editedItem.remises = this.defaultItem.remises;
      }
      this.dialogPincode = true;
    },
    uploadingFile() {
      if (this.chosenFile !== null || this.chosenFile !== undefined) {
        this.loadBtn = true;

        let formData = new FormData();
        formData.append("file", this.chosenFile);

        axios({
          method: "POST",
          url:
            host +
            "/clients/upload/file/" +
            this.editedItem.entreprise.contratNumero,
          baseUrl: host,
          headers: {
            Authorization: "Bearer " + this.token,
            "X-Requested-Width": "XMLHttpRequest",
            "Content-Type": "multipart/form-data",
            Accept: "application/json"
          },
          data: formData
        })
          .then(result => {
            if (result.status === 200) {
              this.sb.status = true;
              this.sb.text = "Fichier déposé !";
              this.sb.color = "success";
              this.loadBtn = false;
              this.dialogUpload = false;
            } else {
              this.sb.status = true;
              this.sb.text = "Une erreur est survenue !";
              this.sb.color = "error";
              this.loadBtn = false;
              this.dialogUpload = false;
            }
          })
          // eslint-disable-next-line no-unused-vars
          .catch(error => {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue !";
            this.sb.color = "error";
            this.loadBtn = false;
            this.dialogUpload = false;
          });
      }
    },
    updatingPinCode() {
      let pincode = this.editPincode;
      this.loadBtn = true;
      axios({
        method: "PUT",
        url:
          host +
          "/cartes/pincode-client/" +
          this.entity.id +
          "/" +
          pincode,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        }
      })
        .then(result => {
          if (result.status === 200) {
            this.editedItem.pincode = pincode;

            axios({
              method: "POST",
              url:
                host +
                "/clients/update/" +
                this.editedItem.id,
              baseUrl: host,
              headers: {
                Authorization: "Bearer " + this.token,
                "X-Requested-Width": "XMLHttpRequest",
                "Content-Type": "application/json",
                Accept: "application/json"
              },
              data: JSON.stringify(this.editedItem)
            })
              .then(result => {
                if (result.status === 200) {
                  this.sb.status = true;
                  this.sb.text = "Pin Code mis a jour ! ";
                  this.sb.color = "success";
                  this.initialize();
                  this.loadBtn = false;
                  this.editPinCode = "";
                  this.dialogPincode = false;
                } else {
                  this.sb.status = true;
                  this.sb.text = "Une erreur est survenue ! ";
                  this.sb.color = "error";
                  this.loadBtn = false;
                  this.editPinCode = "";
                  this.dialogPincode = false;
                }
              })
              // eslint-disable-next-line no-unused-vars
              .catch(error => {
                this.sb.status = true;
                this.sb.text = "Une erreur est survenue ! ";
                this.sb.color = "error";
                this.editPinCode = "";
                this.dialogPincode = false;
                this.loadBtn = false;
              });
          } else {
            this.sb.status = true;
            this.sb.text = "Une erreur est survenue ! ";
            this.sb.color = "error";
            this.editPinCode = "";
            this.dialogPincode = false;
            this.loadBtn = false;
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.editPinCode = "";
          this.dialogPincode = false;
          this.loadBtn = false;
        });
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:
          host +
          "/clients/" +
          this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Client supprimé ! ";
          this.sb.color = "success";
          this.initialize();
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
        });

      this.dialogDelete = false;
    },
    showUpload(item) {
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.finance === null) {
        this.editedItem.finance = this.defaultItem.finance;
      }
      if (this.editedItem.remises === null) {
        this.editedItem.remises = this.defaultItem.remises;
      }
      this.dialogUpload = true;
    },
    closeDialogUpload() {
      this.chosenFile = null;
      this.dialogUpload = false;
    },
    cleanFile() {
      this.chosenFile = null;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      this.loadBtn = true;
      let arrpp = [];

      this.defaultItem.entreprise.contratDate = moment(
        this.defaultItem.entreprise.contratDate
      ).format();

      if (this.pincodeUnique) {
        this.defaultItem.pincodeMode = "UNIQUE";
      } else {
        this.defaultItem.pincodeMode = "GENERATE";
      }

      this.defaultItem.banque.numeroCompte = parseInt(
        this.defaultItem.banque.numeroCompte
      );

      this.defaultItem.banque.reglementEcheance = this._findReglementEchLabel(
this.defaultItem.banque.reglementEcheance
      );

      if (this.sameAdresse) {
        this.defaultItem.adresse.adresse1 = this.defaultItem.entreprise.adresseFacturation.adresse1;
        this.defaultItem.adresse.adresse2 = this.defaultItem.entreprise.adresseFacturation.adresse2;
        this.defaultItem.adresse.codePostal = this.defaultItem.entreprise.adresseFacturation.codePostal;
        this.defaultItem.adresse.ville = this.defaultItem.entreprise.adresseFacturation.ville;
        this.defaultItem.adresse.pays = this.defaultItem.entreprise.adresseFacturation.pays;
      }

      this.remiseProduits.forEach(element => {
        if (element.active) {
          arrpp.push(element);
        }
      });
      this.defaultItem.remises = arrpp;

      axios({
        method: "POST",
        url: host + "/clients/",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        data: JSON.stringify(this.defaultItem)
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Client ajouté ! ";
          this.sb.color = "success";
          this.initialize();
          this.loadBtn = false;
          this.dialog = false;
          this.e1 = 1;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          console.log(error);
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          this.dialog = false;
          this.e1 = 1;
        });
    },
    updateStatus(item) {
      if (
        this.role === "ROLE_ADMIN" ||
        this.role === "ROLE_CONTROLEUR" ||
        this.role === "ROLE_COMMERCIAL"
      ) {
        this.updatedItem = Object.assign({}, item);
        this.dialogUpdateStatus = true;
      }
    },
    updatingStatus() {
      let statut = "ACTIVE";
      if (this.updatedItem.status === "ACTIVE") {
        statut = "INACTIVE";
      }
      axios({
        method: "PUT",
        url:
          host +
          "/clients/status/" +
          statut +
          "/" +
          this.updatedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Client mis à jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogUpdateStatus = false;
        })
        // eslint-disable-next-line no-unused-vars
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
        });
    },
    _findReglementEchLabel(ech) {
      switch (ech) {
        case "J+0":
          return "IMMEDIAT";
        case "J+15":
          return "DEUX_SEMAINE";
        case "J+30":
          return "UN_MOIS";
        case "J+45":
          return "UN_MOIS_DEMI";
        case "J+60":
          return "DEUX_MOIS";
        case "J+90":
          return "TROIS_MOIS";
      }
    },
    _findReglementEchNum(ech) {
      switch (ech) {
        case "IMMEDIAT":
          return "J+0";
        case "DEUX_SEMAINE":
          return "J+15";
        case "UN_MOIS":
          return "J+30";
        case "UN_MOIS_DEMI":
          return "J+45";
        case "DEUX_MOIS":
          return "J+60";
        case "TROIS_MOIS":
          return "J+90";
      }
    },
    _sortClients() {
      this.clientsCSV = [];
      let item = {};
      this.clients.forEach(element => {
        item = {
          contratNumero: element.entreprise.contratNumero,
          enseigne: element.entreprise.enseigne,
          activite: element.entreprise.activite,
          siret: element.entreprise.siret,
          rcs: element.entreprise.numeroRcs,
          date_contrat: moment(element.entreprise.contratDate).locale("fr").format("DD-MM-YYYY"),
          statut: element.status,
          banque: "",
          banque_bic: "",
          banque_iban: "",
          reglement_echeance: "",
          reglement_mode: "",
          finance_credit: "",
          finance_creditlimite: "",
          finance_seuilalert: "",
          finance_garantie1: "",
          finance_garantie2: "",
          remiseProduitCode: "",
          remiseProduitLibelle: "",
          remise1: "",
          remise2: "",
          nom_contact: element.nom,
          prenom_contact: element.prenom,
          email: element.contact.email,
          tel1: element.contact.telephone1,
          tel2: element.contact.telephone2,
          adresse1: element.adresse.adresse1,
          adresse2: element.adresse.adresse2,
          code_postal: element.adresse.codePostal,
          ville: element.adresse.ville,
          fact_adresse1: element.entreprise.adresseFacturation.adresse1,
          fact_adresse2: element.entreprise.adresseFacturation.adresse2,
          fact_ville: element.entreprise.adresseFacturation.ville,
          fact_codepostal: element.entreprise.adresseFacturation.codePostal,
        };

        let remiseProduitCode = "";
        let remiseProduitLibelle = "";
        let remise1 = "";
        let remise2 = "";

        if (element.banque !== null) {
          item.banque = element.banque.banque;
          item.banque_bic = element.banque.bic;
          item.banque_iban = element.banque.iban;
          item.reglement_mode = element.banque.reglementMode;
          item.reglement_echeance = this._findReglementEchNum(element.banque.reglementEcheance);
        }
        if (element.finance !== null) {
          item.finance_garantie1 = element.finance.garantie_1;
          item.finance_garantie2 = element.finance.garantie_2;
          item.finance_seuilalert = element.finance.seuil_alerte;
          item.finance_creditlimite = element.finance.credit_limite;
          item.finance_credit = element.finance.credit.toFixed(2).replace(".", ",");
        }
        if (element.remises !== null) {
          element.remises.forEach(remise => {
            remiseProduitCode = remiseProduitCode.concat(
              remise.produit.code,
              " /"
            );
            remiseProduitLibelle = remiseProduitLibelle.concat(
              remise.produit.libelle,
              " /"
            );
            remise1 = remise1.concat(remise.remise1, " /");
            remise2 = remise2.concat(remise.remise2, " /");
          });
          item.remiseProduitCode = remiseProduitCode.slice(0, -1);
          item.remiseProduitLibelle = remiseProduitLibelle.slice(0, -1);
          item.remise1 = remise1.slice(0, -1);
          item.remise2 = remise2.slice(0, -1);
        }
        this.clientsCSV.push(item);
      });
      this.loadCSV = false;
    },
    nextStep(n) {
      this.e1 = n;
    },
    nextStepEdit(n) {
      this.e2 = n;
    },
    closeEdit() {
      this.dialogEdit = false;
      this.e2 = 1;
      this.initialize();
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else return "orange";
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity",
      "hasAuthenticationUsername"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formatDateContrat() {
      return this.defaultItem.entreprise.contratDate
        ? moment(this.defaultItem.entreprise.contratDate)
            .locale("fr")
            .format("L")
        : "";
    },
    formatEditDateContrat() {
      return this.editedItem.entreprise.contratDate
        ? moment(this.editedItem.entreprise.contratDate)
            .locale("fr")
            .format("L")
        : "";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.username = this.hasAuthenticationUsername;
    this.initialize();
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
          .locale("fr")
          .format("L");

        return date;
      }
    }
  }
};
</script>

<style lang="scss" scoped src="./Clients.scss" />
