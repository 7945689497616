<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <h3 class="page-title" style="color:white; margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Téléchargements</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-data-table :headers="headers" :items="factures" class="elevation-1">
          <template v-slot:item.action="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  size="20"
                  color="green"
                  v-on="on"
                  @click="downloadFile(item)"
                  >mdi-download</v-icon
                >
              </template>
              <span>Télécharger</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </div>
    </Widget>
  </div>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import { mapGetters } from "vuex";

export default {
  name: "DownloadPDF",
  components: { Widget },
  data() {
    return {
      token: null,
      role: null,
      entity: null,
      headers: [
        { text: "Fichier", value: "name" },
        { text: "Actions", value: "action", sortable: false }
      ],
      factures: []
    };
  },
  methods: {
    initialize() {
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };
      if (this.role === "ROLE_CLIENT") {
        axios
          .get(

              host +
              "/clients/download/list-file/" +
              this.entity.entreprise.contratNumero,
            auth
          )
          .then(result => {
            let arr = [...this.factures];
            result.data.forEach(element => {
              let item = {};
              item.name = element;
              //let year = element.split( "_" )[ 1 ];
              //item.surname = year.replace(/[a-zA]/gi, '');
              arr.push(item);
            });
            arr.sort(this.compare);
            this.factures = [...arr];
          })
          .catch(err => {
            console.error(err);
            this.factures = [];
          });
      } else {
        axios
          .get(

              host +
              "/stations/download/list-file/" +
              this.entity.numStation,
            auth
          )
          .then(result => {
            let arr = [...this.factures];
            result.data.forEach(element => {
              let item = {};
              item.name = element;
              let year = element.split( "_" )[ 1 ];
              item.surname = year.replace(/[a-zA]/gi, '');
              arr.push(item);
            });
            arr.sort(this.compare);
            this.factures = [...arr];
          })
          .catch(err => {
            console.error(err);
            this.factures = [];
          });
      }
    },
    downloadFile(item) {
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }, responseType: 'blob'
      };

      if (this.role === "ROLE_CLIENT") {
        axios
          .get(

              host +
              "/clients/download/" +
              this.entity.entreprise.contratNumero +
              "/" +
              item.name,
            auth
          )
          .then(result => {
            var fileURL = window.URL.createObjectURL(new Blob([result.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", item.name);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        axios
          .get(

              host +
              "/stations/download/" +
              this.entity.numStation +
              "/" +
              item.name,
            auth
          )
          .then(result => {
            var fileURL = window.URL.createObjectURL(new Blob([result.data]));
            var fileLink = document.createElement("a");
            fileLink.href = fileURL;
            fileLink.setAttribute("download", item.name);
            document.body.appendChild(fileLink);
            fileLink.click();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    compare( a, b ) {
      if ( a.surname > b.surname ){
        return -1;
      }
      if ( a.surname < b.surname ){
        return 1;
      }
      return 0;
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.initialize();
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ])
  }
};
</script>

<style scoped></style>
