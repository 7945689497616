<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white;margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Produits</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-skeleton-loader :loading="loadData" type="table">
          <v-data-table
            :headers="headers"
            :items="produits"
            :loading="loadDataIn"
            sort-by="libelle"
            class="elevation-1"
          >
            <template v-slot:item.puht="{ item }">
              <span
                >{{ formatFloat(item.puht) }} € -
                {{ item.dateValidite | formatMonth }}</span
              >
            </template>
            <template v-slot:item.status="{ item }">
              <v-btn
                class="ma-2"
                tile
                outlined
                :color="getColor(item.status)"
                @click="updateStatus(item)"
              >
                <span v-if="item.status === 'ACTIVE'"
                  >Active <v-icon color="green">mdi-check</v-icon></span
                >
                <span v-else
                  >Inactive <v-icon color="orange">mdi-close</v-icon></span
                >
              </v-btn>
            </template>
            <template v-slot:top>
              <v-toolbar flat color="white">
                <div class="flex-grow-1"></div>
                <v-dialog v-model="dialog" max-width="500px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="ma-2"
                      outlined
                      color="#03498E"
                      v-on="on"
                      v-if="
                        role === 'ROLE_ADMIN' ||
                          role === 'ROLE_CONTROLEUR' ||
                          role === 'ROLE_COMMERCIAL'
                      "
                      >Ajouter un Produit</v-btn
                    >
                  </template>
                  <v-card>
                    <v-card-title class="headline"
                      >Nouveau produit</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <v-form ref="formStep1" v-model="validStep1">
                          <v-row justify="center">
                            <v-col cols="12" md="10">
                              <v-select
                                v-model="selectCategorie"
                                :items="categories"
                                label="Categories *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-select>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="10">
                              <v-text-field
                                v-model="defaultItem.code"
                                label="Code *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="10">
                              <v-text-field
                                v-model="defaultItem.codeTVA"
                                label="Code TVA *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="10">
                              <v-text-field
                                v-model="defaultItem.libelle"
                                label="Libellé *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" md="10">
                              <v-text-field
                                prepend-inner-icon="euro"
                                v-model="defaultItem.puht"
                                label="Prix Unitaire HT *"
                                :rules="[v => !!v || '* Champs Requis']"
                                required
                              ></v-text-field>
                            </v-col>
                          </v-row>
                          <v-row justify="center">
                            <v-col cols="12" sm="10">
                              <v-menu
                                ref="menuDate"
                                v-model="menuDate"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    :value="formatDefaultDate"
                                    label="Date Validité"
                                    readonly
                                    v-on="on"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="defaultDate.dateValidite"
                                  no-title
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="error"
                                    @click="menuDate = false"
                                    >Annuler</v-btn
                                  >
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      $refs.menuDate.save(
                                        defaultDate.dateValidite
                                      )
                                    "
                                    >Valider</v-btn
                                  >
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="error"
                        text
                        @click="dialog = false"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        color="#03498E"
                        text
                        @click="save"
                        :disabled="!validStep1"
                        :loading="loadBtn"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogEdit" max-width="500px" persistent>
                  <v-card>
                    <v-card-title class="headline"
                      >Modifier ce produit</v-card-title
                    >
                    <v-card-text>
                      <v-container>
                        <v-form ref="formStep1" v-model="validStep1">
                          <v-row justify="center">
                            <v-select
                              v-model="editedItem.categorie"
                              :items="categories"
                              label="Categorie *"
                              :rules="[v => !!v || '* Champs Requis']"
                              required
                            ></v-select>
                          </v-row>
                          <v-text-field
                            v-model="editedItem.code"
                            label="Code *"
                            :rules="[v => !!v || '* Champs Requis']"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.codeTVA"
                            label="Code TVA *"
                            min="0"
                            :rules="[
                              v => /(?=.*\d)/.test(v) || '* Champs Requis'
                            ]"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="editedItem.libelle"
                            label="Libellé *"
                            :rules="[v => !!v || '* Champs Requis']"
                            required
                          ></v-text-field>
                          <v-text-field
                            prepend-inner-icon="euro"
                            v-model="editedItem.puht"
                            label="Prix Unitaire HT *"
                            :rules="[v => !!v || '* Champs Requis']"
                            required
                          ></v-text-field>
                          <v-row justify="center">
                            <v-col cols="12" sm="10">
                              <v-menu
                                ref="menuDateEdit"
                                v-model="menuDateEdit"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                              >
                                <template v-slot:activator="{ on }">
                                  <v-text-field
                                    :value="formatEditDate"
                                    label="Date Validité"
                                    readonly
                                    v-on="on"
                                    clearable
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="editedDate.dateValidite"
                                  no-title
                                  scrollable
                                >
                                  <v-spacer></v-spacer>
                                  <v-btn
                                    text
                                    color="error"
                                    @click="menuDateEdit = false"
                                    >Annuler</v-btn
                                  >
                                  <v-btn
                                    text
                                    color="primary"
                                    @click="
                                      $refs.menuDateEdit.save(
                                        editedDate.dateValidite
                                      )
                                    "
                                    >Valider</v-btn
                                  >
                                </v-date-picker>
                              </v-menu>
                            </v-col>
                          </v-row>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="error"
                        text
                        @click="dialogEdit = false"
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        color="#03498E"
                        text
                        @click="editingItem"
                        :disabled="!validStep1"
                        :loading="loadBtn"
                        >Modifier</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogUpdateStatus" max-width="290px">
                  <v-card>
                    <v-card-title
                      class="headline"
                      v-show="updatedItem.status === 'ACTIVE'"
                      >Désactiver ce produit ?</v-card-title
                    >
                    <v-card-title
                      class="headline"
                      v-show="updatedItem.status === 'INACTIVE'"
                      >Activer ce produit ?</v-card-title
                    >
                    <v-card-text v-show="updatedItem.status === 'ACTIVE'"
                      >Êtes-vous sûr de vouloir désactiver ce produit
                      ?</v-card-text
                    >
                    <v-card-text v-show="updatedItem.status === 'INACTIVE'"
                      >Êtes-vous sûr de vouloir activer ce produit
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        color="error"
                        text
                        @click="dialogUpdateStatus = false"
                        >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text>Confirmer</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="290px">
                  <v-card>
                    <v-card-title class="headline"
                      >Supprimer ce produit ?</v-card-title
                    >
                    <v-card-text
                      >Êtes-vous sûr de vouloir supprimer ce produit
                      ?</v-card-text
                    >
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn color="error" text @click="dialogDelete = false"
                        >Annuler</v-btn
                      >
                      <v-btn color="#03498E" text @click="deletingItem"
                        >Confirmer</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog max-width="600px" v-model="dialogUpdatePrice">
                  <v-card>
                    <v-card-title class="headline"
                      >Mise à jour du Prix</v-card-title
                    >
                    <v-card-text>
                      <v-row justify="center">
                        <v-col cols="12" sm="8">
                          <v-text-field
                                  label="Prix"
                                  single-line
                                  prepend-inner-icon="euro"
                                  v-model="newPrice"
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" sm="8">
                          <v-menu
                            ref="menuDatePrice"
                            v-model="menuDatePrice"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                :value="formatDefaultDatePrice"
                                label="Date Validité"
                                readonly
                                v-on="on"
                                clearable
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="updatedItem.dateValidite"
                              no-title
                              scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                text
                                color="error"
                                @click="menuDatePrice = false"
                                >Annuler</v-btn
                              >
                              <v-btn
                                text
                                color="primary"
                                @click="
                                  $refs.menuDatePrice.save(
                                    updatedItem.dateValidite
                                  )
                                "
                                >Valider</v-btn
                              >
                            </v-date-picker>
                          </v-menu>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <div class="flex-grow-1"></div>
                      <v-btn
                        @click="closingDialogUpdatePrice"
                        color="error"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn"
                        >Annuler</v-btn
                      >
                      <v-btn
                        @click="updatingPrice"
                        color="success"
                        text
                        :loading="loadBtn"
                        :disabled="loadBtn || newPrice === ''"
                        >Valider</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
              <v-icon
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
                size="20"
                @click="editItem(item)"
              >
                mdi-lead-pencil
              </v-icon>
              <v-icon
                v-if="
                  role === 'ROLE_ADMIN' ||
                    role === 'ROLE_CONTROLEUR' ||
                    role === 'ROLE_COMMERCIAL'
                "
                size="20"
                @click="deleteItem(item)"
                color="red darken-2"
              >
                mdi-delete-outline
              </v-icon>
              <v-tooltip top v-if="role === 'ROLE_ADMIN'">
                <template v-slot:activator="{ on }">
                  <v-icon
                    size="20"
                    color="warning"
                    v-on="on"
                    @click="showUpdatePrice(item)"
                    >mdi-cash-multiple</v-icon
                  >
                </template>
                <span>Mettre à jour Prix</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data> </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";

export default {
  name: "Produits",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      loadData: true,
      loadDataIn: true,
      loadBtn: false,
      validStep1: true,
      dialog: false,
      dialogEdit: false,
      dialogUpdateStatus: false,
      dialogUpdatePrice: false,
      dialogDelete: false,
      e1: 1,
      steps: 2,
      sb: { status: false, text: "", color: "", y: "top" },
      headers: [
        {
          text: "Nom",
          align: "left",
          value: "libelle"
        },
        { text: "Code", value: "code" },
        { text: "Categorie", value: "categorie" },
        { text: "Prix Unitaire HT", value: "puht" },
        { text: "Code TVA", value: "codeTVA" },
        { text: "Active", value: "status" },
        { text: "Actions", value: "action", sortable: false }
      ],
      minLength: 0,
      menuDate: false,
      menuDatePrice: false,
      menuDateEdit: false,
      selectCategorie: "",
      produits: [],
      defaultDate: {
        dateValidite: new Date().toISOString().substr(0, 10)
      },
      editedDate: {
        dateValidite: null
      },
      categories: [
        "CARBURANT",
        "GAZ",
        "SERVICE",
        "LAVAGE",
        "LUBRIFIANT",
        "BOUTIQUE"
      ],
      codesTVA: [1, 2, 3],
      editedIndex: -1,
      editedItem: {
        categorie: [],
        code: "",
        codeTVA: null,
        libelle: "",
        puht: "",
        status: "",
        dateValidite: ""
      },
      defaultItem: {
        categorie: [],
        code: "",
        codeTVA: null,
        libelle: "",
        puht: "",
        status: "ACTIVE",
        dateValidite: ""
      },
      deletedItem: {},
      updatedItem: {
        categorie: [],
        code: "",
        codeTVA: null,
        libelle: "",
        puht: "",
        status: "ACTIVE",
        dateValidite: ""
      },
      newPrice: ""
    };
  },
  methods: {
    initialize() {
      this.loadDataIn = true;
      this.loadData = true;
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
        .get( host + "/produits/", auth)
        .then(result => {
          this.produits = result.data;
          this.loadDataIn = false;
          this.loadData = false;
        });
    },
    editItem(item) {
      this.editedIndex = this.produits.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.editedDate.dateValidite = this.editedItem.dateValidite;
      this.dialogEdit = true;
    },
    editingItem() {
      this.loadBtn = true;
      this.editedItem.dateValidite = moment(
        this.editedDate.dateValidite
      ).format();

      this.editedItem.puht = parseFloat(this.editedItem.puht.replace(",", "."));
      axios({
        method: "PUT",
        url:

          host +
          "/produits/" +
          this.editedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        data: this.editedItem
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Produit mis a jour ! ";
          this.sb.color = "success";
          this.loadBtn = false;
          this.initialize();
          this.dialogEdit = false;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          console.log("err : ", error);
        });
    },
    deleteItem(item) {
      this.deletedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deletingItem() {
      axios({
        method: "DELETE",
        url:

          host +
          "/produits/" +
          this.deletedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Produit supprimé ! ";
          this.sb.color = "success";
          this.initialize();
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log("err : ", error);
        });

      this.dialogDelete = false;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    save() {
      this.loadBtn = true;
      this.defaultItem.dateValidite = moment(
        this.defaultDate.dateValidite
      ).format();
      this.defaultItem.categorie = this.selectCategorie;
      this.defaultItem.codeTVA = parseInt(this.defaultItem.codeTVA);
      this.defaultItem.puht = parseFloat(
        this.defaultItem.puht.replace(",", ".")
      );

      axios({
        method: "POST",
        url:  host + "/produits/",
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        data: JSON.stringify(this.defaultItem)
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Produit ajouté ! ";
          this.sb.color = "success";
          this.loadBtn = false;
          this.initialize();
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.loadBtn = false;
          console.log("err : ", error);
        });
      this.close();
    },
    updateStatus(item) {
      this.updatedItem = Object.assign({}, item);
      this.dialogUpdateStatus = true;
    },
    updatingStatus() {
      if (this.updatedItem.status === "ACTIVE") {
        this.updatedItem.status = "INACTIVE";
      } else {
        this.updatedItem.status = "ACTIVE";
      }

      axios({
        method: "PUT",
        url:

          host +
          "/produits/" +
          this.updatedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        data: this.updatedItem
      })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Produit mis à jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogUpdateStatus = false;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log("err : ", error);
        });
    },
    showUpdatePrice(item) {
      this.updatedItem = Object.assign({}, item);
      this.dialogUpdatePrice = true;
    },
    closingDialogUpdatePrice() {
      this.newPrice = "";
      this.dialogUpdatePrice = false;
    },
    updatingPrice() {
      this.loadBtn = true;
      this.updatedItem.puht = parseFloat(this.newPrice.replace(",", "."));

      axios({
        method: "PUT",
        url:

          host +
          "/produits/update-prix/" +
          this.updatedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        },
        data: this.updatedItem
      })
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Produit mis à jour ! ";
          this.sb.color = "success";
          this.dialogUpdatePrice = false;
          this.loadBtn = false;
          this.newPrice = "";
          this.initialize();
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.dialogUpdatePrice = false;
          this.loadBtn = false;
          this.newPrice = "";
          console.log("err : ", error);
        });
    },
    nextStep(n) {
      this.e1 = n;
    },
    getColor(active) {
      if (active) return "green";
      else return "orange";
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationRole", "hasAuthenticationToken"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    formatDefaultDate() {
      return this.defaultDate.dateValidite
        ? moment(this.defaultDate.dateValidite)
            .locale("fr")
            .format("L")
        : "";
    },
    formatDefaultDatePrice() {
      return this.updatedItem.dateValidite
        ? moment(this.updatedItem.dateValidite)
            .locale("fr")
            .format("L")
        : "";
    },
    formatEditDate() {
      if (this.editedDate.dateValidite !== null) {
        return this.editedDate.dateValidite
          ? moment(this.editedDate.dateValidite)
              .locale("fr")
              .format("L")
          : "";
      }
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.initialize();
  },
  filters: {
    formatMonth: function(value) {
      if (value) {
        let month = moment(value)
          .locale("fr")
          .format("MM");
        let year = moment(value)
          .locale("fr")
          .format("YYYY");

        return month + "/" + year;
      }
    }
  }
};
</script>

<style src="./Produits.scss" lang="scss" scoped />
