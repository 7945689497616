<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="tables-basic">
    <v-snackbar v-model="sb.status" :top="sb.y === 'top'" :color="sb.color">
      {{ sb.text }}
    </v-snackbar>
    <h3 class="page-title" style="color:white; margin-top: 4%">
      Gestion des <span class="fw-semi-bold">Débloquer des Cartes</span>
    </h3>
    <Widget class="mx-auto elevation-0">
      <div class="table-responsive">
        <v-data-table
          :headers="headers"
          :items="cartes"
          sort-by="nom"
          class="elevation-1"
        >
          <template v-slot:item.status="{ item }">
            <v-btn
              class="ma-2"
              tile
              outlined
              :color="getColor(item.status)"
              @click="updateStatut(item)"
            >
              <span v-if="item.status === 'DEMDEBLK'"
                >Demande <v-icon color="orange">mdi-exclamation</v-icon></span
              >
              <span v-else-if="item.status === 'INACTIVE'"
                >Erreur Pin<v-icon color="orange">mdi-exclamation</v-icon></span
              >
            </v-btn>
          </template>
          <template v-slot:item.hors_parc="{ item }">
            <span v-if="item.hors_parc === true">Hors Parc</span>
            <span v-else>{{ item.vehicule.immatriculation }}</span>
          </template>
          <template v-slot:item.createdDate="{ item }">
            {{ item.createdDate | formatUnix }}
          </template>
          <template v-slot:item.typePayement="{ item }">
            <span v-if="item.typePayement === 'CREDIT_CLIENT_CLASSIQUE'"
              >PRO</span
            >
            <span v-if="item.typePayement === 'PME'">PORTE MONNAIE</span>
            <span v-if="item.typePayement === 'CARTE_PRE_PAYEE'"
              >PRE PAYEE</span
            >
          </template>
          <template v-slot:item.solde="{ item }">
            <span>{{ formatFloat(item.solde) }} €</span>
          </template>
          <template v-slot:item.limit.montant="{ item }">
            <span>{{ formatFloat(item.limit.montant) }} €</span>
          </template>
          <template v-slot:top>
            <v-toolbar flat color="white">
              <div class="flex-grow-1"></div>
              <v-dialog v-model="dialogInfo" max-width="700px" persistent>
                <v-card>
                  <v-card-title class="headline">Information</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row justify="center">
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.serialNumber"
                            label="Numéro de carte"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.libelle"
                            label="Libellé de carte"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" sm="8">
                          <v-text-field
                            v-model="editedItem.typePayement"
                            label="Type de Paiement"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4">
                          <v-switch
                            v-model="editedItem.hors_park"
                            :label="`Hors Parc`"
                            readonly
                          ></v-switch>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-if="editedItem.client !== null"
                            v-model="editedItem.client.entreprise.enseigne"
                            label="Enseigne Entreprise"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-if="editedItem.client !== null"
                            v-model="editedItem.client.entreprise.activite"
                            label="Activité Entreprise"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="editedItem.produits"
                            :items="produits"
                            item-text="libelle"
                            label="Produits"
                            return-object
                            multiple
                            readonly
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="3">
                          <h4>Plage horaire d'utilisation</h4>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-list disabled>
                          <v-list-item-group color="primary">
                            <v-list-item
                              v-for="(item, i) in editedItem.restriction"
                              :key="i"
                            >
                              <v-list-item-icon>
                                <v-icon>mdi-clock</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title
                                  v-text="
                                    `${item.restrictionJour} - ${item.heure_debut} ${item.heure_fin}`
                                  "
                                ></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="3">
                          <h4>Limites</h4>
                        </v-col>
                      </v-row>
                      <v-row justify="center">
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.limit.montant"
                            label="Plafond"
                            readonly
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            v-model="editedItem.limit.periodicite"
                            label="Périodicité"
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="#03498E" text @click="dialogInfo = false"
                      >Fermer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog v-model="dialogUpdateStatus" max-width="290px">
                <v-card>
                  <v-card-title class="headline"
                    >Débloquer cette carte ?</v-card-title
                  >
                  <v-card-text
                    >Êtes-vous sûr de vouloir débloquer cette carte
                    ?</v-card-text
                  >
                  <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn
                      color="error"
                      text
                      @click="dialogUpdateStatus = false"
                      >Annuler</v-btn
                    >
                    <v-btn color="#03498E" text @click="updatingStatut"
                      >Confirmer</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.action="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon size="20" v-on="on" @click="showDialogInfo(item)"
                  >mdi-information-outline</v-icon
                >
              </template>
              <span>Plus d'informations</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-icon
                  size="20"
                  v-on="on"
                  @click="updateStatut('ACTIVE', item)"
                  >mdi-check-circle-outline</v-icon
                >
              </template>
              <span>Levée Opposition Carte</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data> </template>
        </v-data-table>
      </div>
    </Widget>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Widget from "@/components/Widget/Widget";
import axios from "axios";
import { host } from "@/utils/constants";
import moment from "moment";
import "moment/locale/fr";

export default {
  name: "DebloqueCarte",
  components: { Widget },
  data() {
    return {
      role: null,
      token: null,
      entity: null,
      dialog: false,
      dialogUpdateStatus: false,
      dialogInfo: false,
      sb: { status: false, text: "", color: "", y: "top" },
      headers: [
        {
          text: "N° Carte",
          align: "left",
          value: "serialNumber"
        },
        { text: "Date Création", value: "createdDate", filterable: false },
        { text: "Immat / HP", value: "hors_parc" },
        { text: "Libelle", value: "libelle" },
        { text: "Type", value: "typePayement", filter: this.cartesFilter },
        { text: "Solde", value: "solde" },
        { text: "Plafond", value: "limit.montant" },
        { text: "Pincode", value: "pinCode" },
        { text: "Statut", value: "status", filterable: false },
        { text: "Actions", value: "action", sortable: false, filterable: false }
      ],
      cartes: [],
      restrictionjour1: {
        active: false,
        heure_debut: "0000",
        heure_fin: "2359",
        restrictionJour: "LUNDI_VENDREDI"
      },
      restrictionjour2: {
        active: false,
        heure_debut: "0000",
        heure_fin: "2359",
        restrictionJour: "SAMEDI"
      },
      restrictionjour3: {
        active: false,
        heure_debut: "0000",
        heure_fin: "2359",
        restrictionJour: "DIMANCHE"
      },
      editedItem: {
        createdDate: "",
        status: null,
        serialNumber: "",
        libelle: "",
        dematerialiser: true,
        hors_park: true,
        typePayement: "",
        client: {
          id: "",
          nom: "",
          prenom: "",
          entreprise: {
            enseigne: "",
            email: null,
            activite: "",
            siret: "",
            numeroRcs: 0,
            contratNumero: "",
            contratDate: "",
            contratExpire: true
          }
        },
        produits: [
          {
            id: "",
            code: "",
            libelle: "",
            status: "ACTIVE"
          }
        ],
        restriction: {
          heure_debut: "",
          heure_fin: "",
          restrictionJour: ""
        },
        limit: {
          frequenceJour: 0,
          montant: 0,
          montantJour: 0,
          periodicite: "",
          volume: 0
        },
        uuid: ""
      },
      produits: [],
      selectedProduits: [],
      periodes: ["JOUR", "HEB", "MOIS", "TRIM", "SEM", "AN"],
      selectedPeriodes: [],
      status: null
    };
  },
  methods: {
    initialize() {
      this.demandes = [];
      const auth = {
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest"
        }
      };

      axios
        .get(

            host +
            "/cartes/client/" +
            this.entity.id,
          auth
        )
        .then(result => {
          this.cartes = [];
          result.data.forEach(element => {
            if (
              element.status === "DEMDEBLK" ||
              element.status === "INACTIVE"
            ) {
              this.cartes.push(element);
            }
          });
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.cartes = [];
        });
    },
    showDialog(item) {
      this.defaultItem = Object.assign({}, item);
      this.dialog = true;
    },
    showDialogInfo(item) {
      this.editedIndex = this.cartes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogInfo = true;
    },
    updateStatut(status, item) {
      this.status = status;
      this.editedItem = Object.assign({}, item);
      this.dialogUpdateStatus = true;
    },
    updatingStatut() {
      axios({
        method: "PUT",
        url:

          host +
          "/cartes/status/ACTIVE/" +
          this.editedItem.id,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Access-Control-Allow-Origin": "*"
        },
        data: this.editedItem
      })
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Carte mise a jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialogUpdateStatus = false;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          this.initialize();
          this.dialogUpdateStatus = false;
          console.log("err : ", error);
        });
    },
    save(action) {
      axios({
        method: "PUT",
        url:

          host +
          "/demandes/" +
          this.defaultItem.id +
          "/" +
          action,
        baseUrl: host,
        headers: {
          Authorization: "Bearer " + this.token,
          "X-Requested-Width": "XMLHttpRequest",
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
        .then(result => {
          this.sb.status = true;
          this.sb.text = "Demande mise à jour ! ";
          this.sb.color = "success";
          this.initialize();
          this.dialog = false;
        })
        .catch(error => {
          this.sb.status = true;
          this.sb.text = "Une erreur est survenue ! ";
          this.sb.color = "error";
          console.log(error);
          this.initialize();
          this.dialog = false;
        });
    },
    getColor(active) {
      if (active === "ACTIVE") return "green";
      else return "orange";
    },
    formatFloat: function(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  computed: {
    ...mapGetters("auth", [
      "hasAuthenticationRole",
      "hasAuthenticationToken",
      "hasAuthenticationEntity"
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },
  watch: {
    steps(val) {
      if (this.e1 > val) {
        this.e1 = val;
      }
    }
  },
  created() {
    this.role = this.hasAuthenticationRole;
    this.token = this.hasAuthenticationToken;
    this.entity = this.hasAuthenticationEntity;
    this.initialize();
  },
  filters: {
    formatUnix: function(value) {
      if (value) {
        let date = moment(value)
          .locale("fr")
          .format("L");

        return date;
      }
    }
  }
};
</script>

<style src="./ManageCartes.scss" lang="scss" scoped />
