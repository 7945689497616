// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import vuetify from "@/plugins/vuetify";
import BootstrapVue from "bootstrap-vue";
import vSelect from "vue-select";
import VueTouch from "vue-touch";
import Trend from "vuetrend";
import App from "./App";
import VueResource from "vue-resource";

// router setup
import router from "@/router";
import store from "@/store";

// Plugins
import vBlur from "v-blur";
import JsonExcel from "vue-json-excel";
import JsonCSV from 'vue-json-csv'

Vue.use(BootstrapVue);
Vue.use(VueTouch);
Vue.use(Trend);
Vue.component("v-select", vSelect);
Vue.use(vBlur);
Vue.use(VueResource);
Vue.component("downloadExcel", JsonExcel);
Vue.component('downloadCsv', JsonCSV);

Vue.config.productionTip = false;

/* eslint-disable no-new */
new Vue({
  el: "#app",
  store,
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
